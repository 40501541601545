<template lang="pug">
  .education
    b-jumbotron(header='Online fashion designer course' lead='Table of contents:')
      ul
        li
          a(href="#about-course")
            | About online fashion designer course
        li
          a(href="#general-characteristics")
            | General characteristics of clothing
        li
          a(href="#clothing-range")
            | Characteristics of the clothing range
        li
          a(href="#women-outerwear")
            | Assortment of women's outerwear
        li
          a(href="#main-lines")
            | Main construction 
        li
          a(href="#figure_types")
            | Types of figures with different types of posture and shoulder heights
        li
          a(href="#ease_allowance")
            | Ease allowance classification
        li
          a(href="#seam_allowance")
            | Seam allowances
        li
          a(href="#taking_measurements")
            | Taking measurements
        li
          a(href="#tailor_patterns")
            | Tailor's patterns and rulers
        li
          a(href="#base_pattern")
            | Building the base of the dress pattern
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#about-course
      h3
        | About online fashion designer course
      b-row
        b-col
          p
            | We are creating a resource that will be useful for novice designers, and will be a free online fashion design course.
          b-alert(show="" variant="primary")
            h4(class="alert-heading")
              | In messages like this, we will give you advice as you progress through the course.
            p
              | Here is the first. Further in the text there are links to the sandbox and social networks. Be sure to visit the sandbox and briefly familiarize yourself with its capabilities. If it becomes interesting, then by links to social networks you can get a lot of videos about how it works.
          p
            | A 
            router-link(to="/app")
              | sandbox 
            | has been created for you - a place where you can conveniently and quickly practice in clothing design.
          p
            | There are videos on our 
            a(href="https://www.youtube.com/channel/UC5vZJCfwM4Ys4hHaiBBqdvw/featured" target="_blank")
              | YouTube 
            | and 
            a(href="https://www.tiktok.com/@nastix_design" target="_blank")
              | TikTok 
            | pages that can come in handy.
          p
            | Also on the page of online fashion designer course you will find a lot of theoretical information about clothing design. It is constantly updated with new information.
          div
            b-embed(
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/NulSKQ-cw7I"
              allowfullscreen
            )
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    b-alert(show="" variant="primary")
      h4(class="alert-heading")
        | 
      p
        | Check out the following clothing characteristics and assortment of women's outerwear
    .education__item#general-characteristics
      h3
        | General characteristics of clothing
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Performance properties')
              b-card-text 
                ul
                  li fit for purpose
                  li convenience
                  li shape retention
                  li reliability
            b-card.text-left(header='Hygienic properties')
              b-card-text 
                ul
                  li protective functions from the adverse effects of the climatic environment
                  li mechanical damage
                  li thermal protection
                  li air permeability, gas permeability
                  li water resistance
                  li dust capacity
                  li weight
            b-card.text-left(header='Aesthetic properties')
              b-card-text 
                ul
                  li marketable condition
                  li conformity to the image of a person
          .mt-3
            b-card-group(deck='')
              b-card(class="text-center")
                | The requirements of manufacturability and cost-effectiveness of the design are applied to clothes of mass industrial production
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#clothing-range
      h3
        | Characteristics of the clothing range
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Purpose of clothing according to its protective function')
              b-card-text 
                ul
                  li household clothes
                  li sportswear
                  li industrial clothes
            b-card.text-left(header='Household clothing according to operating conditions')
              b-card-text 
                ul
                  li outerwear
                  li costume and dress clothes
                  li linen products
                  li corsetry
                  li headwear
                  li gloves and mittens
                p
                  | Each group can be subdivided into types (Example, costume and dress clothes - jackets, trousers, skirts, dresses, etc.)
            b-card.text-left(header='Sex and age sign')
              b-card-text 
                ul
                  li men's clothing
                  li women's clothing
                  li baby clothes
                    ul
                      li for newborn
                      li for toddlers
                      li for preschoolers
                      li for younger students
                      li for older students
                      li for teenagers
      .mt-3
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Depending on the time of year and climatic zones')
              b-card-text 
                ul
                  li spring and autumn clothes
                  li summer clothes
                  li winter clothes
                  li all-weather clothing
            b-card.text-left(header='Depending on the use of clothing in a particular situation')
              b-card-text 
                ul
                  li casual wear
                  li home clothes
                  li formal wear
                  li sportswear
                  li industrial clothes
                p
                  | Sportswear is subdivided into subclasses according to sports, according to gender and age
                p
                  | Industrial clothing, depending on the functions performed, is divided into special, departmental, technological (sanitary and hygienic)
            b-card.text-left(header='Depending on the design of the clothes')
              b-card-text 
                ul
                  li according to the method of operation
                    ul
                      li shoulder
                      li waist
                  li according to silhouette
                    ul
                      li adjacent
                      li semi-adjacent
                      li straight
                      li trapezoidal
                  li according to the volume of the form
                    ul
                      li small
                      li medium
                      li volumetric
                  li according to the cut of the sleeves
                    ul
                      li with set-in sleeves
                      li raglan sleeves
                      li one-piece sleeves
                      li combined sleeves
    .mb-1                  
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#women-outerwear
      h3
        | Assortment of women's outerwear
      b-row
        b-col       
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | coats
                //- small
                //-   | 3 days ago
              p(class="mb-1")
                | Front closure clothing, with sleeves, collar, cape or hood. Coat products include upper moisture- and heat-protective products for everyday wear.
              p(class="mb-1")
                | In demi-season coats, as a rule, there is no insulating pad
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | short coats
              p(class="mb-1")
                | The short coat differs from the coat only in a shorter length
              p(class="mb-1")
                | Cape - a short coat-type product with a slit in the front, with or without a fastener, with slits for the hands. May or may not be removable. Can be used to decorate and insulate coats.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | raincoats
              p(class="mb-1")
                | A kind of coat, made of air-supporting or film materials and used to protect against rain
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | wamuses
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | vests
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | jackets
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | suits
              p(class="mb-1")
                | 
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | overalls
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | capes
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | coat dress and others
              p(class="mb-1")
                | wamuses, jackets, vests, dress-coats can be made as an independent type of clothing, or in combination (with trousers, skirts, etc.)
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    b-alert(show="" variant="primary")
      h4(class="alert-heading")
        | 
      p
        | Read about the main construction lines and find them on the layout below
    .education__item#main-lines
      h3
        | Main construction lines
      b-row
        b-col
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder line
              .mb-1
              ul
                li
                  | natural
                li
                  | with raised or lowered shoulder point
                li
                  | with normal, extended or shortened shoulder length
              p
                | Natural position is understood as a shape that fits the shoulder slope without additional pads. The natural level of the shoulders also corresponds to the natural length of the shoulder section, ending with the shoulder point of the figure, creating a normal turn of the shoulders.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | armhole depth line
              p(class="mb-1")
                | Is one of the main lines of a design where the volume of all product as a whole and on separate sites of a design (backs, shelves, armholes) is practically formed
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | waistline
              p(class="mb-1")
                | The level of the waist line can be, depending on the fashion in a natural place, lowered or increased. This applies mainly to the fitted, adjacent and semi-adjacent silhouettes.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | hip line
              p(class="mb-1")
                | In products of a straight volumetric, extended, fitted with a large flared skirt silhouettes, the width at the hip line is not calculated, since the free fit of the product is ensured by a significant expansion to the bottom.
              p(class="mb-1")
                | The level of the hip line, depending on the fashion, can be at different levels, however, in the design drawing of the product, it is usually left in its natural place
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | underline
              p(class="mb-1")
                | Along the bottom line, the product can be narrowed, equal to the width along the hip line, expanded. To obtain a straight silhouette, the width along the bottom line is equal to the width along the chest line plus 4-6 cm. Otherwise, the silhouette will be narrowed to the bottom.
              p(class="mb-1")
                | The expanded silhouette has no bottom expansion limit.
      .mb-1
      b-row
        b-col
          b-img(src="/education_imgs/desing_lines.png" center="" fluid="" alt="Construction lines")
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#figure_types
      h3
        | Types of figures with different types of posture and shoulder heights
      b-row
        b-col
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with normal posture
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with an arched posture with protruding buttocks
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with a stooped posture
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with an arched posture with a protruding belly
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with normal shoulder height
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with low shoulders
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with high shoulders
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 'top type'
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 'low type'
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | with different hips
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    b-alert(show="" variant="primary")
      h4(class="alert-heading")
        | 
      p
        | Familiarize yourself with the concept of clothes ease allowance. The tables below do not need to be memorized, but if necessary, remember where you can find them.
    .education__item#ease_allowance
      h3
        | Ease allowance (allowances for the freedom of fitting) classification
      p
        | Clothing cannot be an exact copy of the human body. That part of the surface of the body where the clothes fit snugly is called the supporting surface.
      p
        | Below the supporting surface, air gaps are formed between the clothes and the human body, which ensure the convenience of wearing clothes, freedom of breathing, heat exchange, etc. Therefore, clothes are always made larger than the corresponding figure sizes. This difference between the inner measurements of the clothes and the corresponding body measurements is called the ease allowance.
      p
        | In order to maintain the same visual impression from products of a certain silhouette on figures of various sizes and heights, the Ease allowance is increased by 0.5 cm - for figures of small sizes, high growth. For figures of large sizes and low growth, they are reduced by 0.5 cm.
      p
        | For winter and insulated products, these increases are increased. The amount of additional Ease allowance depends on the thickness of the insulation pad.
      p
        | The width of the sleeve is characterized by the magnitude of the Ease allowance in the girth of the shoulder.
      h4
        | Allowances for free fit with varying degrees of fit in areas of the product, cm
      p
        | (breast; waist; hips)
      b-row
        b-col
          b-table(responsive="" striped hover :items="table_items_1")
      p
        | (back; shelf)
      b-row
        b-col
          b-table(responsive="" striped hover :items="table_items_2")
      h4
        | Allowances on the insulating gasket along the constructive areas
      p
        | Allowance_1 - the size of the allowance and the half-girth of the chest (third) (the tape should run horizontally around the body, through the protruding points of the mammary glands and close on the right side of the chest), cm
      p
        | Allowance_2 - the amount of allowance for the insulating pad and shoulder girth, cm
      b-row
        b-col
          b-table(responsive="" striped hover :items="table_items_3")
      h4
        | Allowance value, cm, for products
      b-row
        b-col
          b-table(responsive="" striped hover :items="table_items_4")
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#ease_allowance
      h3
        | Seam allowances
      h4
        | Ease allowance (allowances for the freedom of fitting) VS Seam allowance
      b-row
        b-col
          b
            | Ease allowance
          ul
            li
              | allow you to achieve the necessary degree of fit of clothing to the body
            li
              | laid by the master at the stage of designing basic patterns
            li
              | determined by the fashion designer depending on the style of the product, the type of fabric, etc
        b-col
          b
            | Seam allowance
          ul
            li
              | adds the master when cutting the product on all sides of the parts
            li
              | for assembly and processing of seams of a product
      .mb-1
      b-alert(show="" variant="light")
        p
          | Seam allowances are the amount that is added to the details of the pattern along all contours for assembling and processing the seams of the product.
      p
        | If you build patterns yourself or take them in a magazine, then most often they will be without allowances. If you forget, the product will be small in size
      p
        | Allowances are marked with a wax pencil or a regular pencil, tailor's chalk.
      p
        | The thicker and denser the fabric, the higher the allowance
      h4
        | Seam allowance types
      p
        | Allowances for seams and hem - depends on the type of product, as well as the properties of the material from which it is sewn.
      p
        | Allowances for fitting the product according to the figure - are taken into account by the features of the customer's figure.
      h4
        | The amount of allowances depends on a number of reasons:
      ul
        li
          | from type of detail
        li
          |on the type and properties of the tissue. When cutting thick winter types of fabric, the size of the allowances is increased
        li
          |on the type and purpose of the seam
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Seam allowances for dress details (shoulder product)
      b-table(responsive="" striped hover :items="se_table_items_1")
      h4
        | Seam allowances for skirt details
      b-table(responsive="" striped hover :items="se_table_items_2")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Seam allowances for trousers
      b-table(responsive="" striped hover :items="se_table_items_3")
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    b-alert(show="" variant="primary")
      h4(class="alert-heading")
        | 
      p
        | This is the first really important step. The result depends very much on the quality of measurements. So be careful.
    .education__item#taking_measurements
      h3
        | Taking measurements
      p
        b
          | Remember:
      ul
        li
          | Make sure that the measured stands freely, without much tension. You should not pull the stomach, raise and lower the shoulders, take them back and forth. Hands should be lowered, heels together, socks apart.
        li
          | The greatest accuracy if the person being measured is in underwear or a thin dress.
        li
          | The measuring tape should be applied tightly to the body (not tightening and not relaxing either).
      p
        | Record all measurement results in a spreadsheet. Example:
      b-row
        b-col
          b-table(responsive="" striped hover :items="table_items_5")
      p
        | Next, we will show you the main measurements. They will be needed in the future to build the base of the dress pattern.
      b-row
        b-col
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | width over bust 
                  b-badge(variant="info")
                    | WOB
              p
                | Over the breasts
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | bust 
                  b-badge(variant="info")
                    | B
              p
                | At the most protruding points of the chest (breast glands)
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | center of chest 
                  b-badge(variant="info")
                    | CC
              p
                | Horizontally along the front between the teat points
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | chest height 
                  b-badge(variant="info")
                    | CH
              p
                | From the point of articulation of the neck with the shoulder to the nipple point
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | neck girth 
                  b-badge(variant="info")
                    | NG
              p
                | From the base of the neck to the jugular notch
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder height oblique front 
                  b-badge(variant="info")
                    | SHOF
              p
                | Along the front from the point of intersection of the mid-front line with the waist line to the extreme point of the shoulder
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder length 
                  b-badge(variant="info")
                    | SL
              p
                | From the base of the neck to the extreme point of the shoulder (the point of articulation of the shoulder with the arm).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to waist front 
                  b-badge(variant="info")
                    | LWF
              p
                | Along the front from the point of transition of the neck to the shoulder (the base of the neck) through the most protruding point of the chest to the waist
            //- b-list-group-item
            //-   div(class="d-flex w-100 justify-content-between")
            //-     h5(class="mb-1")
            //-       | length to waist front 
            //-       b-badge(variant="info")
            //-         | LWF
            //-   p
            //-     | Along the front from the point of transition of the neck to the shoulder (the base of the neck) through the most protruding point of the chest to the waist
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder height oblique 
                  b-badge(variant="info")
                    | SHO
              p
                | From the point of the spinal column at the waist line to the extreme point of the shoulder
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to back waist 
                  b-badge(variant="info")
                    | LBWL
              p
                | Along the back from the point of articulation of the neck with the shoulder to the waist line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to back waist second 
                  b-badge(variant="info")
                    | LBW2
              p
                | Along the back from the seventh cervical vertebra to the waist line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | back width 
                  b-badge(variant="info")
                    | BW
              p
                | Along the back horizontally at the level of the shoulder blades before the arms
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | armhole width 
                  b-badge(variant="info")
                    | AW
              p
                | Under the armpit
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | armhole depth 
                  b-badge(variant="info")
                    | AD
              p
                | Depth from the 7th cervical vertebra to the arm
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length of the sleeve 
                  b-badge(variant="info")
                    | LS
              p
                | From the end point of the shoulder to the wrist along the arm slightly bent at the elbow
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder girth 
                  b-badge(variant="info")
                    | SG
              p
                | At the widest top of the arm
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | inside leg length 
                  b-badge(variant="info")
                    | ILL
              p
                | Along the inside of the leg from the groin to the floor
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | outside leg length 
                  b-badge(variant="info")
                    | OLL
              p
                | From the waistline along the outside of the leg to the floor
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | wrist circumference 
                  b-badge(variant="info")
                    | WC
              p
                | Around the wrist
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | waist 
                  b-badge(variant="info")
                    | W
              p
                | At the narrowest point
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | hip circumference 
                  b-badge(variant="info")
                    | HC
              p
                | At the most convex points of the buttocks
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | hip height 
                  b-badge(variant="info")
                    | HH
              p
                | Along the side from the waist line to the hip line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder product length 
                  b-badge(variant="info")
                    | SPL
              p
                | along the back from the 7th cervical vertebra to the desired length of the product
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | belt product length 
                  b-badge(variant="info")
                    | BL
              p
                | Waist to desired length
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | knee height 
                  b-badge(variant="info")
                    | KH
              p
                | From the waist to the center of the knee
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | knee girth 
                  b-badge(variant="info")
                    | KG
              p
                | At the center of the knee
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | leg circumference 
                  b-badge(variant="info")
                    | LG
              p
                | Widest part of the leg
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | calf girth 
                  b-badge(variant="info")
                    | CG
              p
                | Along the widest part of the calf
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | ankle girth 
                  b-badge(variant="info")
                    | AG
              p
                | At the narrowest part of the leg above the bone
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | calf height 
                  b-badge(variant="info")
                    | CH
              p
                | From the center of the patella to the most protruding part of the lower leg
      .mb-1
      b-row
        b-col
          b-img(src="/education_imgs/model_with_all_lines.png" center="" fluid="" alt="Construction lines")
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    .education__item#tailor_patterns
      b-row
        b-col
          h2
            | Tailor's patterns and rulers
          p
            | Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns.
          .mt-1
          b-img(src="/education_imgs/str5.jpg" fluid alt="Tailor's patterns and rulers")
          .mt-3
          b-alert(show="" variant="primary")
            h4(class="alert-heading")
              | 
            p
              | For high-quality modeling of clothes, you need a minimum set of sewing patterns. The section presents 4 of the most popular. If you are serious about designing clothes, we recommend purchasing them.
          .mt-1
          p
            | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
          p
            | Most of the patterns are made of durable lightweight plastic. More often, transparent plastic is used, which allows the tailor to see all the lines of the drawing, their location and configuration during design. You can also find patterns made of wood or metal.
          .mt-1
          b-card-group(deck='')
            b-card(title="Pattern 'Saber' (cutting ruler)" img-src="/education_imgs/str1.jpg" img-alt="Patterns 'Saber" img-right='')
              b-card-text
                p
                  | One of the most popular patterns among tailors.
                p
                  | Allows you to measure the rounded areas of the pattern.
                p
                  | Using this pattern, you can draw lines of the side, darts, bottom of the product and sleeves, relief lines, cutout lines, etc.
                p
                  | With the help of an enlarged rounded edge, in some cases it is also possible to draw an armhole line.
          .mt-1
          b-card-group(deck='')
            b-card(title="Tailor's meter" img-src="/education_imgs/str2.jpg" img-alt="Tailor's meter" img-right='')
              b-card-text
                | Ruler 1m
          .mt-1
          b-card-group(deck='')
            b-card(title="Corner pattern" img-src="/education_imgs/str3.jpg" img-alt="Corner pattern" img-right='')
              b-card-text
                p
                  | With it, it is very easy to draw parallel lines, build angles.
                p
                  | The third side of the pattern allows you to draw curved lines of the barrel and bottom of the product.
          .mt-1
          b-card-group(deck='')
            b-card(title="Patterns for the armhole ('French pattern')" img-src="/education_imgs/str4.jpg" img-alt="Patterns for the armhole" img-right='')
              b-card-text
                p
                  |  With the help of such a pattern, you can draw not only the armhole line, but also the lines of the side, bottom of the product and sleeves, model cutouts of various depths, mark corners and allowances, as well as the location of loops and buttons in the product. 
                p
                  | The pattern allows you to easily adjust and fit patterns - reduce or increase the depth of the armhole, adjust the waistline, make sleeves, etc.
          .mt-1
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mt-1
    b-alert(show="" variant="primary")
      h4(class="alert-heading")
        | 
      p
        | And now the fun begins. This part should be studied carefully and tried in practice.
    .education__item#base_pattern
      h3
        | Building the base of the dress pattern
      p
        | Before building a base, you need to complete three mandatory steps:
      ul
        li
          | have a sketch, or at least an idea of how you see the finished product. For this you may need our 
          router-link(to="/app")
            | sandbox
        li
          | take your measurements 
        li
          | and pick up the allowances for the freedom of fitting. You are already somewhat familiar with this from the sections above. Now we have to put it into practice
    .base-dress
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='To build the basic base of the dress, measurements are needed')
              b-card-text 
                ul
                  li Width over bust 
                    b-badge(variant="info")
                      | WOB
                  li Bust 
                    b-badge(variant="info")
                      | B
                  li Bust width (front part of B, a little more than half of B)
                    b-badge(variant="info")
                      | WB
                  li Length between WB and WOB 
                    b-badge(variant="info")
                      | h
                  li Chest height
                    b-badge(variant="info")
                      | CH
                  li Center of chest 
                    b-badge(variant="info")
                      | CC
                  li Waist 
                    b-badge(variant="info")
                      | W
                  li Hip circumference 
                    b-badge(variant="info")
                      | HC
                  li Hip height 
                    b-badge(variant="info")
                      | HH
                  li Back width 
                    b-badge(variant="info")
                      | BW
                  li Armhole width 
                    b-badge(variant="info")
                      | AW
                  li Length to back waist second 
                    b-badge(variant="info")
                      | LBWL2
                  li Shoulder height oblique 
                    b-badge(variant="info")
                      | SHO
                  li Length to waist front 
                    b-badge(variant="info")
                      | LWL
                  li Shoulder height oblique front 
                    b-badge(variant="info")
                      | SHOF
                  li Shoulder length 
                    b-badge(variant="info")
                      | SL
                  li Neck girth 
                    b-badge(variant="info")
                      | NG
                  li Armhole depth 
                    b-badge(variant="info")
                      | AD
                  li Shoulder product length 
                    b-badge(variant="info")
                      | SPL
          .mt-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mt-3
          b-img(src="/education_imgs/dress_base.png" center="" fluid="" alt="Basic base of the dress")       
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Step back from the top corner of the sheet diagonally 15-20 cm.
              small
                //- | Donec id elit non mi porta.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Place point 1 in the top left corner.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw a vertical line down from point 1 and set aside along it:
              p(class="mb-1")
                | 1-2 = AD + Ease allowance for armhole depth (about ease allowance)
              p(class="mb-1")
                | 1-3 = LBWL2
              p(class="mb-1")
                | 3-4 = HH
              p(class="mb-1")
                | 1-5 = SPL
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw lines of arbitrary length to the right from points 1, 2, 3, 3 and 5 - the lines of the armhole, waist, hips and bottom of the dress are obtained.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 1-6 = 1/2 BW + AW + 1/2 WB + ease allowance.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Points 21,31,41,51 were obtained vertically from points 6, at the intersection with horizontal lines.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 2, set aside to the right along the armhole line:
              p(class="mb-1")
                | 2-22 =  1/2 BW.
              p(class="mb-1")
                | 22-23 = AW + ease allowance.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Side line
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Divide the segment 22-23 in half: 22-24 = 23-24.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 24, lower the perpendicular down. Points obtained at the intersection with horizontal lines: 32,42,52.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From points 22, lift up and forward to the segment 1-6, point 61 is obtained.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 31, set aside up along the line of the middle of the front 31-7 = LWL.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 7 draw a horizontal line to the left 7-62 = 21-23.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Connect points 62 and 23.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Back
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 1-11 = 1/6 NGto measure + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 11-12 = 2 cm for all sizes.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw the line of the neck of the back along the pattern or by hand.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 3, build an arc with a radius of 3-8 = SHO.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Back shoulder length. 12-8 = SL + 1.5 cm. Point 8 must lie on the arc.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | In our example, the shoulder of the back is lengthened by 1-1.5 cm, and then the excess length is being ironed.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Divide the segment 61-22 in half - point 9. Draw the armhole line of the back from point 8 through point 9 to point 24 along the pattern or by hand.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Front
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 7-71 = 1-11 (back) = 1/6 NG + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 7-72 = 7-71 + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw the front neck line 71 - 72 smoothly along the pattern or by hand.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Shoulder length to chest tuck: 71-63 = 4 cm (for all sizes).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 63-81 = 1.3 cm (for all sizes).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The position of point C (chest point) is determined by two measurements: Chest height and Center of the chest.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 71, lay down 71C = CH so that the distance from the mid-front line is 25C (1/2 CC).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Right side of chest tuck 81C: connect point 81 and point C with a straight line.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Chest tuck solution. С1С2 = (WB - WOB) / 2.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Left side of chest tuck: connect point C and C2 with a straight line, extend the line up: C-82 = C-81.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From points 31 according to the structure of the arc with a radius of 31-83 = SHOF.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Front shoulder length after chest tuck: 82-83 = SL  minus 71-81. Point 83 must lie on the arc.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Front armhole line. From point 23, set aside up 23-91 = 6 cm (auxiliary point). Line the front armhole from point 83 through point 91 to point 24.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The addition to the Half-Circumference of the Waist should be 1.5 cm. X = 1/2 W+ 1.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The darts are calculated as follows: Grid Width 1-6 of the drawing minus X = X1 This excess around the waist, which must be removed in the darts.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | We remove 1/3 of the obtained value into the side tucks and 2/3 - into the back and shelf - a little more in the back and a little less in the shelf.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Side darts
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | In case of lack of volume on the hips, the formula is applied: (1/2 HC + 1.5 cm minus Mesh Width (1-6)) .Set aside along the hip line 43-44 = 53-54 and draw the lines of the back and front of the dress.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Location of the tail tuck of the back: divide the segment 2-24 in half - point G is obtained. From point G, lower the vertical segment G-G1 down to the hip line ↓, point 35 is obtained on the waist line, point G1 is obtained on the hip line. GG2 = 3-4 cm, G1-G3 = 2 cm. Build a back tuck of the estimated depth as shown in the figure.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Waist front tuck. From point C drawn down to the hip line, point 36 was obtained on the waist line. SG4 = 5-6 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | For additional fitting along the middle seam of the back from point 3, put a tuck 1-1.5 cm deep to the right and connect the resulting point with points 2 and 4 with a smooth line.
    hr
    .education__item#idea
      b-row
        b-col
          b-card-group(deck='')
            b-card(title="The Purpose of the online fashion designer course" img-src="/idea.png" img-alt="The Purpose of the online fashion designer course" img-right='')
              b-card-text
                | In fact, most online fashion designer course are paid or only partially free. So we decided to constantly fill the page with new content. Our purpose to make a completely free, useful resource for novice clothing designers - really free online fashion designer course.
    hr
    blog-part
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Online fashion designer course",
      meta: [
        {
          name: 'keywords',
          content: 'online fashion course, designer course, custom design, fashion designer, Online fashion designer course, desing course, tailor course, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog'
        },
        {
          name: 'description',
          content: "Free fashion designer course: allowances, taking measurements, building the base, etc"
        }
      ]
    },
    data() {
      return {
        table_items_1:[
          {
            product_type: 'dress',
            very_tight: '2-2.5; 1-1.5; 0.5-1',
            tight: '3-4; 2-3; 1-1.5',
            medium: '4-5; 4-5; 2-3',
            free: '5-6, -; -',
            very_free: '7-9; -; -'
          },
          {
            product_type: 'jacket',
            very_tight: '3-4, 1.5-2; 0.5-1.5',
            tight: '4-5; 3-4; 1.5-3',
            medium: '6-7; 5-7; 3-5',
            free: '7-9; -; -',
            very_free: '9-11; -; -'
          },
          {
            product_type: 'coat (demi-season)',
            very_tight: '4-5; 2-3; 2.5-3',
            tight: '5-6; 4-5; 3-4',
            medium: '6-8; 6-8; 5-7',
            free: '9-11; -; -',
            very_free: '11-14; -; -'
          }
        ],
        table_items_2:[
          {
            product_type: 'dress',
            very_tight: '0.3-0.4; 0.1-0.2',
            tight: '0.5-0.8; 0.1-0.4',
            medium: '0.7-1; 0.2-0.5',
            free: '0.9-1.1; 0.3-0.6',
            very_free: '1.2-1.6; 0.4-0.8'
          },
          {
            product_type: 'jacket',
            very_tight: '0.6-0.8; 0.2-0.4',
            tight: '0.7-0.9; 0.2-0.5',
            medium: '0.9-1.2; 0.3-0.6',
            free: '1.2-1.6; 0.4-0.8',
            very_free: '1.5-2; 0.5-1'
          },
          {
            product_type: 'coat (demi-season)',
            very_tight: '0.7-0.9; 0.2-0.5',
            tight: '0.8-1.1; 0.2-0.6',
            medium: '1-1.4; 0.3-0.7',
            free: '1.4-2; 0.5-1',
            very_free: '1.6-2.2; 0.5-11'
          }
        ],
        table_items_3:[
          {
            gasket_name: 'batting (synthetic winterizer) in one layer',
            insulation_thickness_cm: '0.4',
            allowance_1: '1.5',
            back_distribution: '0.25',
            armhole_distribution: '1',
            distribution_on_a_shelf: '0.25',
            allowance_2: '2'
          },
          {
            gasket_name: 'batting (synthetic winterizer) in two layers',
            insulation_thickness_cm: '0.8',
            allowance_1: '2.5',
            back_distribution: '0.5',
            armhole_distribution: '1.5',
            distribution_on_a_shelf: '0.5',
            allowance_2: '3'
          }
        ],
        table_items_4:[
          {
            sleeve_characteristic: 'tight-fitting',
            dress: '2-2.5',
            jacket: '3.5-5.5',
            coat_demi_season: '5-6',
          },
          {
            sleeve_characteristic: 'narrow',
            dress: '3-4',
            jacket: '6-7.5',
            coat_demi_season: '6-8',
          },
          {
            sleeve_characteristic: 'medium',
            dress: '5-6',
            jacket: '7.5-9.5',
            coat_demi_season: '8-11',
          },
          {
            sleeve_characteristic: 'extended',
            dress: '8-11',
            jacket: '9.5-11.5',
            coat_demi_season: '11-13',
          },
          {
            sleeve_characteristic: 'wide',
            dress: '10-12',
            jacket: '11.5-13.5',
            coat_demi_season: '13-17',
          },
          {
            sleeve_characteristic: 'full',
            dress: '>12',
            jacket: '>13.5',
            coat_demi_season: '>17',
          }
        ],
        table_items_5: [
          {
            measurement_name: 'waist',
            value: '10',
            half_value: '5',
            third_value_optionally: '3.33',
            quarter_value_optionally: '2.5'
          },
          {
            measurement_name: '...',
            value: '...',
            half_value: '...',
            third_value_optionally: '...',
            quarter_value_optionally: '...'
          },
          {
            measurement_name: '...',
            value: '...',
            half_value: '...',
            third_value: '...',
            third_value_optionally: '...',
            quarter_value_optionally: '...'
          }
        ],
        se_table_items_1:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the shoulder cuts',
            size_allowances_cm: '1.5-2'
          },
          {
            name_of_allowances: 'to the cuts of the armhole',
            size_allowances_cm: '1'
          },
          {
            name_of_allowances: 'to the neckline',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the waist line of the detachable bodice',
            size_allowances_cm: '2-4'
          },
          {
            name_of_allowances: 'to the cuts of the sleeve',
            size_allowances_cm: '1.5-2'
          },
          {
            name_of_allowances: 'sleeve hem',
            size_allowances_cm: '3-4'
          },
          {
            name_of_allowances: 'dress bottom hem',
            size_allowances_cm: '4-6'
          }
        ],
        se_table_items_2:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the waist line',
            size_allowances_cm: '1'
          },
          {
            name_of_allowances: 'dress bottom hem',
            size_allowances_cm: '4-6'
          }
        ],
        se_table_items_3:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to step cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the middle cut of the back half',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the cut of the front half of the bow',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the slope cuts',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the cuts of the codpiece',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the waistline',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'bottom hem',
            size_allowances_cm: '3-5'
          }
        ]
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.education {
  padding: 15px;
}
.education__item {
  padding: 15px;
}
@media screen and (max-width: 600px) {
  .education__item#tailor_patterns .flex-row-reverse {
    flex-direction: column !important;
  }
}
</style>