<template lang="pug">
  .collection-example
    b-jumbotron(header-tag='h2' header-level='4' header='Collection example' lead='How the app for design clothes online can be useful when creating a collection:')
      b-row
        b-col(xl="8" lg="8" md="12" sm="12")
          ul
            li
              | Design male and female garments (overalls, jackets, skirts, windbrekaers, pants, dresses, tunics etc)
            li
              | You have a wide variety of options to add to your garment layout - many varieties of pockets, accessories, belts, buttons etc.
            li
              | Also You can immediately find a suitable fill / fabric for your technical sketch.
            li
              | The ability to load your own garment layouts, details, fabrics is especially important.
            li
              | You can also save the current design to use it later.
            li
              | It is possible to design two designs at the same time, so that it would be more convenient to compare them or see if one garment fits another.
            li
              | Finanlly, export your design as a png (jpeg, webp) image.
            li
              | We also created a free AI Tool for Fashion, which allows you to get interstitial design options for the selected type of clothing.
          b-button(variant='primary' href='/collections') How to create your own collection with a clothing design app
        b-col(xl="4" lg="4" md="12" sm="12")
          div
            b-carousel#carousel-1(v-model='slide' :interval='4000' controls='' indicators='' background='#e9ecef' img-width='400' img-height='400' style='text-shadow: 1px 1px 2px #333;' @sliding-start='onSlideStart' @sliding-end='onSlideEnd')
              b-carousel-slide(caption='Overalls' text='Design clothes online - overalls by clothing design app' img-src='/examples/overalls.webp' img-alt="overalls")
              b-carousel-slide(caption='Vest' text='Design clothes online - vest' img-src='/examples/vest.webp' img-alt="vest")
              b-carousel-slide(caption='Raincoat' text='Design clothes online - raincoat by clothing design app' img-src='/examples/raincoat.webp' img-alt="raincoat")
              b-carousel-slide(caption='Windbreaker' text='Design clothes online - windbreaker by Nastix Design App' img-src='/examples/windbreaker.webp' img-alt="windbreaker")
    .mb-1
</template>
<script>
  export default {
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    }
  }
</script>
<style lang="scss" scoped>
.collection-example {
  margin: 36px auto 0px auto;
}
.collection-example li {
  font-size: 20px;
  line-height: 30px;
}

.collection-example__benefits {
  padding: 16px;
  margin: 36px auto 0px auto;
}
.collection-example__imgs {
  display: flex;
}
.collection-examplge_img {
  width: 25%;
}
.collection-examplge_img img {
  width: 100%;
}
.jumbotron .btn-primary {
  color: white;
}
@media only screen and (max-width: 1024px) {
  .collection-example__benefits {
    padding: 16px;
    width: 100%;
    margin: 36px auto 0px auto;
  }
}
</style>