import axios from "axios";
import store from '@/store/index';

axios.interceptors.request.use(config => {
  var langs = { 'Українська': 'Ukrainian', 'English': 'English' }
  const locale = langs[store.getters['language'] || 'English'];
  if (locale) {
    config.headers['locale'] = locale;
  }

  return config;
});

const fetchAll = params => {
  return axios.get("/api/v1/item_types", params);
};

const fetchItemsById = (id, params) => {
  return axios.get(`/api/v1/item_types/${id}/items`, {
    params
  });
};

export default {
  fetchAll,
  fetchItemsById
};