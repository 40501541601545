<template lang="pug">
  div(class="app-selected-items-list")
    | Selected items list
    div(v-for="item in selectedItems")
      selected-item( 
        :item="item"
      )
</template>
<script>
  import SelectedItem from "./SelectedItem.vue"
  export default {
    name: 'selected-items-list',
    components: {
      SelectedItem
    },
    props: {
      selectedItems: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data() {
      return {
      }
    }
  }
</script>
<style lang="scss" scoped>
  .app-selected-items-list {
  	background-color: pink;
  	height: 100px;
    display: none;
  }
  .app-selected-items-list:after {
    content: "";
    display: table;
    clear: both;
  }
</style>