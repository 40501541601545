<template lang="pug">
  div(class="app-selected-item")
    b
      | {{ item.name}}
    img(:src="this.item.img.url" height='42' width='42')
    
</template>
<script>
  export default {
    name: 'selected-item',
    data() {
      return {
      }
    },
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    methods: {
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  .app-selected-item {
    background-color: white;
    border: 1px solid;
    float: left;
    margin: 3px;
  }
</style>