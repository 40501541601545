<template>
  <div class="application-footer">
    <span><a href="./app">Nastix Design</a> &copy; {{current_year}} </span> - {{$ml.get('freeSoftware')}} | <span><a href="./privacy-policy">{{$ml.get('privacyPolicy')}}</a></span>
  </div>
</template>
<script>
export default {
  name: 'application-footer',
  computed: {
    current_year() {
      return new Date().getFullYear();
    }
  },
}
</script>
<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .application-footer {
    background-color: $application-main-color;
    color: white;
    min-width: 1245px;
    height: 59px;
    border-top: 1px solid $application-border-color;
    padding: 20px 15px;
  }
</style>


