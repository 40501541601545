<template>
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ul class="nav">
        <template v-for="(item, index) in navItems">
          <div>{{ item }}</div>
        </template>
      </ul>
    </nav>
  </div>
</template>
<script>

export default {
  name: "sidebar",
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle("open");
    }
  }
};
</script>

<style lang="css" scoped>
  .sidebar {
    display: none;
  }
  .nav-link {
    cursor: pointer;
  }
</style>
