<template lang="pug">
  div(class="mass-upload-guide")
    h2.d-flex.justify-content-center
      | How to upload all your costumes, features, materials to the app at once
    p
      | This feature allows you to download all your costumes, features and materials at once.
    p
      | This is very handy if you:
    ul
      li
        | use the application regularly
      li
        | you have a lot of your own costumes, features and materials
    p
      | It is much faster and easier to download everything together, and choose the right one in the application than to spend time downloading everything separately, choosing options with the customer.
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    p
      | First of all, you need to prepare drawings according to the requirements:
    ul
      li
        b
          | For costumes: 
        | Size 800x800px. Prefer PNG. For 'Choose material' to work, the inside of the costume must be transparent, the outside is white (
        a(href="/examples/dress_example_for_custom_layout.png")
          | example
        | ).
      li
        b
          | For features: 
        | Prefer size 80x80px. Prefer PNG.
      li
        b
          | For materials: 
        | Prefer size 800x800px. Prefer PNG.
    p
      | Then download the example archive from the 
      a(href="/examples/upload_items_template.zip")
        |link
      |  and unzip it.
    p
      | Put your pictures in the appropriate folders.
    p
      | Archive folder.
    p
      | Upload the zip-archive to the site.
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    b-row
      b-col
        div
          b-embed(
            type="iframe"
            aspect="16by9"
            src="https://www.youtube.com/embed/cUX98iPNDVs"
            allowfullscreen
          )
      b-col
        p
          i
            | Your images will not be sent to the site's server, they are only in your browser, so they cannot be saved. Therefore, each time you run the application, if necessary, they must be uploaded again.
        p
          i
            | Also, if you do not want to load your entire database together, you can prepare several archives, for example, separate for dresses, separate for skirts, or separate for overalls, and separate for sportswear, also with features and materials.
    hr
    blog-part
    div
      b-card(
        overlay=""
        img-src="/education_imgs/design_course.jpg"
        img-alt="Online fashion designer course"
        text-variant="white"
        title="design course"
        sub-title="Free online fashion designer course"
      )
        b-card-text
          | We are creating a resource that will be useful for novice designers, and will be a free online fashion design course.
        b-button(href="/education" variant="outline-primary")
          | Read design course
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    mounted() {
      localStorage.setItem('history', JSON.stringify([]));
      document.querySelector('html').style.backgroundColor = '#fff';
      document.querySelector('body').style.backgroundColor = '#fff';
    }
  }
</script>

<style lang="scss" scoped>
  .mass-upload-guide {
    padding: 15px;
  }
</style>