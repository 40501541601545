<template lang="pug">
  .blog
    h2
      router-link(to="blog")
        | Blog
      | : 
      | Building the basic base of the skirt
    .mt-3
    .base-dress
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='To build the basic base of the skirt, measurements are needed')
              b-card-text 
                ul
                  li Waist 
                    b-badge(variant="info")
                      | W
                  li Hip circumference 
                    b-badge(variant="info")
                      | HC
                  li Hip height 
                    b-badge(variant="info")
                      | HH
                  li Product length
                    b-badge(variant="info")
                      | BL
          p.mt-3
            | How to take measurements can be found in our blog at the 
            router-link(to="/taking_measurements")
              | link
            | .
          .mt-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mt-3
          b-img(src="/education_imgs/base_skirt.png" center="" fluid="" alt="Basic base of the skirt")       
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Step back from the top corner of the sheet diagonally 5-10 cm, put a point 1.
              small
                //- | Donec id elit non mi porta.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw a rectangle 1-11= 3-31 wide 1-11=3-31= 1/2 hip circumference +1 cm
                  .mb-3
                  | 1-3 = 11-31 (length of the skirt).
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Side line
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Divide the segment 1-11 in half - point 12, from point 12 lower the perpendicular down to segment 3-31 - point 32 is obtained.
          .mb-3
          h4
            | Hip line
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 1, lay down 1-2 (HH). From point 2, draw a horizontal line to the right. At the intersection with the side line, we got point 21.
          .mb-3
          h4
            | Raise the side line
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From the point of view 12 extended upwards 12-4 = 1 cm (the value is not constant and can be made within 0.5 - 1.5 cm, depending on the shape of the hips).
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Calculation of darts
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Darts are calculated using the formula: (Hip circumference minus waist circumference) / 2
                  .mb-3
                  | For example: (100 - 76) / 2 = 12 cm From the calculated 12 cm, we remove half of the side darts (12/2 = 6.0 cm) - 3.0 cm each. From point 4, set aside to the left and right 41 = 42 = 3.0 cm. Draw the lines of the side seams, connecting points 41-22 and 42-22 with smooth lines along the pattern.
          .mb-3
          h4
            | Waist seams
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Sew the waist seams, connecting the points 1-41 and 11-42 with smooth lines.
          .mb-3 
          h4
            | The final calculation of the darts
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Distribute the remaining excess fabric along the waist into the darts of the back and front halves of the skirt: 60% - in the back darts and 40% - in the front darts.
          .mb-3
          h4
            | The location of the back waist tuck
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Split the line 2-22 in half - point 5 is obtained. From point 5, lift up the perpendicular to the waist line - point 51.
                  .mb-3
                  | The depth of the tuck on the back half of the skirt 52-53 = 60% (3.6 cm) length 51-54 = 13-14 cm. From point 51 set aside to the left and right (1.8 cm), build a tuck with slightly rounded sides according to the length pattern 14 cm.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Location of the front waist tuck
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 42, set aside to the right along the waist cut 42-6 = 8 cm (the value determines the position of the waist tuck, can be changed, therefore the position of the waist tuck will be changed). From point 6, lower the perpendicular down to the line of the hips - point 61.
                  .mb-3
                  | The depth of the front tuck is 40% 62-63 = 2.4 cm. From point 6, set aside 1.2 cm to the left and right. The length of the tuck on the front half of the skirt 6-64 = 10 cm. Pull the top of the tuck to the left by 0.5 cm: 64-65 = 0.5 cm. Build a tuck with slightly rounded sides according to the pattern.
                  .mb-3
                  | There is a direct relationship between the depth and length of the tuck - the deeper the tuck, the longer it is.
          .mb-3
          h4
            | Belt construction
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The length of the belt is calculated using the formula: 1-2=4-5 = Waist circumference + 4 cm (of which 2 cm is an increase in freedom of the fit and 2 cm is an increase in the fastener).
                  .mb-3
                  | The standard width of the belt is 1-4=2-5=8 cm (4 cm finished), but this value may vary depending on the style of the skirt.
                  .mb-3
                  | Loop location: 2-3 = 2 cm.
                  .mb-3
                  | To build a figured corner, set aside 1 cm from the edge of the belt (points in the center) to the right and draw a figured edge of the belt. Duplicate the outer side of the belt with a gasket.
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "../GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Building the basic base of the skirt",
      meta: [
        {
          name: 'keywords',
          content: 'skirt design, skirt base, fashion skirt design, skirt designer, fashion skirt, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, women outerwear, outerwear assortment'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: General characteristics of clothing, Characteristics of the clothing range, Assortment of women's outerwear"
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.blog {
  padding: 15px;
}
.base-dress p, .base-dress li, .base-dress .card-header {
  font-size: 16px;
}
.base-dress li {
  padding-bottom: 4px;
}
</style>