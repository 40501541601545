<template lang="pug">
  .video-for-you
    b-jumbotron(header-tag='h2' header-level='4' header='Clothing design training' lead='We regularly try to create useful content on our social media pages.')
      b-row
        b-col
          p
            | Follow our clothing design app on 
            a(href="https://www.facebook.com/NastixDesign" target="_blank")
              |Facebook
            | .
          p
            span
              | Also we constantly shoot 
            b 
              | a training video for you and put it on 
              a(href="https://www.tiktok.com/@nastix_design" target="_blank")
                | TikTok
              | .
            span
              | These are mainly guides on how you can design many types of garments using our app for design clothes. Sometimes these are funny videos dedicated to the holidays. And sometimes practical advice on sewing and construction.
          p
            | In any case, this channel will be useful to you. After all, here you can pick up a lot of new ideas that you have not thought about before. And most importantly, repeating and improving our idea with our app for design clothes is very simple and fast.
          p
            | It only takes a couple of minutes to create a finished design. And in order to fully use the capabilities of Nastic-Design app for design clothes, we recommend trying to add your own detail, load the fabric.
          p
            | Also prepare a mock-up of the suit (800 by 800 pixels, transparent inside, white background outside - this is necessary for the fill to work). Download and design your garment. This is more difficult, since it requires a one-time preparation of the layout, but later on it can be used repeatedly.
          p
            | For example, you are a sales manager. Each time you agree with the client on the design layout by hand on a sheet of papper. Let our layouts differ from what you produce. It's OK. Prepare drawings once and loads as needed, fill in your fabric, add your own special details. Unlike the use of professional design programs and graphic editors, our application does not require special skills and knowledge, and is also quick to use. 
          p
            b
              | Your customers will be happy with free app for design clothes online.
          b-button(variant='primary' href='/education') Free fashion design course
</template>
<script>
  export default {
    name: 'video-for-you',
    components: {
    },
    data() {
      return {
      }
    },
    mounted() {
      // $( ".first-invitation" ).remove();
      let tiktokScript = document.createElement('script')
      tiktokScript.setAttribute('src', 'https://www.tiktok.com/embed.js')
      document.head.appendChild(tiktokScript)
    }
  }
</script>

<style lang="scss" scoped>
.video-for-you {
  margin: 36px auto 0px auto;
}
.video-for-you__list {
  display: flex;
}
.video-for-you__item {
  width: 33%;
}
.video-for-you__text {
  padding: 16px;
  margin: 36px auto 0px auto;
  font-size: 20px;
  line-height: 30px;
}
.invitation-fb {
  margin-bottom: 15px;
}
.jumbotron .btn-primary {
  color: white;
}
.collection-example li {
  font-size: 20px;
  line-height: 30px;
}
.video-for-you p {
  font-size: 20px;
  line-height: 30px;
}
@media only screen and (max-width: 1024px) {
  .video-for-you__text {
    padding: 16px;
    width: 100%;
    margin: 36px auto 0px auto;
  }
  .video-for-you__list {
    display: block;
  }
  .video-for-you__item {
    width: 100%;
  }
}
</style>