<template lang="pug">
  .blog
    b-jumbotron(header='Nastix Design Blog' lead='Blog about designing and modeling clothes. Useful articles for constructors and fashion designers.')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/design_course.jpg"
        img-alt="Online fashion designer course"
        text-variant="white"
        title="design course"
        sub-title="Free online fashion designer course"
      )
        b-card-text
          | We are creating a resource that will be useful for novice designers, and will be a free online fashion design course.
        b-button(href="/education" variant="outline-primary")
          | Read design course
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Building the basic base of the dress"
        text-variant="white"
        title="Building the basic base of the dress"
        sub-title="To build the basic basis of the dress, measurements are needed"
      )
        b-card-text
          | Steps for building the basic base of the dress.
        b-button(href="/base_dress" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Building the basic base of the skirt"
        text-variant="white"
        title="Building the basic base of the skirt"
        sub-title="To build the basic basis of the skirt, measurements are needed"
      )
        b-card-text
          | Steps for building the basic base of the skirt.
        b-button(href="/base_skirt" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="How to Take Body Measurements"
        text-variant="white"
        title="Take Measurements"
        sub-title="How to Take Body Measurements"
      )
        b-card-text
          | Read about main body measurements.
        b-button(href="/taking_measurements" variant="outline-primary")
          | Take Measurements
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Characteristics of clothing"
        text-variant="white"
        title="Characteristics of clothing"
        sub-title="General characteristics of clothing"
      )
        b-card-text
          | + Assortment of women's outerwear + Characteristics of the clothing range
        b-button(href="/characteristics_clothing" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Tailor's patterns and rulers"
        text-variant="white"
        title="Tailor's patterns and rulers"
        sub-title="Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns."
      )
        b-card-text
          | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
        b-button(href="/tailor_pattern" variant="outline-primary")
          | Read
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Pattern difference"
        text-variant="white"
        title="Sloper pattern"
        sub-title="VS block pattern"
      )
        b-card-text
          | the difference between a sloper pattern and block pattern
        b-button(href="/pattern_difference" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Seam allowances"
        text-variant="white"
        title="Seam allowances"
        sub-title="VS Ease allowance"
      )
        b-card-text
          | added to the details of the pattern along all contours for assembling and processing the seams of the product
        b-button(href="/seam_allowances" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Ease allowance"
        text-variant="white"
        title="Ease allowance classification"
        sub-title="Allowances for the freedom of fitting"
      )
        b-card-text
          | Ease allowance (allowances for the freedom of fitting) classification.
        b-button(href="/ease_allowance" variant="outline-primary")
          | Read
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"

  export default {
    components: {
      GoogleAdSense
    },
    metaInfo: {
      title: "Blog",
      meta: [
        {
          name: 'keywords',
          content: 'custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog'
        },
        {
          name: 'description',
          content: 'Blog about designing clothes. Also Online fashion designer course. For novice designers.'
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
.blog .card-subtitle {
  color: #28231d !important;
}
</style>