<template lang="pug">
  div(
    class="app-costume-filter"
  )
    span.app-filters__title
      | {{ category.name}}

    b-form-group
      b-form-checkbox-group(id="category.id" v-model="selected_items" name="category.name")
        div(v-for="item in category.category_items" :key="item.id")
          b-form-checkbox(
            :value="item.id"
          )
            | {{localizeName(item)}}
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'costume-filter',
    data() {
      return {
        selected_items: []
      }
    },
    props: {
      category: {
        type: Object,
        default() {
          return {
          }
        }
      }
    },
    methods: {
      selectCategory() {
        this.$emit('select-category', [this.category.id, this.selected_items]);
      },
      localizeName(item) {
        if (this.language  === 'Українська') {
          return item.name_uk
        } else if (this.language.substring(0,3)  === 'Esp') {
          return item.name_es
        } else {
          return item.name
        }
      }
    },
    watch: {
      selected_items(newVal, oldVal) {
        this.selectCategory(newVal)
      }
    },
    computed: {
      ...mapGetters(['language'])
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  .app-filters__title {
    color: white;
    font-size: 26px;
    font-weight: 100;
    min-width: 400px;
  }
</style>