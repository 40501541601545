<template lang="pug">
  .blog
    .cotton_fabrics
      h2
        router-link(to="blog")
          | Blog
        | :
        | Sewing tips for cotton fabrics
      p
        | Cotton fabrics are a natural material that is made from dense cotton. Cotton fabric is used to wash everyday clothes, bedding, curtains, and even furniture upholstery.
      p
        | There are several types of cotton fabrics - chintz, satin, calico, cotton, flannel, etc.
      b-table(responsive="" striped hover :items="cotton_types")
      .mb-1
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      .mb-1
      b-row
        b-col
          b-card(no-body='true' header="Advantages of cotton fabric")
            b-list-group(flush)
              b-list-group-item
                | ease of cutting and sewing
              b-list-group-item
                | high strength
              b-list-group-item
                | excellent air permeability
              b-list-group-item
                | absorb excess moisture
              b-list-group-item
                | do not cause allergies
              b-list-group-item
                | pleasant to wear
              b-list-group-item
                | easy care
              b-list-group-item
                | breathability
              b-list-group-item
                | lightness
        b-col
          b-card(no-body='true' header="Disadvantages of cotton fabric")
            b-list-group(flush='true')
              b-list-group-item
                | shrink after washing
              b-list-group-item
                | they don't keep their shape
              b-list-group-item
                | wear is higher than synthetics
              b-list-group-item
                | wrinkle strongly
              b-list-group-item
                | do not warm
      .mb-3
      p
        b
          | Cotton fabrics shrink significantly after washing - up to 10%.
      p
        | Before cutting and sewing products from cotton fabrics, the fabric must be prepared - moistened, dried and ironed:
      ul
        li
          | Soak the cloth in plenty of room temperature water.
        li
          | Hang up without spinning when folded, so that water from the glass fabric.
        li
          | Dry in a ventilated area.
        li
          | Iron, slightly moisturizing.
      p
        | If, during soaking, the water was tinted to the color of the fabric, therefore, the fabric was dyed with unstable paints. In this case, the finished product should be washed only in cold water, using shampoo or special products for colored fabrics.
      hr
      blog-part
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      .mb-1
      div
        b-card(
          overlay=""
          img-src="/education_imgs/allowances.webp"
          img-alt="Tailor's patterns and rulers"
          text-variant="white"
          title="Tailor's patterns and rulers"
          sub-title="Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns."
        )
          b-card-text
            | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
          b-button(href="/tailor_pattern" variant="outline-primary")
            | Read
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
      .mb-1
      div
        b-card(
          overlay=""
          img-src="/education_imgs/characteristics_clothing.webp"
          img-alt="Seam allowances"
          text-variant="white"
          title="Seam allowances"
          sub-title="VS Ease allowance"
        )
          b-card-text
            | added to the details of the pattern along all contours for assembling and processing the seams of the product
          b-button(href="/seam_allowances" variant="outline-primary")
            | Read
      .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"
  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Sewing tips for cotton fabrics",
      meta: [
        {
          name: 'keywords',
          content: 'cotton fabrics, fabrics, custom design, novice designer, fashion blog, fashion design blog, design blog, tailor blog, sewing blog'
        },
        {
          name: 'description',
          content: "Sewing tips for cotton fabrics."
        }
      ]
    },
    mounted() {
      $( ".first-invitation" ).remove();
    },
    data() {
      return {
        cotton_types:[
          {
            type: 'flannel',
            compound: 'cotton, wool, viscose',
            applying: 'Bathrobes, underwear for newborns, bed linen'
          },
          {
            type: 'chintz',
            compound: 'cotton',
            applying: 'diapers, baby clothes, underwear, bathrobes, pajamas'
          },
          {
            type: 'muslin',
            compound: 'cotton with wool and silk',
            applying: 'bed linen, underwear, nightgowns'
          },
          {
            type: 'bumazea',
            compound: 'cotton, linen',
            applying: "children's clothing, clothing for the home"
          },
          {
            type: 'coarse calico',
            compound: 'cotton, polyester fibers',
            applying: 'tablecloths, curtains, bed linen, sleepwear'
          },
          {
            type: 'poplin',
            compound: 'cotton with the addition of silk, wool, synthetic fibers',
            applying: "bed linen, children's clothing, nightgowns"
          },
          {
            type: 'satin',
            compound: 'cotton, polyester',
            applying: "children's clothing, nightgowns, pajamas, bathrobes"
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>
