import axios from "axios";
import store from '@/store/index';

axios.interceptors.request.use(config => {
  const locale = store.getters['language'];
  if (locale) {
    config.headers['locale'] = locale;
  }

  return config;
});

const fetchActiveFabrics = params => {
  return axios.get("/api/v1/fabrics", params);
};

export default {
  fetchActiveFabrics
};