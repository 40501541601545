import oauth2Providers from '@/common/login_providers';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import ApiUser from '@/api/user.js'

const login = ({ commit }, params) => {
  if (params.oauth2) {
    const link = oauth2Providers.find(link => link.slug === params.oauth2);
    if (link) {
      window.location.href = link.url;
    }
    return;
  }
};

const logout = ({ commit }) => {
  // delete axios.defaults.headers.common['NastixDesign-User-Id'];
  window.location.href = '/users/sign_out';
  // commit('LOGOUT');
};

const changeLanguage = ({ commit }, params) => {
  console.log('changeLanguage')
  commit('CHANGE_LANGUAGE', params);
};


export default {
  login,
  logout,
  changeLanguage
};

// import authAPI from "../../../api/user";

// const authUser = ({
//     commit
// }, params) => {
//     return authAPI.userLogin(params.email, params.password).then(response => {
//         commit("AUTH_USER", response.data);
//     });
// };

// const logout = ({
//     commit
// }) => {
//     return commit("LOGOUT");
// };

// const resetPassword = ({
//     commit
// }, email) => {
//     return authAPI.resetPassword(email);
// };

// const updatePassword = ({
//     commit
// }, params) => {
//     return authAPI.updatePassword(params);
// };

// const updateUser = ({
//     commit
// }, params) => {
//     return authAPI.updateUser(params).then(response => {
//         commit("AUTH_USER", response.data);
//     });
// };

// const setAuthHeader = ({
//     commit
// }) => {
//     commit("SET_AUTH_HEADER");
// }

// export default {
//     authUser,
//     logout,
//     resetPassword,
//     updatePassword,
//     updateUser,
//     setAuthHeader
// };