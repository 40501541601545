<template lang="pug">
  div(
    class="app-item"
    @click='selectItem()'
  )
    .app-item__title
      | {{ item.name}}
    img(:src="this.item.img.url" class="app-item__preview" :draggable="false" :alt="item.description || item.name")
    
</template>
<script>
  export default {
    name: 'item',
    data() {
      return {
      }
    },
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    methods: {
      selectItem() {
        this.$emit('select-item', this.item);
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .application {
    .app-item {
      box-shadow: 3px 3px 3px $application-border-color;
      border-radius: 3px;
    }
    .app-item:hover {
      box-shadow: 6px 6px 3px $application-border-color;
    }
    .app-item:active {
      box-shadow: 0px 0px 0px $application-border-color;
    }
  }
  .app-item {
    transition: height 0.5s;
    background-color: white;
    border: 1px solid darkred;
    float: left;
    margin: 3px;
    position: relative;
    height: 82px;
    width: 82px;
    cursor: pointer;
  }
  .app-item__preview {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
  .app-item__title {
    transition: background 1s;
    position: absolute;
    width: 100%;
    bottom: 0px;
    color: white;
    font-size: small;
    background: rgba(55,0,0,0.4);
  }
  .app-item:hover {
    height: 100px;
  }
  .app-item:hover > .app-item__title {
    background: rgba(55,0,0,0.8);
  }
</style>