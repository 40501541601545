<template lang="pug">
  .invitation
    .invitation-item.mdc-card
      #fb-root
      b-jumbotron(header-tag='h2' header-level='4' header='About design clothes online app' lead='')
        b-row.invitation-item__about
          b-col
            p
              | Nastix Design is a 
              b free 
              | app for design clothes. Our clothes designing software will be useful for 
              b novice designers or students, sales managers of garment factories, ateliers
              | . Creating a technical sketch is now fast and free.
              | You can also use AI tool to generate fashion designs.
          b-col
            b-list-group
              b-list-group-item
                | Costumes count: {{stats['costumes']}}
              b-list-group-item
                | Price: free
              b-list-group-item
                | Features count: {{stats['items']}}
              b-list-group-item
                | Free 3 AI generation per day
        b-row
          b-col
            h3
              | Free AI Tool for Fashion
            p
              | Also we have tool for Generate Realistic Fashion Designs.
            p
              | You can take our costume base (dresses, skirts, etc.) and tell the AI Tool for Fashion how to improve it.
      b-row
        b-col
          h3
            | It's easy to design clothes. You only need to follow three simple steps:
      b-row
        b-col
          b-card(title="Step 1" img-src="/steps/step1.webp" img-alt="Dress design" img-top tag="article" style="max-width: 20rem;" class="mb-2")
            b-card-text
              | Select a suit (jacket, pants, overalls, etc.).
            b-button.button__text--white-color(href="/app" variant="primary")
              |Get started
        b-col
          b-card(title="Step 2" img-src="/steps/step2.webp" img-alt="Dress design" img-top tag="article" style="max-width: 20rem;" class="mb-2")
            b-card-text
              | Add details (pockets, buttons, patches, etc.).
            b-button.button__text--white-color(href="/app" variant="primary")
              |Get started
        b-col
          b-card(title="Step 3" img-src="/steps/step3.webp" img-alt="Dress design" img-top tag="article" style="max-width: 20rem;" class="mb-2")
            b-card-text
              | Select a background (from the suggested ones or your own).
            b-button.button__text--white-color(href="/app" variant="primary")
              |Get started
      b-row
        b-col
          b-card(class="text-center" title="After that, you can:")
            b-card-text
              | download the finished fashion technical sketch to your computer
            b-card-text
              | send fashion design to your email
            b-card-text
              | save your technical sketch to memory slots
      b-row
        b-col
          .invitation-item__additional
            p
              | To work with the app for design clothes, you do not need design skills or experience in working with graphic editors.
            p
              | The app for design clothes is very useful for those who often need to quickly develop a drawing to show to the client. Especially for sales managers. It is especially surprising that even in many large garment factories, sales managers still agree with the customer on the layout, drawing it by hand.
            p
              | If you are just learning to design clothes, our application will be useful for getting new ideas and quickly creating fashion technical sketches.
            p
              | Also free AI Tool for Fashion allows you to allow you to change your costume in an unexpected way, according to your wishes.
</template>
<script>
  import statsApi  from '../api/stats_api'
  export default {
    name: 'invitation',
    components: {
    },
    data() {
      return {
        stats: {
          costumes: 0,
          items: 0
        }
      }
    },
    mounted() {
      this.fetchStats();
    },
    methods: {
      fetchStats(params = {}) {
        statsApi
          .fetchAll(params)
          .then(response => {
            this.stats = response.data
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
.invitation {
  font-size: 20px;
  line-height: 30px;
}
.invitation-text {

}
.invitation-fb {

}
.invitation-advantage div {

}

.invitation-item {
  padding: 16px;
  margin: 36px auto 0px auto;
}
.invitation__statistic-row {
  display: inline-flex;
  width: 100%;
}
.invitation__statistic-item {
  text-align: center;
  margin: auto;
  padding: 15px;
  width: 200px;
}
.invitation__statistic-item--count {
  padding: 15px;
  font-size: 36px;
}
.invitation__statistic-item--title {
  text-transform:uppercase;
  font-size: 24px;
  text-align: center;
}

.invitation__statistic-item--lightblue {
  background: lightblue;
  color: white;
}

.invitation__statistic-item--indianred {
  background: indianred;
  color: white;
}

.invitation__statistic-item--blue {
  background: #87cefa;
  color: white;
}
.invitation__statistic-item--white {
  color: #87cefa;
}
.button__text--white-color {
  color: white !important;
}
.invitation-item__about {
  padding-bottom: 36px;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .invitation-item {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .invitation-item {
    width: 100%;
  }
}
@media only screen and (max-width: 481px) {
  .invitation__statistic-row {
    display: block;
  }
}
.invitation-item__additional {
  padding: 36px 0px;
  font-size:14px;
  font-style: italic;
}
</style>
