<template lang="pug">
  .blog-part
    .mb-3
      h3
        | Interesting from our 
        router-link(to="/blog")
          | blog
        | :
    .mb-3
</template>
<script>
  export default {
    name: 'blog_part'
  }
</script>

</style>