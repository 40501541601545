<template lang="pug">
  .blog
    h2
      router-link(to="blog")
        | Blog
      | : 
      | Building the basic base of the dress
    .mt-3
    .base-dress
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='To build the basic base of the dress, measurements are needed')
              b-card-text 
                ul
                  li Width over bust 
                    b-badge(variant="info")
                      | WOB
                  li Bust 
                    b-badge(variant="info")
                      | B
                  li Bust width (front part of B, a little more than half of B)
                    b-badge(variant="info")
                      | WB
                  li Length between WB and WOB 
                    b-badge(variant="info")
                      | h
                  li Chest height
                    b-badge(variant="info")
                      | CH
                  li Center of chest 
                    b-badge(variant="info")
                      | CC
                  li Waist 
                    b-badge(variant="info")
                      | W
                  li Hip circumference 
                    b-badge(variant="info")
                      | HC
                  li Hip height 
                    b-badge(variant="info")
                      | HH
                  li Back width 
                    b-badge(variant="info")
                      | BW
                  li Armhole width 
                    b-badge(variant="info")
                      | AW
                  li Length to back waist second 
                    b-badge(variant="info")
                      | LBWL2
                  li Shoulder height oblique 
                    b-badge(variant="info")
                      | SHO
                  li Length to waist front 
                    b-badge(variant="info")
                      | LWL
                  li Shoulder height oblique front 
                    b-badge(variant="info")
                      | SHOF
                  li Shoulder length 
                    b-badge(variant="info")
                      | SL
                  li Neck girth 
                    b-badge(variant="info")
                      | NG
                  li Armhole depth 
                    b-badge(variant="info")
                      | AD
                  li Shoulder product length 
                    b-badge(variant="info")
                      | SPL
          p.mt-3
            | How to take measurements can be found in our blog at the 
            router-link(to="/taking_measurements")
              | link
            | .
          .mt-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mt-3
          b-img(src="/education_imgs/dress_base.png" center="" fluid="" alt="Basic base of the dress")       
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Step back from the top corner of the sheet diagonally 15-20 cm.
              small
                //- | Donec id elit non mi porta.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Place point 1 in the top left corner.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw a vertical line down from point 1 and set aside along it:
              p(class="mb-1")
                | 1-2 = AD + Ease allowance for armhole depth (about ease allowance)
              p(class="mb-1")
                | 1-3 = LBWL2
              p(class="mb-1")
                | 3-4 = HH
              p(class="mb-1")
                | 1-5 = SPL
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw lines of arbitrary length to the right from points 1, 2, 3, 3 and 5 - the lines of the armhole, waist, hips and bottom of the dress are obtained.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 1-6 = 1/2 BW + AW + 1/2 WB + ease allowance.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Points 21,31,41,51 were obtained vertically from points 6, at the intersection with horizontal lines.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 2, set aside to the right along the armhole line:
              p(class="mb-1")
                | 2-22 =  1/2 BW.
              p(class="mb-1")
                | 22-23 = AW + ease allowance.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Side line
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Divide the segment 22-23 in half: 22-24 = 23-24.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 24, lower the perpendicular down. Points obtained at the intersection with horizontal lines: 32,42,52.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From points 22, lift up and forward to the segment 1-6, point 61 is obtained.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 31, set aside up along the line of the middle of the front 31-7 = LWL.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 7 draw a horizontal line to the left 7-62 = 21-23.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Connect points 62 and 23.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Back
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 1-11 = 1/6 NGto measure + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 11-12 = 2 cm for all sizes.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw the line of the neck of the back along the pattern or by hand.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 3, build an arc with a radius of 3-8 = SHO.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Back shoulder length. 12-8 = SL + 1.5 cm. Point 8 must lie on the arc.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | In our example, the shoulder of the back is lengthened by 1-1.5 cm, and then the excess length is being ironed.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Divide the segment 61-22 in half - point 9. Draw the armhole line of the back from point 8 through point 9 to point 24 along the pattern or by hand.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Front
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 7-71 = 1-11 (back) = 1/6 NG + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 7-72 = 7-71 + 0.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Draw the front neck line 71 - 72 smoothly along the pattern or by hand.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Shoulder length to chest tuck: 71-63 = 4 cm (for all sizes).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | 63-81 = 1.3 cm (for all sizes).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The position of point C (chest point) is determined by two measurements: Chest height and Center of the chest.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From point 71, lay down 71C = CH so that the distance from the mid-front line is 25C (1/2 CC).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Right side of chest tuck 81C: connect point 81 and point C with a straight line.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Chest tuck solution. С1С2 = (WB - WOB) / 2.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Left side of chest tuck: connect point C and C2 with a straight line, extend the line up: C-82 = C-81.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | From points 31 according to the structure of the arc with a radius of 31-83 = SHOF.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Front shoulder length after chest tuck: 82-83 = SL  minus 71-81. Point 83 must lie on the arc.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Front armhole line. From point 23, set aside up 23-91 = 6 cm (auxiliary point). Line the front armhole from point 83 through point 91 to point 24.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The addition to the Half-Circumference of the Waist should be 1.5 cm. X = 1/2 W+ 1.5 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | The darts are calculated as follows: Grid Width 1-6 of the drawing minus X = X1 This excess around the waist, which must be removed in the darts.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | We remove 1/3 of the obtained value into the side tucks and 2/3 - into the back and shelf - a little more in the back and a little less in the shelf.
          .mb-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
          .mb-3
          h4
            | Side darts
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | In case of lack of volume on the hips, the formula is applied: (1/2 HC + 1.5 cm minus Mesh Width (1-6)) .Set aside along the hip line 43-44 = 53-54 and draw the lines of the back and front of the dress.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Location of the tail tuck of the back: divide the segment 2-24 in half - point G is obtained. From point G, lower the vertical segment G-G1 down to the hip line ↓, point 35 is obtained on the waist line, point G1 is obtained on the hip line. GG2 = 3-4 cm, G1-G3 = 2 cm. Build a back tuck of the estimated depth as shown in the figure.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | Waist front tuck. From point C drawn down to the hip line, point 36 was obtained on the waist line. SG4 = 5-6 cm.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | For additional fitting along the middle seam of the back from point 3, put a tuck 1-1.5 cm deep to the right and connect the resulting point with points 2 and 4 with a smooth line.
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "../GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Characteristics of clothing",
      meta: [
        {
          name: 'keywords',
          content: 'characteristics of clothing, clothing range, clothing characteristics, custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, women outerwear, outerwear assortment'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: General characteristics of clothing, Characteristics of the clothing range, Assortment of women's outerwear"
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.blog {
  padding: 15px;
}
.base-dress p, .base-dress li, .base-dress .card-header {
  font-size: 16px;
}
.base-dress li {
  padding-bottom: 4px;
}
</style>