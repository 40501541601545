<template lang="pug">
  .blog
    h2
      router-link(to="blog")
        | Blog
      | : 
      | The difference between a sloper pattern and block pattern
    .mt-3
    .pattern-difference
      b-row
        b-col
          b-list-group
            b-list-group-item
              h5(class="mb-1")
                | sloper pattern - home sewing
              h5(class="mb-1")
                | block pattern - industrial production
          .mt-3
          h4
            | Block pattern
          p
            | A block pattern is a ready-made template, according to which the party is cut out. Patterns are detailed details of the future product.
          p
            | They show all the nuances, allowances, joining seams, take into account the lining and other details.
          .mt-3
          h4
            | Sloper pattern
          p
            | A sloper pattern is a simplified version of patterns. Usually it is made of thin paper, it is the details of the future product. It does not include small details, allowances, thread direction, etc. Patterns are used for sewing a single model or a small batch, or a test sample.
          p
            | The accuracy in the development of the pattern is not of great importance, because it can change during sewing, be adjusted to the shape or material of the product.
          .mt-3
          hr
          p
            | There are also standard patterns by which the quality of other patterns is checked.
          .mt-3
          google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "../GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Characteristics of clothing",
      meta: [
        {
          name: 'keywords',
          content: 'characteristics of clothing, clothing range, clothing characteristics, custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, women outerwear, outerwear assortment'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: General characteristics of clothing, Characteristics of the clothing range, Assortment of women's outerwear"
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.blog {
  padding: 15px;
}
.pattern-difference p, .pattern-difference li, .pattern-difference .card-header {
  font-size: 16px;
}
.pattern-difference li {
  padding-bottom: 4px;
}
</style>