<template lang="pug">
  .blog
    .cotton_fabrics
      h2
        router-link(to="blog")
          | Blog
        | : 
        | Types of pockets
      p
        | Pocket - sewn-in or sewn piece of clothing that serves as a place to wear and store.
      .mb-1
      b-row
        b-col
          b-card(no-body='true' header="Types of pockets")
            b-list-group(flush)
              b-list-group-item
                | External and internal pockets
              b-list-group-item
                | Patch, welt and on-seam pockets
      .mb-1
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      .mb-3
      h4
        | Welt pockets
      p
        | Welt pockets can be framed, with a zipper, with a flap etc, can have a horizontal, inclined or vertical position. 
      p
        | A pocket with a zipper is made on products made of dense fabrics (soft fabric will sag).
      b-row
        b-col
          b-img(src="/education_imgs/pockets1.png" center="" fluid="" alt="Welt pockets")
      h4
        | Patch pockets
      p
        | Patch pockets are cut out of the fabric and sewn onto the front side of the product. They are square, with beveled or rounded corners etc.
      p
        | Most often, the edges of the pocket are ironed, and then it is machine-stitched.
      b-row
        b-col
          b-img(src="/education_imgs/pockets2.png" center="" fluid="" alt="Patch pockets")
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Tailor's patterns and rulers"
        text-variant="white"
        title="Tailor's patterns and rulers"
        sub-title="Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns."
      )
        b-card-text
          | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
        b-button(href="/tailor_pattern" variant="outline-primary")
          | Read
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Seam allowances"
        text-variant="white"
        title="Seam allowances"
        sub-title="VS Ease allowance"
      )
        b-card-text
          | added to the details of the pattern along all contours for assembling and processing the seams of the product
        b-button(href="/seam_allowances" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Sewing tips for cotton fabrics",
      meta: [
        {
          name: 'keywords',
          content: 'cotton fabrics, fabrics, custom design, novice designer, fashion blog, fashion design blog, design blog, tailor blog, sewing blog'
        },
        {
          name: 'description',
          content: "Sewing tips for cotton fabrics."
        }
      ]
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>
