<template lang="pug">
  .second-canvas__row
    .second-canvas__left-block
    .canvas-wrapper__second#canvas__second
      .output_second
        img(:src='image')
        .canvas-second__comment
          | {{inputComment}}
    .second-canvas__right-block
</template>
<script>
  export default {
    name: 'second-preview-canvas',
    components: {
    },
    props: {
      backgroundSize: {
        type: String
      },
      backgroundSizeX: {
        type: Number
      },
      backgroundSizeY: {
        type: Number
      },
      backgroundSizeReplaceX: {
        type: Number
      },
      backgroundSizeReplaceY: {
        type: Number
      },
      inputComment: {
        type: String
      },
      image: {
        type: String,
        default() {
          return ''
        }
      },
      initialImage: {
        type: Array,
        default() {
          return []
        }       
      },
      selectedCostume: {
        type: Object,
        default() {
          return {}
        }
      },
      backgroundUrl: {
        type: String,
        default() {
          return ''
        }
      },
      selectedItems: {
        type: Object,
        default() {
          return {}
        }
      },
      sizeStandart: {
        type: Number,
        default() {
          return 800
        }        
      }
    },
    data() {
      return {
      }
    },
    methods: {
      selectItem(val, itemIndex) {
        // let img_index = itemIndex !== null ? itemIndex : `${this.itemIndex}`;
        let obj = { x: 50, y: 50, size: val['default_size']}
        let new_item = Object.assign(obj, val)
        // this.selectedItems[img_index] = new_item
        // if (copyData) {
        //   this.selectedItems[img_index]['rotate_degree'] = copyData['rotate_degree'] ? copyData['rotate_degree'] : 0;
        //   this.selectedItems[img_index]['size'] = copyData['size'];
        //   this.selectedItems[img_index]['flip'] = !!copyData['flip'];
        // }
        let ref = this
        // insert new item
        let img        = document.createElement("img");
        img.className  = "item-image--second";
        img.id         = `item-image-${itemIndex}--second`;
        img.dataset['index'] = itemIndex;
        let x_position = this.sizeStandart * obj.x / 100;
        let y_position = this.sizeStandart * obj.y / 100;
        // let src        = document.getElementById("app-current-preview-canvas");
        let src        = document.getElementById("canvas__second");
        var newDiv     = document.createElement("div");
        newDiv.id      = `item-el-${itemIndex}--second`;
        newDiv.className = 'item-el--second'
        newDiv.setAttribute("style", `position: absolute; left:${x_position}px; top:${y_position}px; width: 100%; z-index: 1000;`);
        src.appendChild(newDiv);
        if (val.customX) {
          if (val.customX >= val.customY) {
            let coef = val.customY / val.customX
            let h = this.sizeStandart * coef * obj.size / 100;
            var mt = (this.sizeStandart * obj.size / 100 - h)/ 2
            img.setAttribute("style", `padding: 0px; position: absolute; height: ${h}px; width: ${this.sizeStandart * obj.size / 100}px; margin-top: ${mt}px;`);
          } else {
            let coef = val.customX / val.customY
            let w = this.sizeStandart * coef * obj.size / 100;
            var ml = (this.sizeStandart * obj.size / 100 - w)/ 2
            img.setAttribute("style", `padding: 0px; position: absolute; height: ${this.sizeStandart * obj.size / 100}px; width: ${w}px; margin-left: ${ml}px;`);
          }
        } else {
        img.setAttribute("style", `padding: 0px; position: absolute; height: ${this.sizeStandart * obj.size / 100}px; width: ${this.sizeStandart * obj.size / 100}px;`);
        }
        img.src = new_item.img.url;
        img.style["-webkit-transform"] = `${ref.selectedItems[itemIndex]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
        img.style["transform"] = `${ref.selectedItems[itemIndex]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${ref.selectedItems[itemIndex]['rotate_degree']}deg)`;
        newDiv.appendChild(img);
      }
        // img.onmouseover = function(event) {
        //   let target = event.target;
        //   target.style.padding = '0px';
        //   target.style.border = '1px dotted grey';
        // };
        // img.onmouseout = function(event) {
        //   let target = event.target;
        //   target.style.padding = '1px';
        //   target.style.border = 'none';
        // };
        // img.src = new_item.img.url;
        // this.selectedItems[img_index]['x'] = (x_position / this.sizeStandart * 100);
        // this.selectedItems[img_index]['y'] = (y_position / this.sizeStandart * 100);
        // this.selectedItems[img_index]['z_index'] = this.changeIndex;
        // this.changeIndex += 1;
        // if (copyData) {
        // } else {
        //   this.selectedItems[img_index]['rotate_degree'] = 0;
        // }
        // if (itemIndex === null) { this.itemIndex += 1; }
      
    },
    watch: {
      async selectedCostume(val) {
        let obj = { x: 0, y: 0, size: 0 };
        this.costume = Object.assign(obj, val);
        let toRemoveImg = await document.getElementById("selected-costume-image-second");
        if (toRemoveImg) {
          await toRemoveImg.remove();
        }
        let toRemoveItems = await document.getElementsByClassName("item-el--second");
        if (toRemoveItems) {
          await [...toRemoveItems].map(n => n && n.remove());
        }
        document.getElementById("canvas__second").style["background-image"] = 'none';
        let img = document.createElement("img");
        img.className = "costume-image-second";
        img.id = "selected-costume-image-second"
        if (this.costume && this.costume.img_front) {
          img.src = this.costume.img_front.url;
          img.onload = "SVGInject(this)"
          let src = document.getElementById("canvas__second");
          src.appendChild(img);
        }
        document.getElementById('canvas__second').style["background-image"] = `url(${this.backgroundUrl})`;
        if (this.backgroundSize == 'cover') {
          document.getElementById('canvas__second').style.backgroundSize = 'auto';
        } else {
          document.getElementById('canvas__second').style.backgroundSize = `${this.backgroundSizeReplaceX}px ${this.backgroundSizeReplaceY}px`;
        }
        for (const [key, val] of Object.entries(this.selectedItems)) {
          this.selectItem(val, key)
        }
      }
    },
    data() {
      return{
      }
    }
  }
</script>

<style lang="scss">
  .canvas-wrapper__second {
    width: 800px;
    height: 800px;
    background-color: white;
    overflow: hidden;
    position: relative;
  }
  .costume-image-second {
    max-width: 100%;
    max-height: 100%;  
    display: block;  
    position: absolute;
    margin: auto;
    text-align: center;
  }
  .output_second {
    position: absolute;
    z-index: 1;
  }
  .canvas-second__comment {
    z-index: 100;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color:black;
  }
  .second-canvas__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #363636;
  }
  .second-canvas__right-block {
    width: 400px;
    background-color: #474747;
    margin-left: 0px;
    position: relative;
    height: 815px;
  }
  .second-canvas__left-block {
    width: 40px;
    height: 785px;
    left: 0px;
  }
</style>
