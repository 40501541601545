<template lang="pug">
  .blog
    h2
      router-link(to="blog")
        | Blog
      | : 
      | Characteristics of clothing
    .general-characteristics
      h3
        | General characteristics of clothing
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Performance properties')
              b-card-text 
                ul
                  li fit for purpose
                  li convenience
                  li shape retention
                  li reliability
            b-card.text-left(header='Hygienic properties')
              b-card-text 
                ul
                  li protective functions from the adverse effects of the climatic environment
                  li mechanical damage
                  li thermal protection
                  li air permeability, gas permeability
                  li water resistance
                  li dust capacity
                  li weight
            b-card.text-left(header='Aesthetic properties')
              b-card-text 
                ul
                  li marketable condition
                  li conformity to the image of a person
          .mt-3
            b-card-group(deck='')
              b-card(class="text-center")
                | The requirements of manufacturability and cost-effectiveness of the design are applied to clothes of mass industrial production
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .clothing-range
      h3
        | Characteristics of the clothing range
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Purpose of clothing according to its protective function')
              b-card-text 
                ul
                  li household clothes
                  li sportswear
                  li industrial clothes
            b-card.text-left(header='Household clothing according to operating conditions')
              b-card-text 
                ul
                  li outerwear
                  li costume and dress clothes
                  li linen products
                  li corsetry
                  li headwear
                  li gloves and mittens
                p
                  | Each group can be subdivided into types (Example, costume and dress clothes - jackets, trousers, skirts, dresses, etc.)
            b-card.text-left(header='Sex and age sign')
              b-card-text 
                ul
                  li men's clothing
                  li women's clothing
                  li baby clothes
                    ul
                      li for newborn
                      li for toddlers
                      li for preschoolers
                      li for younger students
                      li for older students
                      li for teenagers
      .mt-3
      b-row
        b-col
          b-card-group(deck='')
            b-card.text-left(header='Depending on the time of year and climatic zones')
              b-card-text 
                ul
                  li spring and autumn clothes
                  li summer clothes
                  li winter clothes
                  li all-weather clothing
            b-card.text-left(header='Depending on the use of clothing in a particular situation')
              b-card-text 
                ul
                  li casual wear
                  li home clothes
                  li formal wear
                  li sportswear
                  li industrial clothes
                p
                  | Sportswear is subdivided into subclasses according to sports, according to gender and age
                p
                  | Industrial clothing, depending on the functions performed, is divided into special, departmental, technological (sanitary and hygienic)
            b-card.text-left(header='Depending on the design of the clothes')
              b-card-text 
                ul
                  li according to the method of operation
                    ul
                      li shoulder
                      li waist
                  li according to silhouette
                    ul
                      li adjacent
                      li semi-adjacent
                      li straight
                      li trapezoidal
                  li according to the volume of the form
                    ul
                      li small
                      li medium
                      li volumetric
                  li according to the cut of the sleeves
                    ul
                      li with set-in sleeves
                      li raglan sleeves
                      li one-piece sleeves
                      li combined sleeves
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .women-outerwear
      h3
        | Assortment of women's outerwear
      b-row
        b-col       
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | coats
                //- small
                //-   | 3 days ago
              p(class="mb-1")
                | Front closure clothing, with sleeves, collar, cape or hood. Coat products include upper moisture- and heat-protective products for everyday wear.
              p(class="mb-1")
                | In demi-season coats, as a rule, there is no insulating pad
              small
                //- | Donec id elit non mi porta.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | short coats
              p(class="mb-1")
                | The short coat differs from the coat only in a shorter length
              p(class="mb-1")
                | Cape - a short coat-type product with a slit in the front, with or without a fastener, with slits for the hands. May or may not be removable. Can be used to decorate and insulate coats.
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | raincoats
              p(class="mb-1")
                | A kind of coat, made of air-supporting or film materials and used to protect against rain
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | wamuses
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | vests
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | jackets
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | suits
              p(class="mb-1")
                | 
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | overalls
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | capes
              p(class="mb-1")
                |
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | coat dress and others
              p(class="mb-1")
                | wamuses, jackets, vests, dress-coats can be made as an independent type of clothing, or in combination (with trousers, skirts, etc.)
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Characteristics of clothing",
      meta: [
        {
          name: 'keywords',
          content: 'characteristics of clothing, clothing range, clothing characteristics, custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, women outerwear, outerwear assortment'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: General characteristics of clothing, Characteristics of the clothing range, Assortment of women's outerwear"
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>