<template lang="pug">
  .blog
    .seam_allowance
      h2
        router-link(to="blog")
          | Blog
        | : 
        | Seam allowances
      h4
        | Ease allowance (allowances for the freedom of fitting) VS Seam allowance
      b-row
        b-col
          b
            | Ease allowance
          ul
            li
              | allow you to achieve the necessary degree of fit of clothing to the body
            li
              | laid by the master at the stage of designing basic patterns
            li
              | determined by the fashion designer depending on the style of the product, the type of fabric, etc
        b-col
          b
            | Seam allowance
          ul
            li
              | adds the master when cutting the product on all sides of the parts
            li
              | for assembly and processing of seams of a product
      .mb-1
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      .mb-1
      b-alert(show="" variant="light")
        p
          | Seam allowances are the amount that is added to the details of the pattern along all contours for assembling and processing the seams of the product.
      p
        | If you build patterns yourself or take them in a magazine, then most often they will be without allowances. If you forget, the product will be small in size
      p
        | Allowances are marked with a wax pencil or a regular pencil, tailor's chalk.
      p
        | The thicker and denser the fabric, the higher the allowance
      h4
        | Seam allowance types
      p
        | Allowances for seams and hem - depends on the type of product, as well as the properties of the material from which it is sewn.
      p
        | Allowances for fitting the product according to the figure - are taken into account by the features of the customer's figure.
      h4
        | The amount of allowances depends on a number of reasons:
      ul
        li
          | from type of detail
        li
          |on the type and properties of the tissue. When cutting thick winter types of fabric, the size of the allowances is increased
        li
          |on the type and purpose of the seam
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Seam allowances for dress details (shoulder product)
      b-table(responsive="" striped hover :items="table_items_1")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Seam allowances for skirt details
      b-table(responsive="" striped hover :items="table_items_2")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Seam allowances for trousers
      b-table(responsive="" striped hover :items="table_items_3")
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Tailor's patterns and rulers"
        text-variant="white"
        title="Tailor's patterns and rulers"
        sub-title="Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns."
      )
        b-card-text
          | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
        b-button(href="/tailor_pattern" variant="outline-primary")
          | Read
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Ease allowance"
        text-variant="white"
        title="Ease allowance classification"
        sub-title="Allowances for the freedom of fitting"
      )
        b-card-text
          | Ease allowance (allowances for the freedom of fitting) classification.
        b-button(href="/ease_allowance" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Seam allowances",
      meta: [
        {
          name: 'keywords',
          content: 'custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, seam allowances, allowances for skirt, allowances, allowances for trousers, allowances for dress, allowance classification'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: Seam allowances for skirt details, for trousers, for dress details."
        }
      ]
    },
    data() {
      return {
        table_items_1:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the shoulder cuts',
            size_allowances_cm: '1.5-2'
          },
          {
            name_of_allowances: 'to the cuts of the armhole',
            size_allowances_cm: '1'
          },
          {
            name_of_allowances: 'to the neckline',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the waist line of the detachable bodice',
            size_allowances_cm: '2-4'
          },
          {
            name_of_allowances: 'to the cuts of the sleeve',
            size_allowances_cm: '1.5-2'
          },
          {
            name_of_allowances: 'sleeve hem',
            size_allowances_cm: '3-4'
          },
          {
            name_of_allowances: 'dress bottom hem',
            size_allowances_cm: '4-6'
          }
        ],
        table_items_2:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the waist line',
            size_allowances_cm: '1'
          },
          {
            name_of_allowances: 'dress bottom hem',
            size_allowances_cm: '4-6'
          }
        ],
        table_items_3:[
          {
            name_of_allowances: 'to the side cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to step cuts',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the middle cut of the back half',
            size_allowances_cm: '1-3'
          },
          {
            name_of_allowances: 'to the cut of the front half of the bow',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the slope cuts',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the cuts of the codpiece',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'to the waistline',
            size_allowances_cm: '0.75-1'
          },
          {
            name_of_allowances: 'bottom hem',
            size_allowances_cm: '3-5'
          }
        ]
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>