<template lang="pug">
  div(
    class="app-costume"
    @click='selectCostume()'
  )
    .app-costume__name
      | {{costume.name}}
    img(:src="this.costume.img_front.preview.url" height='84' width='168' class="app-costume__preview" :draggable="false" :alt="costume.desctiption || costume.name")
</template>
<script>
  export default {
    name: 'costume',
    data() {
      return {
      }
    },
    props: {
      costume: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    methods: {
      selectCostume() {
        this.$emit('select-costume', this.costume);
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .application {
    .app-costume {
      box-shadow: 3px 3px 3px $application-border-color;
      border-radius: 3px;
    }
    .app-costume:hover {
      box-shadow: 6px 6px 3px $application-border-color;
    }
    .app-costume:active {
      box-shadow: 0px 0px 0px $application-border-color;
    }
  }
  .app-costume {
    float: left;
    padding: 3px;
    border: 1px solid;
    margin: 3px;
    background-color: white;
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    border-color: darkred;
  }
  .app-costume__preview {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
  .app-costume__name {
    position: absolute;
    right: 5px;
    bottom: 0px;
    color: darkred;
    font-weight: 100;
  }
</style>