<template lang="pug">
  .blog
    .ease_allowance
      h2
        router-link(to="blog")
          | Blog
        | :
        | Ease allowance (allowances for the freedom of fitting) classification
      p
        | Clothing cannot be an exact copy of the human body. That part of the surface of the body where the clothes fit snugly is called the supporting surface.
      p
        | Below the supporting surface, air gaps are formed between the clothes and the human body, which ensure the convenience of wearing clothes, freedom of breathing, heat exchange, etc. Therefore, clothes are always made larger than the corresponding figure sizes. This difference between the inner measurements of the clothes and the corresponding body measurements is called the ease allowance.
      p
        | In order to maintain the same visual impression from products of a certain silhouette on figures of various sizes and heights, the Ease allowance is increased by 0.5 cm - for figures of small sizes, high growth. For figures of large sizes and low growth, they are reduced by 0.5 cm.
      p
        | For winter and insulated products, these increases are increased. The amount of additional Ease allowance depends on the thickness of the insulation pad.
      p
        | The width of the sleeve is characterized by the magnitude of the Ease allowance in the girth of the shoulder.
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Allowances for free fit with varying degrees of fit in areas of the product, cm
      small
        | breast; waist; hips
      b-table(striped hover :items="table_items_1")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      small
        | back; shelf
      b-table(striped hover :items="table_items_2")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Allowances on the insulating gasket along the constructive areas
      small
        | Allowance_1 - the size of the allowance and the half-girth of the chest (third) (the tape should run horizontally around the body, through the protruding points of the mammary glands and close on the right side of the chest), cm
      br
      small
        | Allowance_2 - the amount of allowance for the insulating pad and shoulder girth, cm
      b-table(striped hover :items="table_items_3")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Allowance value, cm, for products
      b-table(striped hover :items="table_items_4")
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Tailor's patterns and rulers"
        text-variant="white"
        title="Tailor's patterns and rulers"
        sub-title="Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns."
      )
        b-card-text
          | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
        b-button(href="/tailor_pattern" variant="outline-primary")
          | Read
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')    
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Seam allowances"
        text-variant="white"
        title="Seam allowances"
        sub-title="VS Ease allowance"
      )
        b-card-text
          | added to the details of the pattern along all contours for assembling and processing the seams of the product
        b-button(href="/seam_allowances" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Ease allowance",
      meta: [
        {
          name: 'keywords',
          content: 'custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, ease allowance, freedom of fitting, allowance, allowance classification'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: Ease allowance (allowances for the freedom of fitting) classification."
        }
      ]
    },
    data() {
      return {
        table_items_1:[
          {
            product_type: 'dress',
            very_tight: '2-2.5; 1-1.5; 0.5-1',
            tight: '3-4; 2-3; 1-1.5',
            medium: '4-5; 4-5; 2-3',
            free: '5-6, -; -',
            very_free: '7-9; -; -'
          },
          {
            product_type: 'jacket',
            very_tight: '3-4, 1.5-2; 0.5-1.5',
            tight: '4-5; 3-4; 1.5-3',
            medium: '6-7; 5-7; 3-5',
            free: '7-9; -; -',
            very_free: '9-11; -; -'
          },
          {
            product_type: 'coat (demi-season)',
            very_tight: '4-5; 2-3; 2.5-3',
            tight: '5-6; 4-5; 3-4',
            medium: '6-8; 6-8; 5-7',
            free: '9-11; -; -',
            very_free: '11-14; -; -'
          }
        ],
        table_items_2:[
          {
            product_type: 'dress',
            very_tight: '0.3-0.4; 0.1-0.2',
            tight: '0.5-0.8; 0.1-0.4',
            medium: '0.7-1; 0.2-0.5',
            free: '0.9-1.1; 0.3-0.6',
            very_free: '1.2-1.6; 0.4-0.8'
          },
          {
            product_type: 'jacket',
            very_tight: '0.6-0.8; 0.2-0.4',
            tight: '0.7-0.9; 0.2-0.5',
            medium: '0.9-1.2; 0.3-0.6',
            free: '1.2-1.6; 0.4-0.8',
            very_free: '1.5-2; 0.5-1'
          },
          {
            product_type: 'coat (demi-season)',
            very_tight: '0.7-0.9; 0.2-0.5',
            tight: '0.8-1.1; 0.2-0.6',
            medium: '1-1.4; 0.3-0.7',
            free: '1.4-2; 0.5-1',
            very_free: '1.6-2.2; 0.5-11'
          }
        ],
        table_items_3:[
          {
            gasket_name: 'batting (synthetic winterizer) in one layer',
            insulation_thickness_cm: '0.4',
            allowance_1: '1.5',
            back_distribution: '0.25',
            armhole_distribution: '1',
            distribution_on_a_shelf: '0.25',
            allowance_2: '2'
          },
          {
            gasket_name: 'batting (synthetic winterizer) in two layers',
            insulation_thickness_cm: '0.8',
            allowance_1: '2.5',
            back_distribution: '0.5',
            armhole_distribution: '1.5',
            distribution_on_a_shelf: '0.5',
            allowance_2: '3'
          }
        ],
        table_items_4:[
          {
            sleeve_characteristic: 'tight-fitting',
            dress: '2-2.5',
            jacket: '3.5-5.5',
            coat_demi_season: '5-6',
          },
          {
            sleeve_characteristic: 'narrow',
            dress: '3-4',
            jacket: '6-7.5',
            coat_demi_season: '6-8',
          },
          {
            sleeve_characteristic: 'medium',
            dress: '5-6',
            jacket: '7.5-9.5',
            coat_demi_season: '8-11',
          },
          {
            sleeve_characteristic: 'extended',
            dress: '8-11',
            jacket: '9.5-11.5',
            coat_demi_season: '11-13',
          },
          {
            sleeve_characteristic: 'wide',
            dress: '10-12',
            jacket: '11.5-13.5',
            coat_demi_season: '13-17',
          },
          {
            sleeve_characteristic: 'full',
            dress: '>12',
            jacket: '>13.5',
            coat_demi_season: '>17',
          }
        ]
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>