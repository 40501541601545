import axios from "axios";
// const headers = {
//   'Content-Type': 'application/json'
// }
const sendContactMail = text => {
  return axios.post("/api/v1/costumes/send_mail", {'text': text},
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};

const sendCanvas = (img, imgname, email) => {
  return axios.post("/api/v1/costumes/send_canvas", { 'img': img, 'imgname': imgname, 'email': email },
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};

export default {
  sendContactMail,
  sendCanvas
};