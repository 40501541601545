<template lang="pug">
  div(class="collections")
    h2.d-flex.justify-content-center
      | How to create your own collection with a clothing design app
    hr
    h4.d-flex.justify-content-center
      | Skirt
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select a skirt from the top placket
      .collections__img
        img.how-to-use__img(src='/collections/skirt1.png' alt='skirt')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select front pockets. Drag to suitable locations. Pressing the buttons + - adjust the size of the pockets.
      .collections__img
        img.how-to-use__img(src='/collections/skirt2.png' alt='skirt')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select back pockets. Drag to suitable locations. Press the + - buttons to adjust the size of the pockets. Adjust the angle of the pockets by pressing the buttons R+ R-
      .collections__img
        img.how-to-use__img(src='/collections/skirt3.png' alt='skirt')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Add buttons from the furniture strip
      .collections__img
        img.how-to-use__img(src='/collections/skirt4.png' alt='skirt')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select fabric or load your own
      .collections__img
        img.how-to-use__img(src='/collections/skirt5.png' alt='skirt')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    h4.d-flex.justify-content-center
      | Dress
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select a dress from the top placket
      .collections__img
        img.how-to-use__img(src='/collections/dress1.png' alt='dress')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select logotypes. Drag to suitable locations. Pressing the buttons + - adjust the size of the logos.
      .collections__img
        img.how-to-use__img(src='/collections/dress2.png' alt='dress')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select front pockets. Drag to suitable locations. Press the + - buttons to adjust the size of the pockets.
      .collections__img
        img.how-to-use__img(src='/collections/dress3.png' alt='dress')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Add buttons from the furniture strip
      .collections__img
        img.how-to-use__img(src='/collections/dress4.png' alt='dress')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Use lines and buttons to form the back belt
      .collections__img
        img.how-to-use__img(src='/collections/dress5.png' alt='dress')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select fabric or load your own
      .collections__img
        img.how-to-use__img(src='/collections/dress6.png' alt='dress')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    h4.d-flex.justify-content-center
      | Jacket
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select a jacket from the top placket
      .collections__img
        img.how-to-use__img(src='/collections/jacket1.png' alt='jacket')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select flap valve. Drag to suitable locations. Pressing the button F flip the pocket.
      .collections__img
        img.how-to-use__img(src='/collections/jacket2.png' alt='jacket')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Add buttons from the furniture strip
      .collections__img
        img.how-to-use__img(src='/collections/jacket3.png' alt='jacket')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select breast-pocket. Drag to suitable locations
      .collections__img
        img.how-to-use__img(src='/collections/jacket4.png' alt='jacket')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select fabric or load your own
      .collections__img
        img.how-to-use__img(src='/collections/jacket5.png' alt='jacket')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    h4.d-flex.justify-content-center
      | Tunic
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select a tunic from the top placket
      .collections__img
        img.how-to-use__img(src='/collections/tunic1.png' alt='tunic')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select breast-pocket and side pockets. Drag to suitable locations
      .collections__img
        img.how-to-use__img(src='/collections/tunic2.png' alt='tunic')
    .collections__row.d-flex.justify-content-center
      .collections__description
        p
          | Select fabric or load your own
      .collections__img
        img.how-to-use__img(src='/collections/tunic3.png' alt='tunic')
    hr
    .mb-3
      h3
        | Interesting from our 
        a(href="/blog")
          | blog
        | :
    .mb-3
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Sewing tips for cotton fabrics"
        text-variant="white"
        title="Cotton"
        sub-title="Sewing tips for cotton fabrics"
      )
        b-card-text
          | Natural material that is made from dense cotton.
        b-button(href="/cotton_fabrics" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  export default {
    components: {
      GoogleAdSense
    },
    metaInfo: {
      title: "How to create your own collection",
      meta: [
        {
          name: 'keywords',
          content: 'custom design, dress design, skirt design, novice designer, fashion blog, fashion design blog, tailor blog, sewing blog, how to create collection, clothing design app, fashion collection, clothes collection'
        },
        {
          name: 'description',
          content: "Blog about designing clothes: How to create your own collection with a free online clothing design app."
        }
      ]
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
  .collections {
    padding: 15px;
  }
  .collections__row {
  }
  .collections__img {
    padding: 15px;
    width: 430px;
  }
  .collections__description {
    padding: 15px;
    width: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how-to-use__img {
    margin: auto;
  }
</style>