<template lang="pug">
  div(class="app-toolbar")
    div
      | {{$ml.get('selectMaterial')}}
    .background-input
      input(name='files' type='file' data-direct-upload-url='/rails/active_storage/direct_uploads' direct_upload='true' id='background-input__file-input')
    carousel(:perPage="fabric_count_per_page" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="0")
      slide(v-for="fabric in fabrics" :key="fabric.id")
        button(class='background-item' v-on:click='setBackground(fabric.img.url)' v-bind:style="{ backgroundImage: 'url(' + fabric.img.url + ') !important' }") 
            | {{ fabric.name}}
</template>
<script>
  import toolsApi  from '../api/tools_api'
  export default {
    name: 'toolbar',
    data() {
      return {
        fabrics: [],
        fabric_count_per_page: 3,
      }
    },
    mounted() {
      this.fetchActiveFabrics();
      this.inputBackground();
      this.count_per_page_width();
      window.addEventListener('resize', () => {
        this.count_per_page_width();
      })
    },
    methods: {
      addMassMaterial(fileNameArr, file) {
        let ref = this;
        file.async("blob").then(function (blob) {
          let file_url = URL.createObjectURL(blob);
          let newMaterial = {
            'description': null,
            'name': fileNameArr[2].split('.')[0],
            'img' : {
              'preview': {
                'url': file_url
              },
              'url': file_url
            }
          }
          ref.fabrics.unshift(newMaterial);
        });
      },
      count_per_page_width() {
        this.fabric_count_per_page = $(document).width() >= 1600 ? 5 : 3;
      },
      fetchActiveFabrics() {
        toolsApi
          .fetchActiveFabrics()
          .then(response => {
            this.fabrics = response.data.fabrics
            document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${this.fabrics[0].img.url})`;
            let conter_operation_v = document.getElementById('app-current-preview-canvas').style["background-image"].slice(5, -2)
            let operation_v = this.fabrics[0].img.url
            this.$emit('save-to-history', 'setBackground', 'setBackground', operation_v, conter_operation_v);
          })
      },
      inputBackground() {
        var fileInput = document.getElementById('background-input__file-input')
        var ref = this
        fileInput.addEventListener('change', function(event) {
          var file = fileInput.files[0];
          var fr = new FileReader();
          fr.readAsDataURL(file);
          setTimeout(()=>{ ref.setBackground(fr.result) }, 2000);
        });
      },
      setBackground(fabric_url = null) {
        let conter_operation_v = document.getElementById('app-current-preview-canvas').style["background-image"].slice(5, -2)
        if (fabric_url) {
          document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${fabric_url})`;
        } else {
          $('.app-current-preview-canvas').style["background-image"] = '';
        }
        let operation_v = fabric_url
        var ref = this
        ref.$emit('save-to-history', 'setBackground', 'setBackground', operation_v, conter_operation_v);
        this.defineBackroundImageSize(fabric_url);
      },
      defineBackroundImageSize(fabric_url) {
        var image = new Image();
        image.src = fabric_url.replace(/\"/gi, "");

        var ref = this
        image.onload = function () {
          var width = image.width,
            height = image.height;
          ref.$emit('set-backround-size', [width, height]);
        };
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .app-toolbar {
    display: block;
    height: 115px;
    margin-top: 0px;
    background-color: indianred;
    padding: 15px;
    width: 830px;
    float: left;
  }
  .background-input {
    float: left;
  }
  .background-item {
    border: none;
    float: left;
    margin-left: 4px;
    width: 120px;
    height: 60px;
  }
  .application {
    .app-toolbar {
      background-color: $application-main-color;
      width: 760px;
    }
    .background-item {
      box-shadow: 3px 3px 3px $application-border-color;
      border-radius: 3px;
    }
    .background-item:hover {
      box-shadow: 6px 6px 3px $application-border-color;
    }
    .background-item:active {
      box-shadow: 0px 0px 0px $application-border-color;
    }
  }
@media screen and (min-width: 1600px) {
  .application {
    .app-toolbar {
      width: 945px;
    }
    .background-input {
      width: 292px;
    }
  }
}
</style>