<template lang="pug">
div
  router-view
</template>

<script>
export default {
  name: "AppLayoutApplication"
};
</script>
<style scoped>
</style>
