<template lang="pug">
  .slot-menu
    .slot-menu__list
      .slot-menu__title
        | {{$ml.get('memorySlots')}}
      .slot-menu__item(v-for="index in 10" :key="'slot' + index")
        .slot-menu__info-item
          .slot-menu__item-number
            | {{$ml.get('slot')}} {{index}}
          .slot-menu__item-name(v-if="slots[index]")
            | {{ slots[index].name }}
        .slot-menu__save-item(v-on:click="saveSlot(index)")
          | {{$ml.get('save')}}
        .slot-menu__load-item(v-on:click="loadSlot(index)" v-if="slots[index]")
          | {{$ml.get('load')}}
        .slot-menu__delete-item(v-on:click="deleteSlot(index)" v-if="slots[index]")
          | {{$ml.get('delete')}}
</template>
<script>
  import SlotApi  from "@/api/slot_api";
  export default {
    name: 'MemorySlotMenu',
    data() {
      return {
        text: '',
        slots: {}
      }
    },
    methods: {
      async saveSlot(val) {
        if (this.slots[val]) {
          if (window.confirm(`Do you really want to resave slot ${val}?`)) {
            await this.$emit('save-to-slot', val, this.slots[val].name);
            setTimeout(() => { this.loadSlots() }, 1500);
          }
        } else {
          let name = prompt('Enter slot name', '');
          if (name) {
            await this.$emit('save-to-slot', val, name);
            setTimeout(() => { this.loadSlots() }, 1500);
          }
        }
      },
      loadSlot(val) {
        if (window.confirm(`Do you really want to load image from slot ${val}?`)) {
          this.$emit('load-from-slot', val);
        }
      },
      async loadSlots() {
        let slots = await SlotApi.getSlots();
        this.slots = await slots.data.reduce(function(map, obj) {
            map[obj.position] = obj;
            return map;
        }, {});
      },
      async deleteSlot(val) {
        if (window.confirm(`Do you really want to clear slot ${val}?`)) {
          await this.$emit('delete-slot', { position: val });
          setTimeout(() => {  this.loadSlots() }, 1500);
        }
      }
    },
    mounted() {
      this.loadSlots();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.slot-menu {
  width: 100%;
  margin-bottom: 15px;
  margin-top:5px;
  background-color: $application-main-color;
  border-top: 2px solid $application-border-color;
  height: 109px;
  padding: 0px 15px;
}
.slot-menu__title {
  width: 90px;
  display: flex;
  align-items: center;
}
.slot-menu__list {
  display: flex;
}
.slot-menu__item {
  border: 1px solid $application-border-color;
  padding: 3px;
  margin: 6px 1px;
  width: 109px;
  height: 109px;
}
.slot-menu__save-item, .slot-menu__load-item, .slot-menu__delete-item {
  cursor:pointer;
  font-size: 14px;
}
.slot-menu__item-number, .slot-menu__item-name {
  font-size: 14px;
}
</style>