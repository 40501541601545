const isAuthenticated = state => !!state.user;
const user = state => state.user;
const language = state => state.language;
const isConfirmAgreementsRequired = state => state.user && state.user.agreement_sign_required;

export default {
  isAuthenticated,
  user,
  isConfirmAgreementsRequired,
  language
};