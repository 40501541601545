<template lang="pug">
app-layout-application(v-if="ifApplication")
  | test
.app-wrapper.page-container(v-else md-waterfall='' md-mode='fixed-last')
  app-layout(
    key="appLayout"
  )
  i.mdi.mdi-account-badge-horizontal-outline
</template>

<script>

import AppLayout from "./AppLayout";
import AppLayoutApplication from "./AppLayoutApplication";

export default {
  name: 'App',
  components: {
    AppLayout,
    AppLayoutApplication
  },
  data() {
    return {};
  },

  computed: {
    loggedIn() {
      return this.$store.getters['global/isAuthenticated'];
    },
    ifApplication() {
      return this.$route.name == 'Application' || this.$route.name == 'AI'
    }
  }
};
</script>
<style scoped>
.app-wrapper {
/*  min-width: 1260px;*/
}
</style>