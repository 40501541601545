import axios from 'axios';

const LOGIN = (state, user) => {
  state.user = user;
};

const LOGOUT = state => {
  console.log('LOGOUT')
  state.user = null;
};

const CHANGE_LANGUAGE = (state, lang) => {
  console.log('CHANGE_LANGUAGE')
  state.language = lang;
};

const UPDATE_USER = (state, data) => {
  state.user = {
    ...state.user,
    ...data
  }
};

export default {
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  CHANGE_LANGUAGE
};