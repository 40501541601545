<template lang="pug">
  .ai-wrap(:key="componentKey")
    //- .ai__adv-top
    //-   google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    //-   google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .ai__main-row
      .ai__adv-left
        google-ad-sense(slotid='3016632347' :timeout='200' classnames='page-top')
      .ai__main
        h1.ai__title
          | Nastix AI Fashion Design
        .ai__adv(v-if='showAdv')
          .ai__adv-spin-row
            span.ai__adv-spin-text
            | Advertising is the only reason why our site remains free. Don't forget to tell your friends about us on social networks.
            b-spinner(label="Spinning")
        .ai__result(v-else)
          .ai__search
            b-input-group
              b-input-group-prepend
                b-button(@click='forceAI()' :disabled="!!url")
                  | Generate
              b-form-input(type="search" v-model="search" :disabled="!!url")
          .ai__adv(v-if='showAdv')
            google-ad-sense(slotid='1212498297' :timeout='200' classnames='')
          .ai__desctiption(v-if='!url')
            | Describe in as much detail as possible what you want to capture on the image
            br
            | For example: Technical sketch on a white background of a short denim dress with a unicorn print on the back pocket
            br
            | Use English for queries to get more accurate results
            br
            | TESTING MODE RESTRICTIONS: 3 attempts per day (used {{count}}), the total daily limit for AI requests is significantly limited, so this service does not operate full-time. As testing is successful, the limits will increase.
          .ai__tags(v-if='!url')
            p.ai__add-to_request
              | Add to request:
            b-button.ai_tag-btn(size="sm" @click='addToSearch("technical sketch")' pill variant="primary")
              | technical sketch
            b-button.ai_tag-btn(size="sm" @click='addToSearch("dress design")' pill variant="primary")
              | dress design
            b-button.ai_tag-btn(size="sm" @click='addToSearch("fashion design")' pill variant="primary")
              | fashion design
            b-button.ai_tag-btn(size="sm" @click='addToSearch("skirt design")' pill variant="primary")
              | skirt design
            b-button.ai_tag-btn(size="sm" @click='addToSearch("fashion design sketches")' pill variant="primary")
              | fashion design sketches
            b-button.ai_tag-btn(size="sm" @click='addToSearch("dress drawing")' pill variant="primary")
              | dress drawing
            b-button.ai_tag-btn(size="sm" @click='addToSearch("transparent background")' pill variant="primary")
              | transparent background
            b-button.ai_tag-btn(size="sm" @click='addToSearch("pencil sketch")' pill variant="primary")
              | pencil sketch
            b-button.ai_tag-btn(size="sm" @click='addToSearch("model dress")' pill variant="primary")
              | model dress
            b-button.ai_tag-btn(size="sm" @click='addToSearch("fashion model drawing")' pill variant="primary")
              | fashion model drawing
          .ai__link-menu
            .ai__link-to
              a(href='/app' v-if='!url')
                | Go to Nastix Design 2D
            .ai__link-to
              a(href='/' v-if='!url')
                | Go to main page
          .ai_manage-result(v-if='url')
            img.ai_img(:src="url")
            .ai_img-actions(v-if='url')
              b-button.ai_img-action-btn(@click='openNewTab()')
                | Open
              //- b-button.ai_img-action-btn(@click='downloadURI()')
              //-   | Download
              b-button.ai_img-action-btn(@click='copyToClipboard()')
                | Copy link
              b-button.ai_img-action-btn(v-if="!this.$route.query.url" @click='forceAI()')
                | Try again
              b-button.ai_img-action-btn(@click='clearUrl()')
                | New request
      .ai__adv-right
        google-ad-sense(slotid='3016632347' :timeout='200' classnames='page-top')
</template>
<script>
  import AisApi from '../../api/ais_api'
  import GoogleAdSense from "../../components/GoogleAdSense"
  export default {
    name: 'quote',
    data() {
      return {
        url: null,
        search: '',
        componentKey: 0,
        showAdv: false,
        count: 0
      }
    },
    components: {
      GoogleAdSense,
    },
    metaInfo: {
      title: "Free AI online clothing design software",
      meta: [
        {
          name: 'keywords',
          content: 'design software, clothing design software, desing app, design program, fashion application, custom design, clothes designing software, dress design, skirt design, novice designer, fashion designer course, free design course, fashion designer, course of fashion design, tailor course, AI fashion design, ai design, ai fashion'
        },
        {
          name: 'description',
          content: "Free software for design clothes online and AI fashion design. Unlimited designs by clothing design app. Design custom jackets, overalls, trousers, skirts, dresses etc."
        }
      ]
    },
    methods: {
      showAdvs() {
        this.showAdv = true;
        this.componentKey += 1;
        var ref = this
        setTimeout(()=>{ ref.hideAdv() }, 10000);
      },
      hideAdv() {
        this.showAdv = false;
        this.componentKey += 1;
        let dateToday = new Date(Date.now()).toLocaleString().split(',')[0]
        // this.count = JSON.parse(localStorage.getItem('ND' + dateToday));
        this.count += 1
        localStorage.setItem(('ND' + dateToday), this.count);
      },
      addToSearch(text) {
        this.search += ' ' + text;
        this.componentKey += 1;
      },
      clearUrl() {
        if (this.$route.query.url) {
          this.$router.replace({'query': null});
        }
        this.url = null;
        this.componentKey += 1;
      },
      copyToClipboard() {
        let el = document.createElement('input');
        try{
          el.value=this.url;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
        }finally{
          el.parentElement.removeChild(el);
        }
      },
      downloadURI() {
        console.log('downloadURI')
        var link = document.createElement("a");
        link.download = 'nastix_design_img';
        link.href = this.url;
        console.log(link.href)
        document.body.appendChild(link);
        link.click();
        console.log('click')
        document.body.removeChild(link);
        link = null;
      },
      openNewTab() {
        window.open(this.url, '_blank').focus();
      },
      forceAI() {
        if (this.search.length > 4 && this.count < 3) {
          if (this.search.toLowerCase().includes('fasion') || this.search.toLowerCase().includes('design') || this.search.toLowerCase().includes('sketch') || this.search.toLowerCase().includes('dress')) {
          } else {
            this.search = 'Fashion design' + this.search
          }
          AisApi
            .generateImgUrl({ search: this.search })
            .then(response => {
              this.url = response.data.url;
              this.componentKey += 1;
              if (response.data.errors) {
                alert('Moderation error')
              } else if (this.url === null) { 
                alert('Something went wrong')
              } else {
               this.showAdvs();
              }
            })
        } else if (this.search.length > 4 && this.count > 2) {
          alert('Only 3 attempts per day')
        } else {
          alert('Too short image description')
        }
      }
    },
    mounted() {
      $( ".first-invitation" ).remove();
      let dateToday = new Date(Date.now()).toLocaleString().split(',')[0]
      this.count = Number(localStorage.getItem('ND' + dateToday));
      if (this.$route.query.url) {
        this.url = this.$route.query.url
        this.componentKey += 1;
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.ai-wrap {
  position: fixed;
  top: 0px;
  left:0px;
  right: 0px;
  bottom: 0px;
  background-color: $application-main-color;
}
.ai__main-row {
  display: flex;
  height: 100%;
}
.ai__adv-top {
 height: 25% !important;
 display: flex;
 width: 100%;
}
.ai__adv-left {
  width: 250px;
  height: 100%;
  position: relative;
}
.ai__main {
  width: 100%;
  background-color:$application-border-color;
}
.ai__adv-right {
  width: 250px;
  height: 100%;
  position: relative;
}
.ai__title {
  text-align: center;
  color: white;
  padding: 30px 0px;
  margin-bottom: 0px;
}
.ai__search {
  margin: 0px 30px;
}
.ai_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 15px;
}
.ai_manage-result {
  position: relative;
}
.ai_img-actions {
  position: absolute;
  top: 15px;
  left: 30px;
  display: inline-grid;
}
.ai_img-action-btn {
  margin-bottom: 15px;
}
.ai__desctiption {
  text-align: left;
  padding: 15px 30px;
  color: white;
}
.ai__link-menu {
  padding: 15px 30px;
  display: flex;
}
.ai__link-to { 
  text-align: left;
  padding: 0px 15px 0px 0px;
}
.ai__tags {
  padding: 0px 15px 30px 15px;
  text-align: left;
}
.ai__add-to_request {
  padding: 0px 30px;
  text-align: left;
  color: white;
}
.ai_tag-btn {
  margin: 2px 2px;
}
.ai__adv {
  padding: 0px 30px;
  text-align: center;
}
.ai__adv-spin-row {
  color: white;
}
.ai__adv-top ins {
  width: 50%;
  max-height: 250px;
}
@media only screen and (max-width: 768px) {
  .ai__main-row {
    display: block;
  }
  .ai__adv-top ins {
    width: 100%;
  }
  .ai-wrap {
    position: relative;
  }
  .ai__adv-left {
    display: none;
  }
  .ai__adv-right {
    display: flex;
  }
}

</style>