import * as Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

import GlobalModule from './modules/global';

export const store = new Vuex.Store({
  modules: {
    global: GlobalModule
  }
});

if (store.getters.isAuthenticated) {
  // const currentUser = store.getters['user'];
  // axios.defaults.headers.common['NastixDesign-User-Id'] = currentUser.email;
}

export default store;
