<template lang="pug">
  div(class="how-to-use")
    h2.d-flex.justify-content-center
      | How to use App for design clothes
    hr
    video-tutorial
    hr
    h4
      | STEP 1 of App for design clothes: Select Costume
    p
      | Select the layout of the garment you want to design.
    p
      | It can be a dress, jacket, skirt, overalls, trousers, etc.
    p
      | You can use the available filters (type, season, etc.).
    p
      | Only after this step will you be able to add features.
    p
      b
        | You can also prepare a mockup of your own garment and upload it to the App for design clothes.
    p
      | Requirements: Size 800x800px. Prefer PNG. For 'Choose material' to work, the inside of the costume must be transparent, the outside is white (
      a(href="/examples/dress_example_for_custom_layout.png")
        | example
      | ).
    img.how-to-use__img(src='/how_to_use/costume_types.png' alt='costumes')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')

    hr
    h4
      | STEP 2 of App for design clothes: Add features
    p
      | Select a feature. After it appears in the center of the work area, drag it to the desired location.
    p
      | You can change the size (larger / smaller), tilt angle, rotation of feature.
    p
      | In order not to perform these operations many times, the feature can be copied.
    p
      b
        | You can also prepare a mockup of your own feature and upload it to the App for design clothes.
    img.how-to-use__img(src='/how_to_use/features.png' alt='features')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    h4
      | STEP 3 of App for design clothes: Select a material
    p
      | Pick the fabric you need.
    p
      b
        | You can also upload an image of your own fabric.
    img.how-to-use__img(src='/how_to_use/materials.png' alt='materials')
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    hr
    h4 
      | Download fashion technical sketch, send to your email or save to use later
    hr  
    h4
      | How to create your own collection with a clothing design app 
      router-link(to="/collections")
        i.fas.fa-link
    p
      | * Attention! Here you can read more detailed instructions How to create fashion technical sketch
    router-link(to="/collections")
      .collection-example__imgs
        .collection-examplge_img
          img(src="/examples/overalls.webp" alt="overalls")
        .collection-examplge_img
          img(src="/examples/vest.webp" alt="vest")
        .collection-examplge_img
          img(src="/examples/raincoat.webp" alt="raincoat")
        .collection-examplge_img
          img(src="/examples/windbreaker.webp" alt="windbreaker")
    hr
    h4
      | How to upload all your costumes, features, materials to the app at once 
      router-link(to="/mass-upload-guide")
        i.fas.fa-link
    hr
    blog-part
    div
      b-card(
        overlay=""
        img-src="/education_imgs/design_course.jpg"
        img-alt="Online fashion designer course"
        text-variant="white"
        title="design course"
        sub-title="Free online fashion designer course"
      )
        b-card-text
          | We are creating a resource that will be useful for novice designers, and will be a free online fashion design course.
        b-button(href="/education" variant="outline-primary")
          | Read design course
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/measurements.jpg"
        img-alt="Types of pockets"
        text-variant="white"
        title="Types of pockets"
        sub-title="External and internal pockets"
      )
        b-card-text
          | Patch, welt and on-seam pockets.
        b-button(href="/pockets" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import VideoTutorial from "./VideoTutorial"
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"
export default {

  components: {
    GoogleAdSense,
    BlogPart,
    VideoTutorial
  },
  mounted() {
    $( ".first-invitation" ).remove();
  }
}
</script>

<style lang="scss" scoped>
  .how-to-use {
    padding: 15px;
  }
  .how-to-use__img {
    margin: auto;
  }
  .collection-example__imgs {
    display: flex;
  }
  .collection-examplge_img {
    width: 25%;
  }
  .collection-examplge_img img {
    width: 100%;
  }
</style>