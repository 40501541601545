<template lang="pug">
  div(class="app-item-types-list")
    .app-item-types-list__title
      | {{$ml.get('addFeatures')}}
    div(
      v-if="disable"
      class="app-item-type--disable"
    )
    div(
      class="app-item-type"
      v-for="itemType in itemTypes"
      :key="itemType.id"
    )
      items-list( 
        :key="itemType.id"
        :item-type-name="itemType.name"
        :item-type-id="itemType.id"
        :ref="'to' + itemType.name_en.charAt(0).toUpperCase() + itemType.name_en.slice(1)"
        @select-item="selectItem"
      )
    .app-item-type
      h2(class="app-items-list__type-title") 
        | {{$ml.get('custom')}}
      input(name='files' type='file' data-direct-upload-url='/rails/active_storage/direct_uploads' direct_upload='true' id='item__file-input')
    item-properties(
      :show='showProps'
      :rotate='rotate'
      :zoom='zoom'
      :flip='flip'
      :x='itemX'
      :y='itemY'
    )
</template>
<script>
  import itemTypesApi  from '../api/item_types_api'
  import itemsList from "./ItemsList.vue"
  import ItemProperties from "./ItemProperties"
  export default {
    name: 'item-types-list',
    components: {
      itemsList,
      ItemProperties
    },
    props: {
      disable: {
        type: Boolean,
        default() {
          return true
        }
      },
      showProps: {
        type: Boolean,
        default() {
          return true
        }
      },
      rotate: {
        type: Number,
        default() {
          return 0
        }
      },
      zoom: {
        type: Number,
        default() {
          return 0
        }
      },
      itemX: {
        type: Number,
        default() {
          return 0
        }
      },
      itemY: {
        type: Number,
        default() {
          return 0
        }
      },
      flip: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    data() {
      return {
        itemTypes: []
      }
    },
    methods: {
      addMassItem(fileNameArr, file) {
        let item_type = fileNameArr[2].toLowerCase()
        if (item_type == 'pockets') {
          this.$refs.toPockets[0].addMassItem(fileNameArr, file)
        } else if (item_type == 'furnitures') {
          this.$refs.toFurnitures[0].addMassItem(fileNameArr, file)
        } else if (item_type == 'boosters') {
          this.$refs.toBoosters[0].addMassItem(fileNameArr, file)
        } else if (item_type == 'others') {
          this.$refs.toOthers[0].addMassItem(fileNameArr, file)
        }
      },
      setCustomItem(item_url = null, x, y) {
        let val = {
          'default_size': 10,
          'description': '',
          'id': '',
          'customX': '',
          'customY': '',
          'img': {
            'url': item_url
          },
          'name': 'Custom',
          'customX': x,
          'customY': y
        }
        this.selectItem(val)
      },
      inputBackground() {
        var fileInput = document.getElementById('item__file-input')
        var ref = this
        var x = null
        var y = null
        fileInput.addEventListener('change', function(event) {
          var file = fileInput.files[0];
          var fr = new FileReader();
          fr.readAsDataURL(file);
          const myImage = new Image();
          myImage.src = window.URL.createObjectURL(file)
          myImage.onload = function () {
            x = myImage.width
            y = myImage.height
            ref.setCustomItem(window.URL.createObjectURL(file), x, y);
          };

          
        });
      },
      fetchItemTypes() {
        itemTypesApi
          .fetchAll({})
          .then(response => {
            this.itemTypes = response.data.item_types
          })
      },
      selectItem(val) {
        this.$emit('select-item', val);
      }
    },
    mounted() {
      this.fetchItemTypes();
      this.inputBackground();
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .app-item-types-list {
    position: relative;
    background-color: indianred;
    height: 815px;
    margin-left: 830px;
    margin-bottom: 0px;
    padding: 15px 20px;
    min-width: 400px;
    border-top: 15px solid white;
  }
  .app-item-type {
    min-height: 120px;
    margin-top: 20px;
  }
  .app-item-type:after {
    content: "";
    display: table;
    clear: both;
  }
  .app-item-types-list__title {

  }
  .app-item-type--disable {
    text-shadow: #000 0 1px 0;
    background: rgba(255,255,255,0.45) !important;
    z-index:4;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  .application {
    .app-item-types-list {
      width: 400px;
      background-color: $application-main-color;
      border-top: 15px solid $application-border-color;
      margin-left: 0px;
    }
    .app-items-list__type-title {
      color: white;
      font-size: 26px;
      font-weight: 100;
    }
  }
</style>