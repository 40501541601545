<template lang="pug">
  .video-tutorial
    h4
      b-link(href="https://www.youtube.com/embed/zjRBaj9EURw" target="blank")
        | Video tutorial: For novice designers. How design a jacket for 3 minutes by free Nastix Design App
      //- b-embed(
      //-   type="iframe"
      //-   aspect="16by9"
      //-   src="https://www.youtube.com/embed/zjRBaj9EURw"
      //-   allowfullscreen
      //- )
</template>
<script>
  export default {
    name: 'video-tutorial',
    data() {
      return {
      }
    },
    mounted() {
      // let tiktokScript = document.createElement('script')
      // tiktokScript.setAttribute('src', 'https://www.tiktok.com/embed.js')
      // document.head.appendChild(tiktokScript)
    }
  }
</script>
<style lang="scss" scoped>
.video-tutorial {
  margin: 36px auto 36px auto;
  padding: 15px 20px;
}
@media only screen and (max-width: 1024px) {
  .video-tutorial {
    width: 100%;
  }
}
</style>