import * as Vue from 'vue';
import VeeValidate from 'vee-validate';
import App from '@/components/App';
import store from '@/store';
import router from '@/router';
import { BootstrapVue } from 'bootstrap-vue';
import VueCarousel from 'vue-carousel';
import VueMaterial from 'vue-material';
import VueMeta from 'vue-meta'
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import '@/styles/main.scss';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './ml'

// import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(VueCarousel);
Vue.use(BootstrapVue)
Vue.use(VueMaterial)
Vue.use(VueMeta)
Vue.use(VeeValidate, {
  inject: false
});

const app = new Vue({
  router,
  store,
  $validates: true,
  ...App
});

export { app, router, store };
