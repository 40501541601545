<template lang="pug">
  .blog.tailor_patterns
    b-row
      b-col
        h2
          router-link(to="blog")
            | Blog
          | : 
          | Tailor's patterns and rulers
        p
          | Tailor's patterns (rulers) are special-shaped tools that are needed to build patterns.
        .mt-1
        b-img(src="/education_imgs/str5.jpg" fluid alt="Tailor's patterns and rulers")
        .mt-3
        p
          | They are used in the process of modeling clothes, marking the fabric before cutting and during tailoring.
        p
          | Most of the patterns are made of durable lightweight plastic. More often, transparent plastic is used, which allows the tailor to see all the lines of the drawing, their location and configuration during design. You can also find patterns made of wood or metal.
        .mt-1
        google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
        .mt-1
        b-card-group(deck='')
          b-card(title="Pattern 'Saber' (cutting ruler)" img-src="/education_imgs/str1.jpg" img-alt="Patterns 'Saber" img-right='')
            b-card-text
              p
                | One of the most popular patterns among tailors.
              p
                | Allows you to measure the rounded areas of the pattern.
              p
                | Using this pattern, you can draw lines of the side, darts, bottom of the product and sleeves, relief lines, cutout lines, etc.
              p
                | With the help of an enlarged rounded edge, in some cases it is also possible to draw an armhole line.
        .mt-1
        google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
        .mt-1
        b-card-group(deck='')
          b-card(title="Tailor's meter" img-src="/education_imgs/str2.jpg" img-alt="Tailor's meter" img-right='')
            b-card-text
              | Ruler 1m
        .mt-1
        google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
        .mt-1
        b-card-group(deck='')
          b-card(title="Corner pattern" img-src="/education_imgs/str3.jpg" img-alt="Corner pattern" img-right='')
            b-card-text
              p
                | With it, it is very easy to draw parallel lines, build angles.
              p
                | The third side of the pattern allows you to draw curved lines of the barrel and bottom of the product.
        .mt-1
        google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
        .mt-1
        b-card-group(deck='')
          b-card(title="Patterns for the armhole ('French pattern')" img-src="/education_imgs/str4.jpg" img-alt="Patterns for the armhole" img-right='')
            b-card-text
              p
                |  With the help of such a pattern, you can draw not only the armhole line, but also the lines of the side, bottom of the product and sleeves, model cutouts of various depths, mark corners and allowances, as well as the location of loops and buttons in the product. 
              p
                | The pattern allows you to easily adjust and fit patterns - reduce or increase the depth of the armhole, adjust the waistline, make sleeves, etc.
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Seam allowances"
        text-variant="white"
        title="Seam allowances"
        sub-title="VS Ease allowance"
      )
        b-card-text
          | added to the details of the pattern along all contours for assembling and processing the seams of the product
        b-button(href="/seam_allowances" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Ease allowance"
        text-variant="white"
        title="Ease allowance classification"
        sub-title="Allowances for the freedom of fitting"
      )
        b-card-text
          | Ease allowance (allowances for the freedom of fitting) classification.
        b-button(href="/ease_allowance" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "Tailor's patterns and rulers",
      meta: [
        {
          name: 'keywords',
          content: 'rulers, pattern, tailor pattern, tailor ruler, custom design, clothes designing software, dress design, skirt design, novice designer, french pattern, cutting ruler'
        },
        {
          name: 'description',
          content: 'Special-shaped tools that are needed to build patterns. Most popular patterns among tailors: cutting ruler, french pattern etc.'
        }
      ]
    },
    data() {
      return {
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
.blog .card-subtitle {
  color: #28231d !important;
}
@media screen and (max-width: 600px) {
  .blog.tailor_patterns .flex-row-reverse {
    flex-direction: column !important;
  }
}
</style>