<template lang="pug">
  .quote
    .quote__text.slogan
      | {{text}}
    .quote__author
      | {{author}}
    .quote__position
      | {{position}}
</template>
<script>
  import quotesApi from '../api/quotes_api'
  export default {
    name: 'quote',
    data() {
      return {
        text: '',
        author: '',
        position: ''
      }
    },
    methods: {
      fetchQuote(params = {}) {
        quotesApi
          .fetchRandomQuote(params)
          .then(response => {
            console.log(response.data.quote)
            this.text     = response.data.quote.text
            this.author   = response.data.quote.author
            this.position = response.data.quote.position
          })
      }
    },
    mounted() {
      this.fetchQuote();
    }
  }
</script>

<style lang="scss" scoped>
.quote {
  padding: 60px 15px 45px 15px;
  width: 60%;
  margin: auto;
}
.quote__text {
  color: white;
  text-align: center;
  font-style: italic;
  background-color: indigo;
  border-radius: 15px;
  padding: 15px !important;
  width: 100%;
  margin-bottom: 15px;
}
.quote__author {
  padding-right: 15px;
  font-weight: bold;
  text-align: right;
}
.quote__position {
  padding-right: 15px;
  text-align: right;
}

</style>