<template lang="pug">
  div(class="app-items-list")
    h2(class="app-items-list__type-title") {{ this.itemTypeName }}
    carousel(:perPage="4" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="0")
      slide(
        v-for="item in items"
        :key="item.id"
      )
        item( 
          :key="item.id"
          :item="item"
          @select-item="selectItem"
        )
</template>
<script>
  import itemTypesApi from '../api/item_types_api'
  import Item from "./Item.vue"
  export default {
    name: 'items-list',
    components: {
      Item
    },
    data() {
      return {
        items: []
      }
    },
    props: {
      itemTypeName: {
        type: String,
        default() {
          return ''
        }
      },
      itemTypeId: {
        type: Number,
        default() {
          return null
        }
      }
    },
    methods: {
      addMassItem(fileNameArr, file) {
        let ref = this;
        file.async("blob").then(function (blob) {
          let file_url = URL.createObjectURL(blob);
          let newCostume = {
            'default_size': 10,
            'description': null,
            'name': fileNameArr[2].split('.')[0],
            'img' : {
              'preview': {
                'url': file_url
              },
              'url': file_url
            }
          }
          ref.items.unshift(newCostume);
        });
      },
      // addMassItemToFurnitures(fileNameArr, file) {

      // },
      // addMassItemToBoosters(fileNameArr, file) {

      // },
      // addMassItemToOthers(fileNameArr, file) {

      // },
      fetchItems() {
        itemTypesApi
          .fetchItemsById(this.itemTypeId)
          .then(response => {
            this.items = response.data.items
          })
      },
      selectItem(val) {
        this.$emit('select-item', val);
      }
    },
    mounted() {
      this.fetchItems();
    }
  }
</script>

<style lang="scss" scoped>
  .app-items-list {
    height: 50px;
  }
  .app-items-list:after {
    content: "";
    display: table;
    clear: both;
  }
  .app-items-list__type-title {
    color: white;
    font-size: 26px;
    font-weight: 100;
  }
</style>