<template lang="pug">
div.application(:key="'app' + componentKey")
  .application-main
    top-menu(
      @update-language="updateLanguage"
    )
    costume-list(
      :nonselected="Object.keys(selectedCostume).length === 0"
      @select-costume="selectCostume"
      ref="childCostumeList"
      :key="'costumeList' + costumeListComponentKey"
    )
    memory-slot-menu(
      v-if='showMemorySlotMenu'
      @save-to-slot="saveToSlot"
      @load-from-slot="loadFromSlot"
      @delete-slot="deleteSlot"
    )
    .canvas-row
      .app-middle-menu
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="clearCanvas" )
          i.fas.fa-window-close.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('clearCanvas')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="gotoHowToUse" )
          i.fas.fa-info-circle.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('howToUse')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="historyBack" v-bind:class="{ 'app-middle-menu__btn--disabled': blockHistoryBack }")
          i.fas.fa-undo.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('historyBack')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="historyForward" v-bind:class="{ 'app-middle-menu__btn--disabled': blockHistoryForward }")
          i.fas.fa-redo.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('historyForward')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="openMassUpload")
          i.fas.fa-upload.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('uploadItems')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-if="isAuthenticated" v-on:click="showMemorySlotMenu = !showMemorySlotMenu" v-bind:class="{ 'app-middle-menu__btn--active': showMemorySlotMenu }")
          i.fas.fa-database.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('openSlotMenu')}}
        hr
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="showWeb = !showWeb" v-bind:class="{ 'app-middle-menu__btn--active': showWeb }")
          i.fas.fa-border-all.app-middle-menu__btn--img
          span.tooltiptext
            | {{showWeb ? $ml.get('remove') : $ml.get('add')}} {{$ml.get('markup')}}
        .show-web__additional(v-if="showWeb")
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="showBoldWeb = !showBoldWeb" v-bind:class="{ 'app-middle-menu__btn--active': showBoldWeb }")
            i.fas.fa-bold.app-middle-menu__btn--img
            span.tooltiptext
              | {{showBoldWeb ? $ml.get('thin') : $ml.get('bold')}}
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="webToTop")
            i.fas.fa-arrow-up.app-middle-menu__btn--img
            span.tooltiptext
              | {{$ml.get('up')}}
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="webToDown")
            i.fas.fa-arrow-down.app-middle-menu__btn--img
            span.tooltiptext
              | {{$ml.get('down')}}
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="webToLeft")
            i.fas.fa-arrow-left.app-middle-menu__btn--img
            span.tooltiptext
              | {{$ml.get('left')}}
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="webToRight")
            i.fas.fa-arrow-right.app-middle-menu__btn--img
            span.tooltiptext
              | {{$ml.get('right')}}
          hr
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="toggleFullScreen")
          i.fas.fa-expand.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('fullScreen')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="zoomPlus" v-bind:class="{ 'app-middle-menu__btn--disabled': zoom >= 1.7 }")
          i.fas.fa-search-plus.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('zoom')}} + ({{Math.round(zoom * 100)}}%)
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="zoomMinus" v-bind:class="{ 'app-middle-menu__btn--disabled': zoom <= 0.8 }")
          i.fas.fa-search-minus.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('zoom')}} - ({{Math.round(zoom * 100)}}%)
        hr
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="handleSecondCanvas" v-bind:class="{ 'app-middle-menu__btn--active': showSecondCanvas }")
          i.fas.fa-plus-square.app-middle-menu__btn--img
          span.tooltiptext
            | {{showSecondCanvas ? $ml.get('remove') : $ml.get('add')}} {{$ml.get('secondCanvas')}}
        .show-web__additional(v-if="showSecondCanvas")
          .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="swapCanvases")
            i.fas.fa-sync.app-middle-menu__btn--img
            span.tooltiptext
              | {{$ml.get('swapCanvases')}}
          hr
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="backgroungSwitchCover" v-bind:class="{ 'app-middle-menu__btn--disabled': backgroundSizeX <= 800 && backgroundSizeY <= 800 }")
          i.fas.fa-arrows-alt.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('backgroundCoverContain')}}
        hr
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right(v-on:click="openAI")
          .app-middle-menu__text-btn.app-middle-menu__text-btn--red
            | AI
          span.tooltiptext
            | {{$ml.get('useAI')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right.btn_to_figures(v-on:click="changeToFigures")
          i.fas.fa-pen.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('drawFigures')}}
        .app-middle-menu__btn.custom-tooltip.custom-tooltip--right.app-middle-menu__btn--active.btn_to_consturct(v-on:click="changeToConstruct")
          i.fas.fa-wrench.app-middle-menu__btn--img
          span.tooltiptext
            | {{$ml.get('returnConstruction')}}
        .draw-menu
          .button-container
            button.app-middle-menu__info(type='button' @click.prevent='$refs.VueCanvasDrawing.undo()')
              svg.bi.bi-arrow-counterclockwise(xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewbox='0 0 16 16')
                path(fill-rule='evenodd' d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z')
                path(d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z')
              |             {{$ml.get('undo')}}
            button.app-middle-menu__info(type='button' @click.prevent='$refs.VueCanvasDrawing.redo()')
              svg.bi.bi-arrow-clockwise(xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewbox='0 0 16 16')
                path(fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z')
                path(d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z')
              |             {{$ml.get('redo')}}
            button.app-middle-menu__info(type='button' @click.prevent='$refs.VueCanvasDrawing.reset()')
              svg.bi.bi-file-earmark(xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewbox='0 0 16 16')
                path(d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z')
              |             {{$ml.get('reset')}}
          .button-container
            select.draw-font(v-model='line')
              option(v-for='n in 25' :key="'option-' + n" :value='n')
                | {{ n }}
            input(type='color' v-model='color')
            select(v-model='strokeType')
              option(value='dash') {{$ml.get('dash')}}
              option(value='line') {{$ml.get('straightLine')}}
              option(value='circle') {{$ml.get('circle')}}
              option(value='square') {{$ml.get('square')}}
              option(value='triangle') {{$ml.get('triangle')}}
              option(value='half_triangle') {{$ml.get('halfTriangle')}}
            select(v-model='lineCap')
              option(value='round') {{$ml.get('lineCapRound')}}
              option(value='square') {{$ml.get('lineCapSquare')}}
              option(value='butt') {{$ml.get('lineCapbutt')}}
            select(v-model='lineJoin')
              option(value='round') {{$ml.get('lineJoinRound')}}
              option(value='miter') {{$ml.get('lineJoinmiter')}}
              option(value='bevel') {{$ml.get('lineJoinbevel')}}
            button.app-middle-menu__info(type='button' @click.prevent='fillShape = !fillShape')
              span(v-if='fillShape')
                svg.bi.bi-square-fill(xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewbox='0 0 16 16')
                  path(d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z')
                |               {{$ml.get('fill')}}
              span(v-else='')
                svg.bi.bi-square(xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewbox='0 0 16 16')
                  path(d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z')
                |               {{$ml.get('stroke')}}
      img(id="img-for-canvas")
      canvas(
        id="current-canvas" 
        :height="sizeStandartPx"
        :width="sizeStandartPx"
      )
      .canvas-wrapper
        vue-drawing-canvas(
          ref="VueCanvasDrawing"
          :image.sync="image"
          :width="800"
          :height="800"
          :stroke-type="strokeType"
          :line-cap="lineCap"
          :line-join="lineJoin"
          :fill-shape="fillShape"
          :eraser="eraser"
          :lineWidth="line"
          :color="color"
          :background-color="backgroundColor"
          :background-image="backgroundImage"
          :watermark="watermark"
          :initial-image="initialImage"
          saveAs="png"
          :styles="{}"
          :lock="disabled"
          :key="'VDC' + componentKey"
        )
        preview-canvas(
          :selected-costume="selectedCostume"
          :sizeStandart="sizeStandart"
          :inputComment.sync="comment"
          :showWeb="showWeb"
          :showBoldWeb="showBoldWeb"
          :vcorection="vcorection"
          :hcorection="hcorection"
        )
        .output
          img(:src='image')
      item-types-list(
        :disable="Object.keys(selectedCostume).length === 0 || enableDrawFigures"
        @select-item="selectItem"
        ref="childItemList"
        :showProps='itemPropsShow'
        :rotate='itemPropsRotate'
        :zoom='itemPropsZoom'
        :flip='itemPropsFlip'
        :itemX='itemPropsX'
        :itemY='itemPropsY'
        :key="'itemTypesList' + itemTypesListComponentKey"
      )
      selected-items-list(
        :key="itemIndex"
        :selected-items="selectedItems"
      )
    second-preview-canvas(
      v-if="showSecondCanvas"
      :background-url="backgroundUrlSecond"
      :selected-costume="selectedCostumeSecond"
      :selected-items="selectedItemsSecond"
      :size-standart="sizeStandart"
      :initial-image="initialImageSecond"
      :image="imageSecond"
      :inputComment="commentSecond"
      :backgroundSize = "backgroundSizeSecond"
      :backgroundSizeX = "backgroundSizeXSecond"
      :backgroundSizeY = "backgroundSizeYSecond"
      :backgroundSizeReplaceX = "backgroundSizeReplaceXSecond"
      :backgroundSizeReplaceY = "backgroundSizeReplaceYSecond"
    )
    .toolbar-row
      .toolbar-row-left
        .app-low-menu
        toolbar(
        @set-backround-size="setBackroundSize"
        @save-to-history="saveToHistory"
        ref="childMaterialList"
        :key="'toolbar' + toolbarComponentKey"
      )
      .download-canvas(v-bind:class="{ 'download-canvas--thin': isAuthenticated }")
        md-button(v-on:click='generateCanvas' class='download-canvas__button') {{$ml.get('pressDownload')}}
        .format-line
          .format-line__title
            | {{$ml.get('format')}}:
          .format-line__item(@click="changeFormat('image/png')" v-bind:class="{ 'format-line__item--active': imgFormat=='image/png' }")
            | PNG
          .format-line__item(@click="changeFormat('image/jpeg')" v-bind:class="{ 'format-line__item--active': imgFormat=='image/jpeg' }")
            | JPEG
          .format-line__item(@click="changeFormat('image/webp')" v-bind:class="{ 'format-line__item--active': imgFormat=='image/webp' }")
            | WEBP
        .quality-line
          .quality-line__title
            | {{$ml.get('quality')}}:
          .quality-line__item(@click="changeQuality(1.0)" v-bind:class="{ 'quality-line__item--active': imgQuality==1.0 }")
            | {{$ml.get('normal')}}
          .quality-line__item(@click="changeQuality(0.5)" v-bind:class="{ 'quality-line__item--active': imgQuality==0.5 }")
            | {{$ml.get('medium')}}
          .quality-line__item(@click="changeQuality(0.1)" v-bind:class="{ 'quality-line__item--active': imgQuality==0.1 }")
            | {{$ml.get('low')}}
        md-button(v-on:click='sendToUser' class='send-to__button' v-if="isAuthenticated")
          | {{$ml.get('sendTo')}} {{ user ? user.email : '' }}
    application-footer
  .application-adv
    google-ad-sense(slotid='3016632347' :timeout='200' classnames='page-top')
    google-ad-sense(slotid='3016632347' :timeout='200' classnames='page-top')
  md-dialog(:md-active.sync="showMassUploadDialog")
    md-dialog-title
      | {{$ml.get('uploadCostumes')}}
    md-content
      //- md-field
      //-   label
      //-     | Zip-file to upload
      //-   md-file(v-model="uploadedItemsFile")
      div
        input(type="file" @change="uploadItemsFile")
      p
        | {{$ml.get('modal1text1')}}.
      p
        | {{$ml.get('modal1text2')}} 
        router-link(to="/mass-upload-guide")
          | {{$ml.get('link')}}
        | .
      p
        | {{$ml.get('modal1text3')}}  
        a(href="/examples/upload_items_template.zip")
          | {{$ml.get('link')}}
        | .
      //- img(:src="preview")
    md-dialog-actions
      md-button(class="md-primary" @click="massUpload" :disabled="false")
        | {{$ml.get('upload')}}
      md-button(class="md-primary" @click="showMassUploadDialog = false")
        | {{$ml.get('close')}}
  md-dialog(:md-active.sync="showAIDialog")
    md-dialog-title
      | Free AI Tool for Fashion
      //- | {{$ml.get('uploadCostumes')}}
    md-content
      md-field
        b-input-group
          b-input-group-prepend
            b-button(@click='forceAI()' :disabled="generateAI")
              | Generate
          b-form-input(type="search" v-model="searchAI")
      p(v-if="generateAI")
        b-spinner(label="Spinning")
        |  While we are generating the picture, you can read the advertisement
      p
        | First of all, select a costume type
      p 
        | Describe in as much detail as possible what you want to capture on the image
      p
        | Free AI Tool for Fashion can try to set the background and details to the current sketch
      p 
        | For example: Front and back sides technical sketch of a red dress with a unicorn print on the back pocket
      p
        | Use English for queries to get more accurate results
      p
        | 3 attempts per day (used {{countAI}}), the total daily limit for AI requests is significantly limited, so this service does not operate full-time.
    md-dialog-actions
      md-button(class="md-primary" @click="showAIDialog = false" :disabled="generateAI")
        | {{$ml.get('close')}}
  md-dialog(:md-active.sync="showSocialDialog")
    md-dialog-title
      | {{$ml.get('modal2text1')}}
    md-content
      p
        | {{$ml.get('modal2text2')}}:
      ul
        li
          | {{$ml.get('modal2text3')}};
        li
          | {{$ml.get('modal2text4')}};
        li
          | {{$ml.get('modal2text5')}}.
      p
        | {{$ml.get('modal2text6')}}.
    md-dialog-actions
      md-button(class="md-primary" @click="showSocialDialog = false")
        | {{$ml.get('ok')}}
  md-dialog(:md-active.sync="showSocialDialog2")
    md-dialog-title
      | {{$ml.get('modal3text1')}}
    md-content
      ul
        li
          a(href='https://www.facebook.com/NastixDesign')
            | Nastix Design
        li
          a(href='https://www.facebook.com/groups/fashion2022')
            | Fashion News
      p
        | {{$ml.get('modal3text2')}}.
      p
        | {{$ml.get('modal3text3')}}.
    md-dialog-actions
      md-button(class="md-primary" @click="showSocialDialog2 = false")
        | {{$ml.get('ok')}}
  //- cookie-law
</template>

<script>
  import JSZip from 'jszip'
  import AisApi from '../api/ais_api'
  import CostumeList       from "../components/CostumeList"
  import PreviewCanvas     from "../components/PreviewCanvas"
  import SecondPreviewCanvas     from "../components/SecondPreviewCanvas"
  import ItemTypesList     from "../components/ItemTypesList"
  import SelectedItemsList from "../components/SelectedItemsList"
  import Toolbar           from "../components/Toolbar"
  import GoogleAdSense     from "../components/GoogleAdSense"
  import ApplicationFooter from "../components/ApplicationFooter"
  import MemorySlotMenu from "../components/MemorySlotMenu"
  import VueDrawingCanvas  from "vue-drawing-canvas";
  import { mapGetters } from 'vuex';
  import ContactsApi from "@/api/contacts_api";
  import SlotApi  from "@/api/slot_api";
  // import CookieLaw from 'vue-cookie-law'
  import TopMenu     from "../components/Header/TopMenu"
  // import { useRouter } from 'vue-router'

export default {
  components: {
    CostumeList,
    PreviewCanvas,
    ItemTypesList,
    SelectedItemsList,
    Toolbar,
    GoogleAdSense,
    ApplicationFooter,
    VueDrawingCanvas,
    MemorySlotMenu,
    SecondPreviewCanvas,
    // CookieLaw,
    TopMenu
  },
  metaInfo: {
    title: "Free online clothing design software",
    meta: [
      {
        name: 'keywords',
        content: 'design software, clothing design software, desing app, design program, fashion application, custom design, jacket, overalls, pocket, clothes, semi-overalls, trousers, windbreaker, fabric, material, clothes designing software, dress design, skirt design, novice designer, fashion designer course, free design course, fashion designer, course of fashion design, tailor course'
      },
      {
        name: 'description',
        content: "Free software for design clothes online and Online fashion designer course. Unlimited designs by clothing design app. Design custom jackets, overalls, trousers, skirts, dresses etc."
      }
    ]
  },
  data() {
    return {
      showSecondCanvas: false,
      componentKey: 0,
      componentKeySecond: 1000,
      costumeListComponentKey: 0,
      itemTypesListComponentKey: 0,
      toolbarComponentKey: 0,
      showSocialDialog: false,
      showSocialDialog2: false,
      itemPropsShow: false,
      itemPropsRotate: 0,
      itemPropsZoom: 0,
      itemPropsX: 0,
      itemPropsY: 0,
      itemPropsFlip: false,
      selectedItems: {},
      selectedItemsSecond: {},
      selectedCostume: {},
      selectedCostumeSecond: {},
      sizeStandart: 800,
      itemIndex: 0,
      canvas: null,
      changeIndex: 0,
      buttonSize: 24,
      comment: '',
      commentSecond: '',
      showWeb: false,
      showBoldWeb: false,
      vcorection: 0,
      hcorection: 0,
      zoom: 1,
      backgroundUrl: null,
      backgroundUrlSecond: "/uploads/fabric/img/1/White-square.jpg",
      backgroundSize: 'cover',
      backgroundSizeX: null,
      backgroundSizeY: null,
      backgroundSizeReplaceX: null,
      backgroundSizeReplaceY: null,
      backgroundSizeSecond: 'cover',
      backgroundSizeXSecond: null,
      backgroundSizeYSecond: null,
      backgroundSizeReplaceXSecond: null,
      backgroundSizeReplaceYSecond: null,
      imgFormat: 'image/png',
      imgQuality: 1.0,
      historyIndex: 0,
      showMassUploadDialog: false,
      showAIDialog: false,
      uploadedItemsFile: null,
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "transparent",
          width: 5,
          fill: false,
        },
      ],
      initialImageSecond: [],
      x: 0,
      y: 0,
      image: "",
      imageSecond: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 2,
      color: "#000000",
      strokeType: "dash",
      lineCap: "round",
      lineJoin: "round",
      backgroundColor: "transparent",
      backgroundImage: null,
      watermark: null,
      enableDrawFigures: false,
      showMemorySlotMenu: false,
      urlAI: null,
      searchAI: '',
      countAI: 0,
      generateAI: false
    };
  },
  methods: {
    forceAI() {
      let ref = this
      if (Object.keys(this.selectedCostume).length === 0 && this.selectedCostume.constructor === Object) {
        alert('First of all, select a costume type')
      } else if (this.searchAI.length > 4 && this.countAI < 3) { 
        ref.$forceUpdate();
        ref.generateAI = true
        ref.componentKey += 1;
        let canvas = document.getElementById("current-canvas");
        let context = canvas.getContext('2d');
        let costume_url = $('#selected-costume-image')[0].src;
        let base_image  = new Image();
        base_image.src  = costume_url;
        context.drawImage(base_image, 0, 0, 800, 800);
        let sourceCanvas = document.getElementById("VueDrawingCanvas");
        context.drawImage(sourceCanvas, 0, 0);
        ref.$forceUpdate();
        let items_ordered = ref.sortProperties(ref.selectedItems, 'z_index', true, false)
        ref.$forceUpdate();
        for (var key in items_ordered) {
          ref.add_item_to_canvas(items_ordered[key][1]);
        }
        ref.add_comment_to_canvas();
        ref.$forceUpdate();
          let file = canvas.toDataURL("image/png");
          AisApi
            .generateImgUrlV2({ search: ref.searchAI, file: file })
            .then(response => {
              ref.urlAI = response.data.url;
              ref.componentKey += 1;
              let dateToday = new Date(Date.now()).toLocaleString().split(',')[0]
              ref.countAI += 1
              localStorage.setItem(('ND' + dateToday), ref.countAI);
              if (response.data.errors) {
                alert('Moderation error')
              } else if (ref.urlAI === null) { 
                alert('Something went wrong')
              } else {
                ref.generateAI = false
                const routeData = this.$router.resolve({path: '/ai', query: { url: ref.urlAI }});
                window.open(routeData.href, '_blank');
                ref.componentKey += 1;
              }
            })
        // ref.showAIDialog = false
      } else if (ref.searchAI.length > 4 && ref.countAI > 2) {
        ref.generateAI = false
        alert('Only 3 attempts per day')
      } else {
        ref.generateAI = false
        alert('Too short image description')
      }
    },
    handleSecondCanvas() {
      this.showSecondCanvas = !this.showSecondCanvas
    },
    swapCanvases() {
      this.clearHistory();
      let prevImageSecond = this.imageSecond
      this.imageSecond = this.image
      this.image = prevImageSecond
      let prevInitialImage = this.initialImageSecond
      this.initialImage = this.$refs.VueCanvasDrawing.getAllStrokes()
      this.initialImageSecond = this.initialImage
      this.initialImage = prevInitialImage
      let prevComponentKey = this.componentKeySecond
      this.componentKeySecond = this.componentKey
      this.componentKey = prevComponentKey
      let prevBackgroundUrlSecond = this.backgroundUrlSecond
      this.backgroundUrlSecond = this.backgroundUrl
      this.backgroundUrl = prevBackgroundUrlSecond
      let prevSelectedItemsSecond = this.selectedItemsSecond
      this.selectedItemsSecond = this.selectedItems
      this.selectedItems = prevSelectedItemsSecond
      let prevSelectedCostumeSecond = { ...this.selectedCostumeSecond }
      this.selectedCostumeSecond = { ...this.selectedCostume }
      this.selectedCostume = prevSelectedCostumeSecond
      let prevCommentSecond = this.commentSecond
      this.commentSecond = this.comment
      this.comment = prevCommentSecond
      let prevBackgroundSizeSecond = this.backgroundSizeSecond
      this.backgroundSizeSecond = this.backgroundSize
      this.backgroundSize = prevBackgroundSizeSecond
      let prevBackgroundSizeXSecond = this.backgroundSizeXSecond
      this.backgroundSizeXSecond = this.backgroundSizeX
      this.backgroundSizeX = prevBackgroundSizeXSecond
      let prevBackgroundSizeYSecond = this.backgroundSizeYSecond
      this.backgroundSizeYSecond = this.backgroundSizeY
      this.backgroundSizeY = prevBackgroundSizeYSecond
      let prevBackgroundSizeReplaceXSecond = this.backgroundSizeReplaceXSecond
      this.backgroundSizeReplaceXSecond = this.backgroundSizeReplaceX
      this.backgroundSizeReplaceX = prevBackgroundSizeReplaceXSecond
      let prevBackgroundSizeReplaceYSecond = this.backgroundSizeReplaceYSecond
      this.backgroundSizeReplaceYSecond = this.backgroundSizeReplaceY
      this.backgroundSizeReplaceY = prevBackgroundSizeReplaceYSecond
      document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${this.backgroundUrl})`;
      const itmeImages = document.getElementsByClassName('item-image');
      while(itmeImages.length > 0){
        itmeImages[0].parentNode.removeChild(itmeImages[0]);
      }
      const itmeElements = document.getElementsByClassName('item-el');
      while(itmeElements.length > 0){
        itmeElements[0].parentNode.removeChild(itmeElements[0]);
      }
      this.setSavedBackroundCover();
      for (const [key, val] of Object.entries(this.selectedItems)) {
        this.selectItem(val, val, false, key, true)
      }
    },
    updateLanguage() {
      this.costumeListComponentKey += 1;
      this.itemTypesListComponentKey += 1;
      this.toolbarComponentKey += 1;
    },
    saveToSlot(position, name=null) {
      this.initialImage = this.$refs.VueCanvasDrawing.getAllStrokes()
      var params = { slot: {
          image: JSON.stringify(this.initialImage),
          construction: JSON.stringify({
            backgroundUrl: this.backgroundUrl,
            backgroundSize: this.backgroundSize,
            backgroundSizeX: this.backgroundSizeX,
            backgroundSizeY: this.backgroundSizeY,
            backgroundSizeReplaceX: this.backgroundSizeReplaceX,
            backgroundSizeReplaceY: this.backgroundSizeReplaceY,
            selectedItems: this.selectedItems,
            selectedCostume: this.selectedCostume,
          }),
          position: position,
          name: name
        }
      }
      SlotApi.saveSlot(params)
    },
    async deleteSlot(params) {
      SlotApi.deleteSlot(params)
    },
    async loadFromSlot(position) {
      await this.clearCanvas(false);
      let response = await SlotApi.getSlot(position)
      this.initialImage = await JSON.parse(response.data.image)
      this.componentKey += 1;
      let construction = await JSON.parse(response.data.construction)
      this.backgroundSize = construction['backgroundSize']
      this.backgroundSizeX = construction['backgroundSizeX']
      this.backgroundSizeY = construction['backgroundSizeY']
      this.backgroundSizeReplaceX = construction['backgroundSizeReplaceX']
      this.backgroundSizeReplaceY = construction['backgroundSizeReplaceY']
      this.backgroundUrl = construction['backgroundUrl']
      this.selectedItems = construction['selectedItems']
      this.selectedCostume = construction['selectedCostume']
      document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${this.backgroundUrl})`;
      this.setSavedBackroundCover();
      for (const [key, val] of Object.entries(this.selectedItems)) {
        this.selectItem(val, val, false, key, true)
      }
    },
    openSocailModal() {
      var randNum = Math.floor(Math.random() * 10) + 1;
      if (randNum === 1) {
        this.showSocialDialog = true;
      } else if (randNum === 2) {
        this.showSocialDialog2 = true;
      }
    },
    webToTop(event) {
      if (this.vcorection > -100) {
        this.vcorection -= 1
      }
    },
    webToDown(event) {
      if (this.vcorection < 100) {
        this.vcorection += 1
      }
    },
    webToLeft(event) {
      if (this.hcorection > -100) {
        this.hcorection -= 1
      }
    },
    webToRight(event) {
      if (this.hcorection < +100) {
        this.hcorection += 1
      }
    },
    uploadItemsFile(event) {
      this.uploadedItemsFile = event.target.files[0]
    },
    changeQuality(quality) {
      this.imgQuality = quality
    },
    changeFormat(format) {
      this.imgFormat = format
    },
    gotoHowToUse() {
      let route = this.$router.resolve({ path: "/how-to-use" });
      window.open(route.href);
    },
    zoomPlus(history = true) {
      // if (history) { this.saveToHistory('zoomPlus', 'zoomMinus') }
      if (this.zoom < 1.9) {
        this.zoom = (+this.zoom + 0.1)
        window.parent.document.body.style.zoom = this.zoom
      }
    },
    zoomMinus(history = true) {
      // if (history) { this.saveToHistory('zoomMinus', 'zoomPlus') }
      if (this.zoom > 0.5) {
        this.zoom = (+this.zoom - 0.1)
        window.parent.document.body.style.zoom = this.zoom
      }
    },
    backgroungSwitchCover(history=true) {
      if (history) { this.saveToHistory('backgroungSwitchCover', 'backgroungSwitchCover') }
      if (this.backgroundSize == 'contain' && (this.backgroundSizeX > 800 || this.backgroundSizeY > 800)) {
        document.getElementById('app-current-preview-canvas').style.backgroundSize = 'auto';
        this.backgroundSize = 'cover';
      } else {
        document.getElementById('app-current-preview-canvas').style.backgroundSize = `${this.backgroundSizeReplaceX}px ${this.backgroundSizeReplaceY}px`;
        this.backgroundSize = 'contain';
      }
    },
    setSavedBackroundCover() {
      if (this.backgroundSize == 'contain' && (this.backgroundSizeX > 800 || this.backgroundSizeY > 800)) {
        document.getElementById('app-current-preview-canvas').style.backgroundSize = `${this.backgroundSizeReplaceX}px ${this.backgroundSizeReplaceY}px`;
        this.backgroundSize = 'contain';
      } else {
        document.getElementById('app-current-preview-canvas').style.backgroundSize = 'auto';
        this.backgroundSize = 'cover';
      }
    },
    changeToFigures() {
      $('.app-middle-menu__btn').hide('slow');
      $('.btn_to_consturct').show();
      $('.app-middle-menu').width(90);
      $('.draw-menu').show();
      this.enableDrawFigures = true;
      let sourceCanvas = document.getElementById("VueDrawingCanvas");
      sourceCanvas.style.zIndex = "50000";
      sourceCanvas.style.top = 0;
      sourceCanvas.style.bottom = "initial";
    },
    changeToConstruct() {
      $('.draw-menu').hide();
      $('.app-middle-menu').width(40);     
      $('.app-middle-menu__btn').show('slow');
      $('.btn_to_consturct').hide();
      this.enableDrawFigures = false;
      let sourceCanvas = document.getElementById("VueDrawingCanvas");
      sourceCanvas.style.zIndex = "2000";
      sourceCanvas.style.top = "initial";
      sourceCanvas.style.bottom = "2400px";
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    clearHistory() {
      this.historyIndex = 0;
      localStorage.setItem('history', JSON.stringify([]));
    },
    clearCanvas(ask = true) {
      let result = ask ? confirm('Are you sure you want to clear the canvas?') : true;
      if (result) {
        this.selectedItems = {}
        this.selectedCostume = {}
        this.itemIndex = 0
        this.canvas = null
        this.changeIndex = 0
        this.comment = ''
        this.showWeb = false
        this.vcorection = 0
        this.hcorection = 0
        this.backgroundSize = 'cover'
        this.backgroundSizeX = null
        this.backgroundSizeY = null
        this.backgroundSizeReplaceX = null
        this.backgroundSizeReplaceY = null
        this.backgroundUrl = "/uploads/fabric/img/1/White-square.jpg"
        document.getElementById('app-current-preview-canvas').style["background-image"] = 'url("/uploads/fabric/img/1/White-square.jpg")';
        if (document.getElementById('selected-costume-image')) {
          document.getElementById('selected-costume-image').remove();
        }
        document.querySelectorAll('.item-el').forEach(e => e.remove());
        document.getElementById('app-current-preview-canvas').style.backgroundImage = 'url("/uploads/fabric/img/1/White-square.jpg")';
        this.clearHistory();
        
      }
    },
    sendToUser() {
      this.$forceUpdate();
      var randNum = Math.floor(Math.random() * 10) + 1;
      this.openSocailModal();
      this.add_background_to_canvas();
      var ref = this
      setTimeout(()=>{ ref.BEFOREdrawInlineSVGbase() }, 1000);
      setTimeout(()=>{ ref.sendCanvas() }, 2000);
    },
    generateCanvas() {
      this.$forceUpdate();
      this.openSocailModal();
      this.add_background_to_canvas();
      var ref = this
      setTimeout(()=>{ ref.BEFOREdrawInlineSVGbase() }, 1000);
      setTimeout(()=>{ ref.downloadCanvas() }, 2000);
    },
    dateTimeNow() {
      let today    = new Date();
      let day      = today.getUTCDate();
      let date     = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time     = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
      let dateTime = date+'-'+time;
      return dateTime;
    },
    sendCanvas() {
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      let sourceCanvas = document.getElementById("VueDrawingCanvas");
      context.drawImage(sourceCanvas, 0, 0);
      this.$forceUpdate();
      let items_ordered = this.sortProperties(this.selectedItems, 'z_index', true, false)
      this.$forceUpdate();
      for (var key in items_ordered) {
        this.add_item_to_canvas(items_ordered[key][1]);
      }
      this.add_comment_to_canvas();
      this.$forceUpdate();
      var img = canvas.toDataURL(this.imgFormat, this.imgQuality);
      var imgname = 'costume-' + this.dateTimeNow();
      // let link      = document.createElement('a');
      // link.download = 'costume-' + this.dateTimeNow();
      // link.href     = canvas.toDataURL(this.imgFormat, this.imgQuality);
      if (this.user) {
        ContactsApi.sendCanvas(img, imgname, this.user.email)
        alert(`Thanks for using our service. Your drawing has been sent to ${this.user.email}`) 
      }
    },
    downloadCanvas() {
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      let sourceCanvas = document.getElementById("VueDrawingCanvas");
      context.drawImage(sourceCanvas, 0, 0);
      this.$forceUpdate();
      let items_ordered = this.sortProperties(this.selectedItems, 'z_index', true, false)
      this.$forceUpdate();
      for (var key in items_ordered) {
        this.add_item_to_canvas(items_ordered[key][1]);
      }
      this.add_comment_to_canvas();
      this.$forceUpdate();
      let link      = document.createElement('a');
      link.download = 'costume-' + this.dateTimeNow();
      link.href     = canvas.toDataURL(this.imgFormat, this.imgQuality);
      link.click();
    },
    sortProperties(obj, sortedBy, isNumericSort, reverse) {
      sortedBy = sortedBy || 1; // by default first key
      isNumericSort = isNumericSort || false; // by default text sort
      reverse = reverse || false; // by default no reverse

      var reversed = (reverse) ? -1 : 1;

      var sortable = [];
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              sortable.push([key, obj[key]]);
          }
      }
      if (isNumericSort)
          sortable.sort(function (a, b) {
              return reversed * (a[1][sortedBy] - b[1][sortedBy]);
          });
      else
          sortable.sort(function (a, b) {
              var x = a[1][sortedBy].toLowerCase(),
                  y = b[1][sortedBy].toLowerCase();
              return x < y ? reversed * -1 : x > y ? reversed : 0;
          });
      return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    },
    BEFOREdrawInlineSVGbase(){
      let canvas      = document.getElementById("current-canvas");
      let context     = canvas.getContext('2d');
      let costume_url = $('#selected-costume-image')[0].src;
      let base_image  = new Image();
      base_image.src  = costume_url;
      context.drawImage(base_image, 0, 0, 800, 800);
    },
    add_background_to_canvas() {
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      let bg_url = $('#app-current-preview-canvas').css('background-image');
      bg_url = bg_url.replace('url(','').replace(')','').replace(/\"/gi, "");
      let base_image = new Image(50, 50)
      base_image.src = bg_url;
      if (this.backgroundSize == 'contain' && (this.backgroundSizeX > 800 || this.backgroundSizeY > 800)) {
        if (this.backgroundSizeX > this.backgroundSizeY) {
          let rows = Math.ceil(this.backgroundSizeX / this.backgroundSizeY)
          for (let step = 0; step < rows; step++) {
            context.drawImage(base_image, 0 , step * this.backgroundSizeReplaceY, this.backgroundSizeReplaceX, this.backgroundSizeReplaceY);
          }
        } else if (this.backgroundSizeX < this.backgroundSizeY) {
          let columns = Math.ceil(this.backgroundSizeY / this.backgroundSizeX)
          for (let step = 0; step < columns; step++) {
            context.drawImage(base_image, step * this.backgroundSizeReplaceX, 0, this.backgroundSizeReplaceX, this.backgroundSizeReplaceY);
          }
        } else if (this.backgroundSizeX == this.backgroundSizeY) {
          context.drawImage(base_image, 0, 0, 800, 800);
        }
      } else {
        var ptrn = context.createPattern(base_image, 'repeat');
        context.fillStyle = ptrn;
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
    },
    add_comment_to_canvas(context) {
      let canvas = document.getElementById("current-canvas");
      var context = canvas.getContext('2d');
      context.font = "14px verdana, sans-serif";
      context.fillStyle = "#000000";
      context.fillText(this.comment, 15, 778);
    },
    add_item_to_canvas(item) {
      let canvas = document.getElementById("current-canvas");
      let base_image = new Image();
      base_image.src = item.img.url;
      if (item.flip) {
        base_image.style="filter:FlipH"
      }
      let base_width  = base_image.width;
      let base_height = base_image.height;
      let size_x;
      let size_y;
      let item_size = this.sizeStandart * item.size / 100
      if (base_width > base_height) {
        size_x = item_size;
        size_y = item_size * (base_height / base_width);
      } else {
        size_x = item_size * (base_width / base_height);
        size_y = item_size;
      }
      let shift_x = (item_size - size_x) / 2;
      let shift_y = (item_size - size_y) / 2;
      let position_x = this.sizeStandart * item.x / 100 + shift_x;
      let position_y = this.sizeStandart * item.y / 100 + shift_y;
      // new rotate and flip
      this.drawRotatedImage(canvas, base_image, item.rotate_degree, item.flip, position_x, position_y, size_x, size_y, item_size)  
    },
    drawRotatedImage(canvas, base_image, angle, mirrorImage, position_x, position_y, size_x, size_y, item_size) {
      var context = canvas.getContext('2d');
        context.save();
      if (angle > 0 || angle < 0) {
          context.translate((position_x + size_x / 2), (position_y + size_y / 2));
          context.rotate(angle * (mirrorImage ? (-1) : 1) * (Math.PI / 180));
          context.translate((-position_x - size_x / 2), (-position_y - size_y / 2));
          // context.restore();
      }
      if (mirrorImage === true) {
          context.scale(-1, 1);
          context.drawImage(base_image, (position_x + size_x) * (-1), position_y, size_x, size_y);
      } else {
        context.drawImage(base_image, position_x, position_y, size_x, size_y);
      }
      context.restore();
    },
    selectItem(val, copyData = null, history = true, itemIndex = null, loaded=false) {
      if (history) { this.saveToHistory('selectItem', 'deSelectItem', { 'val': val, 'copyData': copyData, 'imgIndex': this.itemIndex }, this.itemIndex) }
      if (Object.keys(this.selectedCostume).length === 0) {
        return
      }
      let img_index = itemIndex !== null ? itemIndex : `${this.itemIndex}`;
      let obj = { x: 50, y: 50, size: val['default_size']}
      let new_item = Object.assign(obj, val)
      this.selectedItems[img_index] = new_item
      if (copyData) {
        this.selectedItems[img_index]['rotate_degree'] = copyData['rotate_degree'] ? copyData['rotate_degree'] : 0;
        this.selectedItems[img_index]['size'] = copyData['size'];
        this.selectedItems[img_index]['flip'] = !!copyData['flip'];
      }
      let ref = this
      // insert new item
      let img        = document.createElement("img");
      img.className  = "item-image";
      img.id         = `item-image-${img_index}`;
      img.dataset['index'] = img_index;
      let half_size  = this.sizeStandart * new_item.size / 100 / 2
      let x_position = this.sizeStandart * obj.x / 100 - (loaded ? 0 : half_size);
      let y_position = this.sizeStandart * obj.y / 100 - (loaded ? 0 : half_size);
      // let src        = document.getElementById("app-current-preview-canvas");
      let src        = document.getElementsByClassName("output")[0];
      var newDiv     = document.createElement("div");
      newDiv.id      = `item-el-${img_index}`;
      newDiv.className = 'item-el'
      newDiv.setAttribute("style", `position: absolute; left:${x_position}px; top:${y_position}px; width: 100%; z-index: 1000;`);
      src.appendChild(newDiv);
      if (val.customX) {
        if (val.customX >= val.customY) {
          let coef = val.customY / val.customX
          let h = this.sizeStandart * coef * obj.size / 100;
          var mt = (this.sizeStandart * obj.size / 100 - h)/ 2
          img.setAttribute("style", `padding: 0px; position: absolute; height: ${h}px; width: ${this.sizeStandart * obj.size / 100}px; margin-top: ${mt}px;`);
        } else {
          let coef = val.customX / val.customY
          let w = this.sizeStandart * coef * obj.size / 100;
          var ml = (this.sizeStandart * obj.size / 100 - w)/ 2
          img.setAttribute("style", `padding: 0px; position: absolute; height: ${this.sizeStandart * obj.size / 100}px; width: ${w}px; margin-left: ${ml}px;`);
        }
      } else {
      img.setAttribute("style", `padding: 0px; position: absolute; height: ${this.sizeStandart * obj.size / 100}px; width: ${this.sizeStandart * obj.size / 100}px;`);
      }
      img.onmouseover = function(event) {
        let target = event.target;
        target.style.padding = '0px';
        target.style.border = '1px dotted grey';
      };
      img.onmouseout = function(event) {
        let target = event.target;
        target.style.padding = '1px';
        target.style.border = 'none';
      };
      img.src = new_item.img.url;
      this.selectedItems[img_index]['x'] = (x_position / this.sizeStandart * 100);
      this.selectedItems[img_index]['y'] = (y_position / this.sizeStandart * 100);
      this.selectedItems[img_index]['z_index'] = this.changeIndex;
      this.changeIndex += 1;
      if (copyData) {
        img.style["-webkit-transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
        img.style["transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${ref.selectedItems[img_index]['rotate_degree']}deg)`;
      } else {
        this.selectedItems[img_index]['rotate_degree'] = 0;
      }
      if (itemIndex === null) { this.itemIndex += 1; }
      
      // ---

      this.add_buttons_to_item(img_index, img, val)
    },
    add_buttons_to_item(img_index, img, val = null) {
      let ref = this
      let srcNewDiv = document.getElementById(`item-el-${img_index}`);
      let copy = document.createElement('div');
      copy.className = 'zoom-copy'
      copy.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; left: 0px; top: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; user-select: none; border: 1px solid white;`)
      copy.textContent = 'C'
      srcNewDiv.appendChild(copy);
      let plus = document.createElement('div');
      plus.className = 'zoom-plus'
      plus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; left: 25px; top: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; user-select: none;border: 1px solid white;`)
      plus.textContent = '+'
      srcNewDiv.appendChild(plus);

      let minus = document.createElement('div');
      minus.className = 'zoom-minus'
      minus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; left: 25px; top: 50px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; user-select: none;border: 1px solid white;`)
      minus.textContent = '-'
      srcNewDiv.appendChild(minus);

      let down_index = document.createElement('div');
      down_index.className = 'down-index'
      down_index.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; left: 25px; user-select: none;border: 1px solid white;`)
      down_index.textContent = '↓'
      srcNewDiv.appendChild(down_index);

      let remove = document.createElement('div');
      remove.className = 'item-remove'
      remove.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; left: 50px; user-select: none;border: 1px solid white;`)
      remove.textContent = 'X'
      srcNewDiv.appendChild(remove);

      let flip = document.createElement('div');
      flip.className = 'item-flip'
      flip.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; top: 50px; user-select: none;border: 1px solid white;`)
      flip.textContent = 'F'
      srcNewDiv.appendChild(flip);

      let rotate_plus = document.createElement('div');
      rotate_plus.className = 'item-rotate-plus'
      rotate_plus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; top: 25px; left: 50px; user-select: none;border: 1px solid white;`)
      rotate_plus.textContent = 'R+'
      srcNewDiv.appendChild(rotate_plus);

      let rotate_minus = document.createElement('div');
      rotate_minus.className = 'item-rotate-minus'
      rotate_minus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 25px; height: 25px; text-align: center; color: black; display: none; z-index: 10; cursor: pointer; top: 50px; left: 50px; user-select: none;border: 1px solid white;`)
      rotate_minus.textContent = 'R-'
      srcNewDiv.appendChild(rotate_minus);

      srcNewDiv.appendChild(img); // after tools because need find closest element in up dom

      var item = document.getElementById(img.id);
      this.addDragDrop(srcNewDiv);
      
      srcNewDiv.addEventListener("mouseenter", function( event ) {
        // down.style.display="block"
        ref.itemPropsShow=true;
        ref.itemPropsRotate=ref.selectedItems[img_index]['rotate_degree'];
        ref.itemPropsZoom=ref.selectedItems[img_index]['size'];
        ref.itemPropsFlip=ref.selectedItems[img_index]['flip'];
        ref.itemPropsX=ref.selectedItems[img_index]['x'];
        ref.itemPropsY=ref.selectedItems[img_index]['y'];
        copy.style.display="block";
        plus.style.display="block";
        minus.style.display="block";
        remove.style.display="block";
        flip.style.display="block";
        rotate_plus.style.display="block";
        rotate_minus.style.display="block";
        down_index.style.display="block";
      }); 
      srcNewDiv.addEventListener("mouseleave", function( event ) {
        // down.style.display="none"
        ref.itemPropsShow=false;
        ref.itemPropsRotate=0;
        ref.itemPropsZoom=0;
        ref.itemPropsFlip=false;
        ref.itemPropsX=0;
        ref.itemPropsY=0;
        copy.style.display="none";
        plus.style.display="none";
        minus.style.display="none";
        remove.style.display="none";
        flip.style.display="none";
        rotate_plus.style.display="none";
        rotate_minus.style.display="none";
        down_index.style.display="none";
      });
      // down.addEventListener("mousedown", function( event ) {
        
      // });
      down_index.addEventListener("mousedown", function( event ) {
        setTimeout(()=>{ ref.down_item(img_index, srcNewDiv) }, 600);
      });
      copy.addEventListener("mousedown", function( event ) {
        ref.selectItem(val, ref.selectedItems[img_index])
      });
      plus.addEventListener("mousedown", function( event ) {
        if (ref.selectedItems[img_index]['size'] < 35) {
          let img2             = document.getElementById(`item-image-${img_index}`);
          let conter_operation_v   = {'prev_size': ref.selectedItems[img_index]['size'], 'img_index': img_index}
          ref.selectedItems[img_index]['size'] += 1;


          let coef = null;
          let h = null;
          let mt = null;
          let obj = ref.selectedItems[img_index];
          if (obj.customX) {
            if (obj.customX >= obj.customY) {
              coef = obj.customY / obj.customX
              h = ref.sizeStandart * coef * obj.size / 100;
              mt = (ref.sizeStandart * obj.size / 100 - h)/ 2
              img2.style["height"] = `${h}px`;
              img2.style["width"]  = `${ref.sizeStandart * obj.size / 100}px`;
              img2.style["margin-top"] = `${mt}px`;
              img2.setAttribute('height', `${h}px`);
              img2.setAttribute('width', `${ref.sizeStandart * obj.size / 100}px`);
              img2.setAttribute('margin-top', `${mt}px`);
            } else {
              coef = obj.customX / obj.customY
              w = ref.sizeStandart * coef * obj.size / 100;
              ml = (ref.sizeStandart * obj.size / 100 - w)/ 2
              img2.style["height"] = `${ref.sizeStandart * obj.size / 100}px`;
              img2.style["width"]  = `${w}px`;
              img2.style["margin-left"]  = `${ml}px`;
              img2.setAttribute('height', `${ref.sizeStandart * obj.size / 100}px`);
              img2.setAttribute('width', `${w}px`);
              img2.setAttribute('margin-top', `${ml}px`);
            }
          } else {
            let new_size         = ref.sizeStandart * ref.selectedItems[img_index]['size'] / 100 + 'px';
            img2.style["height"] = new_size;
            img2.style["width"]  = new_size;
            img2.setAttribute('height', new_size);
            img2.setAttribute('width', new_size);
          }

          // let new_size         = ref.sizeStandart * obj['size'] / 100 + 'px';

          let operation_v        = {'new_size': ref.selectedItems[img_index]['size'], 'img_index': img_index}
          ref.itemPropsZoom=ref.selectedItems[img_index]['size'];
          ref.saveToHistory('changeSize', 'changeSize', operation_v, conter_operation_v)
        }
      });
      minus.addEventListener("mousedown", function( event ) {
        if (ref.selectedItems[img_index]['size'] > 3) {
          let img2             = document.getElementById(`item-image-${img_index}`);
          let conter_operation_v   = {'prev_size': ref.selectedItems[img_index]['size'], 'img_index': img_index}
          ref.selectedItems[img_index]['size'] -= 1;

          let coef = null;
          let h = null;
          let mt = null;
          let w = null;
          let ml = null;
          let obj = ref.selectedItems[img_index];
          if (obj.customX) {
            if (obj.customX >= obj.customY) {
              coef = obj.customY / obj.customX
              h = ref.sizeStandart * coef * obj.size / 100;
              mt = (ref.sizeStandart * obj.size / 100 - h)/ 2
              img2.style["height"] = `${h}px`;
              img2.style["width"]  = `${ref.sizeStandart * obj.size / 100}px`;
              img2.style["margin-top"] = `${mt}px`;
              img2.setAttribute('height', `${h}px`);
              img2.setAttribute('width', `${ref.sizeStandart * obj.size / 100}px`);
              img2.setAttribute('margin-top', `${mt}px`);
            } else {
              coef = obj.customX / obj.customY
              w = ref.sizeStandart * coef * obj.size / 100;
              ml = (ref.sizeStandart * obj.size / 100 - w)/ 2

              img2.style["height"] = `${ref.sizeStandart * obj.size / 100}px`;
              img2.style["width"]  = `${w}px`;
              img2.style["margin-left"]  = `${ml}px`;
              img2.setAttribute('height', `${ref.sizeStandart * obj.size / 100}px`);
              img2.setAttribute('width', `${w}px`);
              img2.setAttribute('margin-top', `${ml}px`);
            }
          } else {
            let new_size = ref.sizeStandart * ref.selectedItems[img_index]['size'] / 100 + 'px';
            img2.style["height"] = new_size;
            img2.style["width"]  = new_size;
            img2.setAttribute('height', new_size);
            img2.setAttribute('width', new_size);
          }

          let operation_v        = {'new_size': ref.selectedItems[img_index]['size'], 'img_index': img_index}
          ref.itemPropsZoom=ref.selectedItems[img_index]['size'];
          ref.saveToHistory('changeSize', 'changeSize', operation_v, conter_operation_v)
        }
      });
      remove.addEventListener("mousedown", function( event ) {
        ref.removeItem(ref, img_index, plus, minus, remove, copy, flip, rotate_plus, rotate_minus, down_index)
      });
      flip.addEventListener("mousedown", function( event ) {

        let img2 = document.getElementById(`item-image-${img_index}`);
        let conter_operation_v = {
          'prev_flip': !!ref.selectedItems[img_index]['flip'],
          'prev_rotate_degree': ref.selectedItems[img_index]['rotate_degree'],
          'img_index': img_index
        }
        ref.itemPropsFlip=ref.selectedItems[img_index]['flip']
        ref.selectedItems[img_index]['flip'] = !ref.selectedItems[img_index]['flip']

        ref.selectedItems[img_index]['rotate_degree'] = ref.selectedItems[img_index]['rotate_degree']  * (-1);

        img2.style["-webkit-transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
        img2.style["transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${ref.selectedItems[img_index]['rotate_degree']}deg)`;

        let operation_v = { 'new_flip': !!ref.selectedItems[img_index]['flip'], 'new_rotate_degree': ref.selectedItems[img_index]['rotate_degree'], 'img_index': img_index}
        ref.saveToHistory('flip', 'flip', operation_v, conter_operation_v)
      });
      rotate_plus.addEventListener("mousedown", function( event ) {
        ref.rotate_plus_keep(img_index);
      });
      rotate_plus.addEventListener("mouseup", function( event ) {
        clearInterval(ref.rotateInterval);
      });
      rotate_minus.addEventListener("mousedown", function( event ) {
        ref.rotate_minus_keep(img_index);
      });
      rotate_minus.addEventListener("mouseup", function( event ) {
        clearInterval(ref.rotateInterval);
      });
      rotate_plus.addEventListener("touchstart", function( event ) {
        ref.rotate_plus_keep_mobile(img_index);
      });
      rotate_minus.addEventListener("touchstart", function( event ) {
        ref.rotate_minus_keep_mobile(img_index);
      });
    },
    rotate_plus_keep_mobile: function(img_index){
      let img2 = document.getElementById(`item-image-${img_index}`);
      let conter_operation_v = {'img_index': img_index, 'prev_transform': img2.style["transform"], 'prev_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
      let rotate_degree = 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1)
      this.selectedItems[img_index]['rotate_degree'] += rotate_degree;
      let transform = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`
      img2.style["transform"] = transform;
      let operation_v = {'img_index': img_index, 'new_transform': transform, 'new_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
      this.itemPropsRotate=this.selectedItems[img_index]['rotate_degree'];
      this.saveToHistory('changeRotate', 'changeRotate', operation_v, conter_operation_v)
    },
    rotate_minus_keep_mobile: function(img_index){
      let img2 = document.getElementById(`item-image-${img_index}`);
      let conter_operation_v = {'img_index': img_index, 'prev_transform': img2.style["transform"], 'prev_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
      let rotate_degree = 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1)
      this.selectedItems[img_index]['rotate_degree'] -= rotate_degree;
      let transform = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`
      img2.style["transform"] = transform;
      let operation_v = {'img_index': img_index, 'new_transform': transform, 'new_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
      this.itemPropsRotate=this.selectedItems[img_index]['rotate_degree'];
      this.saveToHistory('changeRotate', 'changeRotate', operation_v, conter_operation_v)
    },
    rotate_plus_keep: function(img_index){
      this.rotateInterval = setInterval(() => {
        let img2 = document.getElementById(`item-image-${img_index}`);
        let conter_operation_v = {'img_index': img_index, 'prev_transform': img2.style["transform"], 'prev_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
        let rotate_degree = 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1)
        this.selectedItems[img_index]['rotate_degree'] += rotate_degree;
        let transform = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`
        img2.style["transform"] = transform;
        let operation_v = {'img_index': img_index, 'new_transform': transform, 'new_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
        this.itemPropsRotate=this.selectedItems[img_index]['rotate_degree'];
        this.saveToHistory('changeRotate', 'changeRotate', operation_v, conter_operation_v)
      }, 100)
    },
    rotate_minus_keep: function(img_index){
      this.rotateInterval = setInterval(() => {
        let img2          = document.getElementById(`item-image-${img_index}`);
        let conter_operation_v = {'img_index': img_index, 'prev_transform': img2.style["transform"], 'prev_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
        let rotate_degree = 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1)
        this.selectedItems[img_index]['rotate_degree'] -= rotate_degree;
        let transform = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`
        img2.style["transform"] = transform;
        let operation_v = {'img_index': img_index, 'new_transform': transform, 'new_rotate_degree': this.selectedItems[img_index]['rotate_degree']}
        this.itemPropsRotate=this.selectedItems[img_index]['rotate_degree'];
        this.saveToHistory('changeRotate', 'changeRotate', operation_v, conter_operation_v)
      }, 100)
    },
    down_item(img_index, srcNewDiv) {
      let operation_v = { 'img_index': img_index };
      let prev_sibling  = srcNewDiv.previousElementSibling
      let prev_id = prev_sibling['id']
      let conter_operation_v = { 'img_index': img_index, 'z_index': this.selectedItems[img_index].z_index, 'prev_id': prev_id };
      this.saveToHistory('downZIndex', 'returnZIndex', operation_v, conter_operation_v)
      let img_output = document.querySelector('div.output img');
      img_output.after(srcNewDiv);
      Object.keys(this.selectedItems).forEach(index => {
        this.selectedItems[index].z_index += 1;
      });
      this.selectedItems[img_index].z_index = 1;
    },
    removeItem(ref, img_index, plus = null, minus = null, remove = null, copy = null, flip = null, rotate_plus = null, rotate_minus = null, down_index = null) {
      // let conter_operation_v = {'img_index': img_index}
      let deleted_item = ref.selectedItems[img_index]
      ref.$delete(ref.selectedItems, img_index)
      let conter_operation_v = { 'img_index': img_index, 'prev_item': deleted_item }
      document.getElementById(`item-image-${img_index}`).remove();
      plus.remove();
      minus.remove();
      remove.remove();
      copy.remove();
      flip.remove();
      rotate_plus.remove();
      rotate_minus.remove();
      down_index.remove();
      let operation_v = img_index
      this.saveToHistory('removeItem', 'returnItem', operation_v, conter_operation_v)
    },
    addDragDrop(item) {
      item.ondragstart = function() {
        return false;
      };
      var ref = this
      let moving = null;
      item.addEventListener("touchstart", function( e ) {
        if (e.target.nodeName != 'IMG') { return; }
        ref.changeIndex += 1;
        // let src = document.getElementById("app-current-preview-canvas");
        let src = document.getElementsByClassName("output")[0];
        let coords = getCoords(item);
        let shiftX = e.changedTouches[0].clientX - coords.left;
        let shiftY = e.changedTouches[0].clientY - coords.top;

        // подготовить к перемещению
        // 2. разместить на том же месте, но в абсолютных координатах
        item.style.position = 'absolute';
        
        // переместим в body, чтобы мяч был точно не внутри position:relative
        src.appendChild(item);
        moveAt(e);
        item.style.zIndex = 1000; // показывать мяч над другими элементами

        // передвинуть мяч под координаты курсора
        // и сдвинуть на половину ширины/высоты для центрирования
        function moveAt(e) {
          e.preventDefault();
          let pageX = e.changedTouches[0].clientX
          let pageY = e.changedTouches[0].clientY
          // let elem_index  = e.toElement.dataset.index;
          let elem_index  = item.getElementsByClassName('item-image')[0].dataset.index;
          if (ref.selectedItems[elem_index]) {
            let item_id     = item['id']

            let prev_left   = item.style.left
            let prev_top    = item.style.top
            let prev_x       = ref.selectedItems[elem_index].x
            let prev_y       = ref.selectedItems[elem_index].y
            let prev_z_index = ref.selectedItems[elem_index].z_index

            let src_coords  = getCoords(src);
            let new_left    = (pageX - shiftX - src_coords.left) + 'px'
            let new_top     = (pageY - shiftY - src_coords.top) + 'px'
            item.style.left = new_left;
            item.style.top  = new_top;
            let new_x       = (pageX - shiftX - src_coords.left) / ref.sizeStandart * 100
            let new_y       = (pageY - shiftY - src_coords.top) / ref.sizeStandart * 100
            let new_z_index = ref.changeIndex
            
            ref.selectedItems[elem_index].x = new_x;
            ref.selectedItems[elem_index].y = new_y;
            ref.selectedItems[elem_index].z_index = new_z_index;

            let operation_v        = { 'item_id': item_id, 'elem_index': elem_index, 'x': new_x, 'y': new_y, 'z': new_z_index, 'top': prev_top, 'left': prev_left }
            let conter_operation_v = { 'item_id': item_id, 'elem_index': elem_index, 'x': prev_x, 'y': prev_y, 'z': prev_z_index, 'top': new_top, 'left': new_left }
            ref.itemPropsX=new_x;
            ref.itemPropsY=new_y;
            ref.saveToHistory('moveTo', 'moveTo', operation_v, conter_operation_v)
          }
        }
        function getCoords(elem) {   // кроме IE8-
          var box = elem.getBoundingClientRect();
          return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset
          };
        }
        let touchmove_src = function( e ) {
          moveAt(e);
        }
        // 3, перемещать по экрану
        src.addEventListener("touchmove", touchmove_src)

        // 4. отследить окончание переноса
        item.addEventListener("touchend", function() {
          src.touchmove = null;
          item.touchend = null;
          src.removeEventListener("touchmove", touchmove_src)
        })
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].className == 'item-image') { continue; }
          item.children[i].style.display = (item.children[i].style.display == 'none') ? 'block' : 'none'
        }

      });
      item.onmousedown = function(e) { // 1. отследить нажатие
        ref.changeIndex += 1;
        // let src = document.getElementById("app-current-preview-canvas");
        let src        = document.getElementsByClassName("output")[0];
        let coords = getCoords(item);
        let shiftX = e.pageX - coords.left;
        let shiftY = e.pageY - coords.top;

        // подготовить к перемещению
        // 2. разместить на том же месте, но в абсолютных координатах
        item.style.position = 'absolute';
        
        // переместим в body, чтобы мяч был точно не внутри position:relative
        src.appendChild(item);
        moveAt(e);
        item.style.zIndex = 1000; // показывать мяч над другими элементами

        // передвинуть мяч под координаты курсора
        // и сдвинуть на половину ширины/высоты для центрирования
        function moveAt(e) {
          // let elem_index  = e.toElement.dataset.index;
          let elem_index  = item.getElementsByClassName('item-image')[0].dataset.index;
          if (ref.selectedItems[elem_index]) {
            let item_id     = item['id']
            let prev_left   = item.style.left
            let prev_top    = item.style.top
            let prev_x       = ref.selectedItems[elem_index].x
            let prev_y       = ref.selectedItems[elem_index].y
            let prev_z_index = ref.selectedItems[elem_index].z_index
            let src_coords = getCoords(src);
            let new_left    = (e.pageX - shiftX - src_coords.left) + 'px';
            let new_top     = (e.pageY - shiftY - src_coords.top) + 'px';
            item.style.left = new_left;
            item.style.top  = new_top;

            let new_x       = (e.pageX - shiftX - src_coords.left) / ref.sizeStandart * 100;
            let new_y       = (e.pageY - shiftY - src_coords.top) / ref.sizeStandart * 100;
            let new_z_index = ref.changeIndex
            ref.selectedItems[elem_index].x = new_x;
            ref.selectedItems[elem_index].y = new_y;
            ref.selectedItems[elem_index].z_index = new_z_index;
            let operation_v        = { 'item_id': item_id, 'elem_index': elem_index, 'x': new_x, 'y': new_y, 'z': new_z_index, 'top': prev_top, 'left': prev_left }
            let conter_operation_v = { 'item_id': item_id, 'elem_index': elem_index, 'x': prev_x, 'y': prev_y, 'z': prev_z_index, 'top': new_top, 'left': new_left }
            ref.itemPropsX=new_x;
            ref.itemPropsY=new_y;
            ref.showSimilarItem(elem_index)
            ref.saveToHistory('moveTo', 'moveTo', operation_v, conter_operation_v)
          }
        }
        function getCoords(elem) {   // кроме IE8-
          var box = elem.getBoundingClientRect();
          return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset
          };
        }
        // 3, перемещать по экрану
        src.onmousemove = function(e) {
          moveAt(e);
        }

        // 4. отследить окончание переноса
        item.onmouseup = function() {
          src.onmousemove = null;
          item.onmouseup = null;
          ref.stopAllLightItem();
        }
      }
    },
    showSimilarItem(elem_index) {
      let x = this.selectedItems[elem_index].x
      let y = this.selectedItems[elem_index].y
      let size = this.selectedItems[elem_index].size
      let x_end = x + size;
      let y_end = y + size;
      let x_mid = x + size/2;
      let y_mid = y + size/2;
      let rotate_degree = this.selectedItems[elem_index].rotate_degree
      let flip = this.selectedItems[elem_index].flip
      let sims = []
      let stop_sims = []
      Object.keys(this.selectedItems).forEach(index => {
        if (elem_index == index) {
          return null
        }
        let element = this.selectedItems[index]
        if (rotate_degree != element.rotate_degree && flip != element.flip) {
          stop_sims.push(index)
        } else if ((x + 0.20) >= element.x && (x - 0.20) <= element.x) {
          sims.push(index);
        } else if ((y + 0.20) >= element.y && (y - 0.20) <= element.y) {
          sims.push(index);
        } else if ((x_end + 0.20) >= (element.x + element.size) && (x_end - 0.20) <= (element.x + element.size)) {
          sims.push(index);
        } else if ((y_end + 0.20) >= (element.y + element.size) && (y_end - 0.20) <= (element.y + element.size)) {
          sims.push(index);
        } else if ((x_mid + 0.20) >= (element.x + element.size/2) && (x_mid - 0.20) <= (element.x + element.size/2)) {
          sims.push(index);
        } else if ((y_mid + 0.20) >= (element.y + element.size/2) && (y_mid - 0.20) <= (element.y + element.size/2)) {
          sims.push(index);
        } else {
          stop_sims.push(index)
        }
      });
      this.lightItem(sims);
      this.stopLightItem(stop_sims);
    },
    lightItem(item_ids) {
      item_ids.forEach(index => {
        let srcNewDiv = document.getElementById(`item-image-${index}`);
        srcNewDiv.style.backgroundColor = "#FFE9E4";
      })
    },
    stopLightItem(item_ids){
      item_ids.forEach(index => {
        let srcNewDiv = document.getElementById(`item-image-${index}`);
        srcNewDiv.style.backgroundColor = "";
      })
    },
    stopAllLightItem() {
      let srcNewDivs = document.getElementsByClassName('item-image');
      if (srcNewDivs) {
        Array.from(srcNewDivs).forEach(item => {
          item.style.backgroundColor = "";
        })        
      }
    },
    setBackroundSize(size) {
      let width = size[0];
      let height = size[1];
      this.backgroundSize  = 'cover'
      document.getElementById('app-current-preview-canvas').style.backgroundSize = 'auto';
      this.backgroundSizeX = width
      this.backgroundSizeY = height
      if (width > 800 || height > 800) {
        this.backgroundSizeReplaceX = width > height ? 800 : Math.round(800 * width / height);
        this.backgroundSizeReplaceY = width > height ? Math.round(800 * height / width) : 800;
      } else {
        this.backgroundSizeReplaceX = width;
        this.backgroundSizeReplaceY = height;
      }
    },
    selectCostume(val, history = true) {
      if (history) { this.saveToHistory('selectCostume', 'selectCostume', val, this.selectedCostume) }
      this.selectedCostume = val
    },
    hideCostumlist() {
      $(".app-header-menu").hide();
      $(".toogle-costume-list--hide").hide();
      $(".toogle-costume-list--show").show();
    },
    showCostumlist() {
      $(".app-header-menu").show();
      $(".toogle-costume-list--hide").show();
      $(".toogle-costume-list--show").hide();
    },
    massUpload() {
      let ref = this
      // this.preview = URL.createObjectURL(this.uploadedItemsFile);
      let js_zip = new JSZip();
      js_zip.loadAsync(this.uploadedItemsFile)
      .then(function(zip) {
        for (const [fileName, file] of Object.entries(zip.files)) {
          if (!file.dir) {
            let fileNameArr = fileName.split('/')
            if (fileNameArr[1] == 'costumes') {
              ref.uploadCostume(fileNameArr, file);
            } else if (fileNameArr[1] == 'features') {
              ref.uploadItem(fileNameArr, file);
            } else if (fileNameArr[1] == 'materials') {
              ref.uploadMaterials(fileNameArr, file)
            }
          }
        }
          // you now have every files contained in the loaded zip
          // zip.file("hello.txt").async("string"); // a promise of "Hello World\n"
      });
      this.showMassUploadDialog = false;
    },
    uploadCostume(fileNameArr, file) {
      this.$refs.childCostumeList.addMassItem(fileNameArr, file);
    },
    uploadItem(fileNameArr, file) {
      this.$refs.childItemList.addMassItem(fileNameArr, file);
    },
    uploadMaterials(fileNameArr, file) {
      this.$refs.childMaterialList.addMassMaterial(fileNameArr, file);
    },
    openMassUpload() {
      this.showMassUploadDialog = true;
    },
    openAI() {
      this.showAIDialog = true;
    },
    historyForward() {
      let history         = JSON.parse(localStorage.getItem('history'));
      if (this.historyIndex <= 0) {
        this.historyIndex = 0
      } else {
        this.historyIndex = this.historyIndex - 1
        let forward_history = history[history.length - 1 - this.historyIndex]
        let value = forward_history['operation_v']
        if (forward_history['operation'] == 'zoomMinus') {
          this.zoomMinus(false);
        } else if (forward_history['operation'] == 'downZIndex') {
          let img_index  = forward_history['operation_v']['img_index']
          let srcNewDiv  = document.getElementById(`item-el-${img_index}`);
          let img_output = document.querySelector('div.output img');
          img_output.after(srcNewDiv);
          Object.keys(this.selectedItems).forEach(index => {
            this.selectedItems[index].z_index += 1;
          });
          this.selectedItems[img_index].z_index = 1;
        } else if (forward_history['operation'] == 'zoomPlus') {
          this.zoomPlus(false);
        } else if (forward_history['operation'] == 'backgroungSwitchCover') {
          this.backgroungSwitchCover(false)
        } else if (forward_history['operation'] == 'selectCostume') {
          this.selectCostume(value, false)
        } else if (forward_history['operation'] == 'selectItem') {
          this.selectItem(value['val'], value['copyData'], false, value['imgIndex'])
        } else if (forward_history['operation'] == 'removeItem') {
          let img_index = forward_history['operation_v']
          this.$delete(this.selectedItems, img_index)
          $(`#item-el-${img_index}`).remove()
        } else if (forward_history['operation'] == 'moveTo') {
          let data = forward_history['operation_v']
          let item = document.getElementById(`${data['item_id']}`)
          let elem_index = data['elem_index']
          this.selectedItems[elem_index].x = data['x'];
          this.selectedItems[elem_index].y = data['y'];
          this.selectedItems[elem_index].z_index = data['z'];
          item.style.left = data['left'];
          item.style.top  = data['top'];
        } else if (forward_history['operation'] == 'flip') {
          let data               = forward_history['operation_v']
          let img_index          = data['img_index']
          let img2               = document.getElementById(`item-image-${img_index}`);
          let flip               = data['new_flip']
          this.selectedItems[img_index]['flip']      = flip
          this.selectedItems[img_index]['rotate_degree'] = data['new_rotate_degree'];
          img2.style["-webkit-transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
          img2.style["transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`;
        } else if (forward_history['operation'] == 'changeSize') {
          let data = forward_history['operation_v']
          this.chageItemSize(data['img_index'], data['new_size'])
        } else if (forward_history['operation'] == 'changeRotate') {
          let data = forward_history['operation_v']
          let img2 = document.getElementById(`item-image-${data['img_index']}`);
          this.selectedItems[data['img_index']]['rotate_degree'] = data['new_rotate_degree'];
          img2.style["transform"] = data['new_transform'];
        } else if (forward_history['operation'] == 'setBackground') {
          document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${forward_history['operation_v']})`;
          this.backgroundUrl = forward_history['operation_v'];
        }
      }
    },
    chageItemSize(img_index, size) {
      let img2             = document.getElementById(`item-image-${img_index}`);
      this.selectedItems[img_index]['size'] = size;

      let coef = null;
      let h = null;
      let mt = null;
      let w = null;
      let ml = null;
      let obj = this.selectedItems[img_index];
      if (obj.customX) {
        if (obj.customX >= obj.customY) {
          coef = obj.customY / obj.customX
          h = this.sizeStandart * coef * obj.size / 100;
          mt = (this.sizeStandart * obj.size / 100 - h)/ 2
          img2.style["height"] = `${h}px`;
          img2.style["width"]  = `${this.sizeStandart * obj.size / 100}px`;
          img2.style["margin-top"] = `${mt}px`;
          img2.setAttribute('height', `${h}px`);
          img2.setAttribute('width', `${this.sizeStandart * obj.size / 100}px`);
          img2.setAttribute('margin-top', `${mt}px`);
        } else {
          coef = obj.customX / obj.customY
          w = this.sizeStandart * coef * obj.size / 100;
          ml = (this.sizeStandart * obj.size / 100 - w)/ 2

          img2.style["height"] = `${this.sizeStandart * obj.size / 100}px`;
          img2.style["width"]  = `${w}px`;
          img2.style["margin-left"]  = `${ml}px`;
          img2.setAttribute('height', `${this.sizeStandart * obj.size / 100}px`);
          img2.setAttribute('width', `${w}px`);
          img2.setAttribute('margin-top', `${ml}px`);
        }
      } else {
        let new_size = this.sizeStandart * this.selectedItems[img_index]['size'] / 100 + 'px';
        img2.style["height"] = new_size;
        img2.style["width"]  = new_size;
        img2.setAttribute('height', new_size);
        img2.setAttribute('width', new_size);      
    }
    },
    historyBack() {
      let history = JSON.parse(localStorage.getItem('history'));
      if (history.length > (this.historyIndex + 1)) {
        let prev_history  = history[history.length - 1 - this.historyIndex]
        if (prev_history['conter_operation'] == 'zoomMinus') {
          this.zoomMinus(false);
        } else if (prev_history['conter_operation'] == 'returnZIndex') {
          let img_index  = prev_history['conter_operation_v']['img_index']
          let z_index    = prev_history['conter_operation_v']['z_index']
          let prev_id    = prev_history['conter_operation_v']['prev_id']
          let srcNewDiv  = document.getElementById(`item-el-${img_index}`);
          if (prev_id) {
            let prev_el = document.getElementById(`${prev_id}`);
            prev_el.after(srcNewDiv);
          }
          Object.keys(this.selectedItems).forEach(index => {
            this.selectedItems[index].z_index -= 1;
          });
          this.selectedItems[img_index].z_index = z_index;
        } else if (prev_history['conter_operation'] == 'zoomPlus') {
          this.zoomPlus(false);
        } else if (prev_history['conter_operation'] == 'backgroungSwitchCover') {
           this.backgroungSwitchCover(false)
        } else if (prev_history['conter_operation'] == 'selectCostume') {
          let value = prev_history['conter_operation_v']
          this.selectCostume(value, false)
        } else if (prev_history['conter_operation'] == 'deSelectItem') {
          let img_index = prev_history['conter_operation_v']
          this.$delete(this.selectedItems, img_index)
          $(`#item-el-${img_index}`).remove()
          // this.removeItem(this, img_index, plus, minus, remove, copy, flip, rotate_plus, rotate_minus)
        } else if (prev_history['conter_operation'] == 'moveTo') {
          let data = prev_history['conter_operation_v']
          let item = document.getElementById(`${data['item_id']}`)
          let elem_index = data['elem_index']
          this.selectedItems[elem_index].x = data['x'];
          this.selectedItems[elem_index].y = data['y'];
          this.selectedItems[elem_index].z_index = data['z'];
          item.style.left = data['left'];
          item.style.top  = data['top'];
        } else if (prev_history['conter_operation'] == 'flip') {
          let data               = prev_history['conter_operation_v']
          let img_index          = data['img_index']
          let img2               = document.getElementById(`item-image-${img_index}`);
          let flip               = data['prev_flip']
          this.selectedItems[img_index]['flip']          = flip
          this.selectedItems[img_index]['rotate_degree'] = data['prev_rotate_degree'];
          img2.style["-webkit-transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
          img2.style["transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`;
        } else if (prev_history['conter_operation'] == 'changeSize') {
          let data = prev_history['conter_operation_v']
          this.chageItemSize(data['img_index'], data['prev_size'])
        } else if (prev_history['conter_operation'] == 'changeRotate') {
          let data = prev_history['conter_operation_v']
          let img2 = document.getElementById(`item-image-${data['img_index']}`);
          this.selectedItems[data['img_index']]['rotate_degree'] = data['prev_rotate_degree'];
          img2.style["transform"] = data['prev_transform'];
        } else if (prev_history['conter_operation'] == 'returnItem') {
          let data = prev_history['conter_operation_v']
          let img_index = data['img_index'];
          let new_item  = data['prev_item']          
          this.selectedItems[img_index] = new_item;
          let img        = document.createElement("img");
          img.className  = "item-image";
          img.id         = `item-image-${img_index}`;
          img.dataset['index'] = img_index;
          let x_position = this.sizeStandart * new_item.x / 100;
          let y_position = this.sizeStandart * new_item.y / 100;
          let src        = document.getElementById("app-current-preview-canvas");
          var newDiv     = document.createElement("div");
          newDiv.id      = `item-el-${img_index}`;
          newDiv.className = 'item-el'
          newDiv.setAttribute("style", `position: absolute; left:${x_position}px; top:${y_position}px; width: 100%;`);
          src.appendChild(newDiv);
          img.setAttribute("style", `padding: 0px; position: absolute; height: ${this.sizeStandart * new_item.size / 100}px; width: ${this.sizeStandart * new_item.size / 100}px;`);
          img.onmouseover = function(event) {
            let target = event.target;
            target.style.padding = '0px';
            target.style.border = '1px dotted grey';
          };
          img.onmouseout = function(event) {
            let target = event.target;
            target.style.padding = '1px';
            target.style.border = 'none';
          };
          img.src = new_item.img.url;
          this.add_buttons_to_item(img_index, img)
        } else if (prev_history['conter_operation'] == 'setBackground') {
          document.getElementById('app-current-preview-canvas').style["background-image"] = `url(${prev_history['conter_operation_v']})`;
          this.backgroundUrl = prev_history['conter_operation_v']
        }
        this.historyIndex = this.historyIndex + 1
      } else {
        this.historyIndex = history.length - 1
      }
    },
    onScrolX() {
      window.addEventListener('scroll', () => {
        if (window.scrollX > 15) {
          // $('.app-middle-menu').css({ "background-color" : "transparent" });
          $('.app-middle-menu').css({ "background-color" : "transparent", "border-right": "dotted" });
          $('.app-middle-menu__btn').not('.app-middle-menu__btn--active').css({ "background-color" : "#474747" });
        } else if (!this.enableDrawFigures) {
          // $('.app-middle-menu').css({ "background-color" : "#474747" });
          $('.app-middle-menu').removeAttr("style");
          $('.app-middle-menu_btn.app-middle-menu__btn--active').removeAttr("style");
          // $('.app-middle-menu .app-middle-menu__btn--active').removeAttr("style");
          // $('.app-middle-menu__btn').not('.app-middle-menu__btn--active').css({ "background-color" : "#474747" });
          // $('.app-middle-menu__btn').css({ "background-color" : "transparent" });
        }
      })
    },
    saveToHistory(operation, conter_operation, operation_v = null, conter_operation_v = null) {
      if (operation == 'moveTo' && conter_operation == 'moveTo' && conter_operation_v['x'] == operation_v['x'] && conter_operation_v['y'] == operation_v['y']) {
        return
      }
      let history = JSON.parse(localStorage.getItem('history'));
      if (this.historyIndex > 0) {
        history.splice(history.length - this.historyIndex)
        this.historyIndex = 0;
      }
      if (history.length > 100) {
        history.shift()
      }
      if (history.length > 0 && (history[history.length - 1]['operation'] == 'moveTo' && operation == 'moveTo' ||  history[history.length - 1]['operation'] == 'changeRotate' && operation == 'changeRotate') && this.historyIndex == 0) {
        history[history.length - 1]['operation_v'] = operation_v
      } else {
        history.push({ 'operation': operation, 'conter_operation': conter_operation, 'operation_v': operation_v, 'conter_operation_v': conter_operation_v })   
      }
      if (operation === 'setBackground') {
        this.backgroundUrl = operation_v;
      }
      localStorage.setItem('history', JSON.stringify(history));
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
    sizeStandartPx() {
      return this.sizeStandart + 'px';
    },
    blockHistoryForward() {
      return this.historyIndex == 0
    },
    blockHistoryBack() {
      // let history = JSON.parse(localStorage.getItem('history'));
      return this.historyIndex >= 99
    },
  },
  mounted() {
    $( ".first-invitation" ).remove();
    localStorage.setItem('history', JSON.stringify([]));
    document.querySelector('html').style.backgroundColor = '#363636';
    document.querySelector('body').style.backgroundColor = '#363636';
    let dateToday = new Date(Date.now()).toLocaleString().split(',')[0]
    this.countAI = Number(localStorage.getItem('ND' + dateToday));
    this.onScrolX();
  }
};
</script>
<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .application {
    user-select: none;
    color: $application-text-color;
    display: flex;
    flex-flow: row;
    background-color: $application-main-color;
    width: 100%;
  }
  .application-main {
    width: 100%;
    min-width: 1240px;
    background-color: $application-main-color;
  }
  .application-adv {
    width: 242px;
    min-width: 200px;
    background-color: $application-main-color;
  }
  .application {
    #app-current-preview-canvas {
      position: relative;
      margin-top: 15px;
      border: 0px;
      background-repeat: repeat;
    }
  }
  #generate-style {
    margin: 36px auto 0px auto;
  }
  #current-canvas {
    display: none;
    position: absolute;
    bottom: -2400px;
    z-index: 2000;
    background: green;
  }
  .download-canvas {
    margin-left: 830px;
    padding: 15px 0px 0px 15px;
    background-color: white;
    height: 115px;
    min-width: 400px;
  }
  .application {
    .toolbar-row-left {
      width: 100%;
      display: inline-flex;
    }
    .toogle-costume-list--show {
      display: none;
      position:absolute;
      top: 0px;
      cursor:pointer;
      left: 105px;
      font-size: 10px;
      padding: 0px 10px;
      background-color: $application-main-color;
      z-index: 100;
      height: 15px;
    }
    .toogle-costume-list--show:hover {
      background-color: $application-border-color;
    }
    .toogle-costume-list--hide {
      border-left: 3px solid $application-border-color;
      border-right: 3px solid $application-border-color;
      border-top: 3px solid $application-border-color;
      border-radius: 3px;
      padding: 0px 10px;
      letter-spacing: 2px;
      position:absolute;
      top: 194px;
      left: 105px;
      cursor:pointer;
      display: block;
      font-size: 10px;
    }
    .toogle-costume-list--hide:hover {
      background-color: $application-border-color;
    }
    .app-preview-canvas {
      margin-right: 0px;
    }
    .download-canvas {
      margin-left: 15px;
      width: 400px;
      padding: 0px;
      border-left: solid 15px $application-border-color !important;
      background-color: $application-main-color;
    }
    .send-to__button, .download-canvas__button {
      background-color: $application-main-color !important;
    }
    .send-to__button:hover, .download-canvas__button:hover {
      background-color: $application-border-color !important;
    }
    .send-to__button:active, .download-canvas__button:active {
      background-color: $application-border-color !important;
      background-blend-mode: overlay;
    }
    .toolbar-row, .canvas-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .canvas-row {
      background-color: $application-border-color;
    }
    .app-middle-menu {
      margin: 15px 0px;
      width: 40px;
      height: 785px;
      background-color: $application-main-color;
      border-radius: 3px;
      position:-webkit-sticky;
      position: sticky;
      left: 0px;
      z-index:200000;
    }
    .app-low-menu {
      width: 40px;
      height: 115px;
    }
  }
  .download-canvas__button {
    height: 75px;
    width: 100%;
    background-color: $application-main-color;
    background-blend-mode: hue;
    border: 0px;
    cursor: pointer;
    text-shadow: none;
    font-weight: normal;
    margin: 0px !important;
    background-color: lightskyblue !important;
    color: rgba(255,255,255,0.87) !important;
    letter-spacing: 0.10em;
    text-shadow: #000 0 1px 0;
    font-weight: 500;
  }
  .download-canvas--thin .download-canvas__button{
    height: 38px;
  }
  .send-to__button {
    width: 100%;
    height: 37px;
    background-color: $application-main-color;
    background-blend-mode: hue;
    border: 0px;
    cursor: pointer;
    text-shadow: none;
    font-weight: normal;
    margin: 0px !important;
    background-color: lightskyblue !important;
    color: rgba(255,255,255,0.87) !important;
    letter-spacing: 0.10em;
    text-shadow: #000 0 1px 0;
    font-weight: 500;
  }
  .send-to__button:hover {
    background-color: lightcoral;
  }
  .send-to__button:active {
    background-color: lightcoral;
    background-blend-mode: overlay;
  }
  .download-canvas__button:hover {
    background-color: lightcoral;
  }
  .download-canvas__button:active {
    background-color: lightcoral;
    background-blend-mode: overlay;
  }
  .item-image {
    cursor: pointer;
  }
  .application {
    #img-for-canvas {
      display: none;
    }
  }
  .first-screen {
    min-width: 1230px;
  }
  #simple-toolbar--negative {
    background-color: $additional-color;
    width: 50%;
    margin: 15px 0px;
    padding: 15px;
  }
  #simple-toolbar--positive {
    background-color: lightblue;
    width: 50%;
    margin: 15px 0px;
    padding: 15px;
  }
  #simple-toolbar {
    display: inline-flex;
    width: 100%;
  }
  .app-middle-menu__info {
    width: 30px;
    height: 30px;
    border: 1px solid $application-border-color;
    border-radius: 2px;
    margin: 5px;
    padding: 2px;    
  }
  .app-middle-menu__btn {
    width: 30px;
    height: 30px;
    border: 1px solid $application-border-color;
    border-radius: 2px;
    margin: 5px;
    cursor: pointer;
    position: relative;
  }
  :not(.app-middle-menu__btn--disabled).app-middle-menu__btn:hover {
    background-color: $application-border-color;
    cursor: pointer;
  }
  :not(.app-middle-menu__btn--disabled).app-middle-menu__btn:active {
    background-color: $application-main-color;
  }
  .app-middle-menu__btn--active {
    background-color: black;
    cursor: pointer;
  }
  .app-middle-menu__btn--disabled {
    cursor: default;
  }
  .app-middle-menu__btn--img {
    width: 100%;
    height: 100%;
    user-select: none;
  }
  .binance-adv-img {
    height: 33.33%;
  }
  .format-line, .quality-line {
    display: flex;
  }
  .format-line__title, .quality-line__title, .format-line__item, .quality-line__item {
    width: 25%;
    padding: 0 10px;
    height: 20px;
    font-size: 14px;
  }
  .format-line__title, .quality-line__title {
    text-align: right;
  }
  .format-line__item, .quality-line__item {
    text-align: center;
    cursor: pointer;
  }
  .format-line__item:hover, .quality-line__item:hover {
    background-color: $application-border-color;
    opacity: 0.75;
  }
  .format-line__item--active, .quality-line__item--active, .format-line__item--active:hover, .quality-line__item--active:hover {
    background-color: $application-border-color;
    opacity: 1;
  }
  .md-content {
    padding: 1px 24px;
  }
  .canvas-wrapper {
    position: relative;
  }
  #VueDrawingCanvas, .output {
    position: absolute;
    z-index: 1;
    margin-top: 15px;
    width: 800px;
    height: 800px;
  }
  #VueDrawingCanvas {
    z-index: 2000;
    bottom: 2400px;
  }
  .btn_to_consturct {
    display: none;
  }
  .draw-menu {
    display: none;
    font-size: 14px;
  }
  .draw-menu select, .draw-menu input{
    width: 80px;
  }
  .draw-font {
    margin-top: 5px;
  }
  .app-middle-menu__text-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }
  .app-middle-menu__text-btn--red {
    color:red;
  }

@media only screen and (max-width: 481px) {
  .md-dialog {
    z-index: 1000000;
    position: fixed;
    left: 0px;
    width: 481px;
  }
}
</style>