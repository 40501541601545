<template lang="pug">
div
  .first-screen
    invitation
  //- .first-screen
  //-   video-tutorial
  //- .advs-square
  //-   google-ad-sense(slotid='5019256372' :timeout='200' classnames='page-top')
  .first-screen
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    md-app#simple-toolbar
      md-app-toolbar#simple-toolbar--negative(class="md-primary")
        span.md-title
          | Do you novice designer or sales manager of garment factory?
      md-app-toolbar#simple-toolbar--positive(class="md-primary")
        span.md-title
          | Clothes designing software helps you with practice, prototype creation etc.
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    md-app#simple-toolbar
      md-app-toolbar#simple-toolbar--negative(class="md-primary")
        span.md-title
          | You don't have to use only our elements
      md-app-toolbar#simple-toolbar--positive(class="md-primary")
        span.md-title
          | You can upload your costumes, details and fabrics
  collection-example
  google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
  md-app#simple-toolbar
    md-app-toolbar#simple-toolbar--negative(class="md-primary")
      span.md-title
        | We add new models regularly
    md-app-toolbar#simple-toolbar--positive(class="md-primary")
      span.md-title
        | Send us what suits to add
  video-for-you
  hr
  blog-part
  google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
  .mb-1
  div
    b-card(
      overlay=""
      img-src="/education_imgs/characteristics_clothing.webp"
      img-alt="Seam allowances"
      text-variant="white"
      title="Seam allowances"
      sub-title="VS Ease allowance"
    )
      b-card-text
        | added to the details of the pattern along all contours for assembling and processing the seams of the product
      b-button(href="/seam_allowances" variant="outline-primary")
        | Read
  .mb-1
  google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
  .mb-1
  div
    b-card(
      overlay=""
      img-src="/education_imgs/allowances.webp"
      img-alt="Ease allowance"
      text-variant="white"
      title="Ease allowance classification"
      sub-title="Allowances for the freedom of fitting"
    )
      b-card-text
        | Ease allowance (allowances for the freedom of fitting) classification.
      b-button(href="/ease_allowance" variant="outline-primary")
        | Read
  .mb-1
</template>

<script>
  import GoogleAdSense     from "../../components/GoogleAdSense"
  import CostumeList       from "../../components/CostumeList"
  import PreviewCanvas     from "../../components/PreviewCanvas"
  import ItemTypesList     from "../../components/ItemTypesList"
  import SelectedItemsList from "../../components/SelectedItemsList"
  import Toolbar           from "../../components/Toolbar"
  // import VideoTutorial     from "../../components/VideoTutorial"
  import Invitation        from "../../components/Invitation"
  import CollectionExample from "../../components/CollectionExample"
  import Quote             from "../../components/Quote"
  import VideoForYou       from "../../components/VideoForYou"
  import BlogPart from "@/components/Blog/BlogPart"

export default {

  components: {
    GoogleAdSense,
    CostumeList,
    PreviewCanvas,
    ItemTypesList,
    SelectedItemsList,
    Toolbar,
    // VideoTutorial,
    Invitation,
    CollectionExample,
    Quote,
    VideoForYou,
    BlogPart
  },
  metaInfo: {
    title: "Free online clothing design software",
    meta: [
      {
        name: 'keywords',
        content: 'design software, clothing design software, desing app, design program, fashion application, custom design, jacket, overalls, pocket, clothes, semi-overalls, trousers, windbreaker, fabric, material, clothes designing software, dress design, skirt design, novice designer, fashion designer course, free design course, fashion designer, course of fashion design, tailor course'
      },
      {
        name: 'description',
        content: "Free software for design clothes online and Online fashion designer course. Unlimited designs by clothing design app. Design custom jackets, overalls, trousers, skirts, dresses etc."
      }
    ]
  },
  data() {
    return {
      selectedItems: {},
      selectedCostume: {},
      sizeStandart: 800,
      itemIndex: 0,
      canvas: null,
      changeIndex: 0,
      buttonSize: 24,
      comment: ''
    };
  },

  computed: {
  },

  methods: {
    generateCanvas() {
      this.$forceUpdate();
      this.add_background_to_canvas();
      var ref = this
      setTimeout(()=>{ ref.BEFOREdrawInlineSVGbase() }, 1000);
      setTimeout(()=>{ ref.downloadCanvas() }, 2000);
    },
    downloadCanvas() {
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      this.$forceUpdate();
      let items_ordered = this.sortProperties(this.selectedItems, 'z_index', true, false)
      this.$forceUpdate();
      for (var key in items_ordered) {
        this.add_item_to_canvas(items_ordered[key][1]);
      }
      this.add_comment_to_canvas();
      this.$forceUpdate();
      let link = document.createElement('a');
      link.download = 'costume.png';
      link.href = canvas.toDataURL();
      link.click();
    },
    sortProperties(obj, sortedBy, isNumericSort, reverse) {
      sortedBy = sortedBy || 1; // by default first key
      isNumericSort = isNumericSort || false; // by default text sort
      reverse = reverse || false; // by default no reverse

      var reversed = (reverse) ? -1 : 1;

      var sortable = [];
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              sortable.push([key, obj[key]]);
          }
      }
      if (isNumericSort)
          sortable.sort(function (a, b) {
              return reversed * (a[1][sortedBy] - b[1][sortedBy]);
          });
      else
          sortable.sort(function (a, b) {
              var x = a[1][sortedBy].toLowerCase(),
                  y = b[1][sortedBy].toLowerCase();
              return x < y ? reversed * -1 : x > y ? reversed : 0;
          });
      return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    },
    BEFOREdrawInlineSVGbase(){
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      let costume_url = $('#selected-costume-image')[0].src;
      let base_image = new Image();
      base_image.src = costume_url;
      context.drawImage(base_image, 0, 0, 800, 800);
    },
    add_background_to_canvas() {
      let canvas = document.getElementById("current-canvas");
      let context = canvas.getContext('2d');
      let bg_url = $('#app-current-preview-canvas').css('background-image');
      bg_url = bg_url.replace('url(','').replace(')','').replace(/\"/gi, "");
      let base_image = new Image();
      base_image.src = bg_url;
      var ptrn = context.createPattern(base_image, 'repeat');
      context.fillStyle = ptrn;
      context.fillRect(0, 0, canvas.width, canvas.height);
    },
    add_comment_to_canvas(context) {
      let canvas = document.getElementById("current-canvas");
      var context = canvas.getContext('2d');
      context.font = "14px verdana, sans-serif";
      context.fillStyle = "#000000";
      context.fillText(this.comment, 15, 750);
    },
    add_item_to_canvas(item) {
      let canvas = document.getElementById("current-canvas");
      let base_image = new Image();
      base_image.src = item.img.url;
      if (item.flip) {
        base_image.style="filter:FlipH"
      }
      let base_width  = base_image.width;
      let base_height = base_image.height;
      let size_x;
      let size_y;
      let item_size = this.sizeStandart * item.size / 100
      if (base_width > base_height) {
        size_x = item_size;
        size_y = item_size * (base_height / base_width);
      } else {
        size_x = item_size * (base_width / base_height);
        size_y = item_size;
      }
      let shift_x = (item_size - size_x) / 2;
      let shift_y = (item_size - size_y) / 2;
      let position_x = this.sizeStandart * item.x / 100 + shift_x;
      let position_y = this.sizeStandart * item.y / 100 + shift_y;
      // new rotate and flip
      this.drawRotatedImage(canvas, base_image, item.rotate_degree, item.flip, position_x, position_y, size_x, size_y, item_size)  
    },
    drawRotatedImage(canvas, base_image, angle, mirrorImage, position_x, position_y, size_x, size_y, item_size) {
      var context = canvas.getContext('2d');
        context.save();
      if (angle > 0 || angle < 0) {
          context.translate((position_x + size_x / 2), (position_y + size_y / 2));
          context.rotate(angle * (mirrorImage ? (-1) : 1) * (Math.PI / 180));
          context.translate((-position_x - size_x / 2), (-position_y - size_y / 2));
          // context.restore();
      }
      if (mirrorImage === true) {
          context.scale(-1, 1);
          context.drawImage(base_image, (position_x + size_x) * (-1), position_y, size_x, size_y);
      } else {
        context.drawImage(base_image, position_x, position_y, size_x, size_y);
      }
      context.restore();
    },
    selectItem(val, copyData = null) {
      if (Object.keys(this.selectedCostume).length === 0) {
        return
      }
      let img_index = `${this.itemIndex}`;
      let obj = { x: 50, y: 50, size: val['default_size']}
      let new_item = Object.assign(obj, val)
      this.selectedItems[img_index] = new_item
      if (copyData) {
        console.log('copyData')
        this.selectedItems[img_index]['rotate_degree'] = copyData['rotate_degree'] ? copyData['rotate_degree'] : 0;
        this.selectedItems[img_index]['size'] = copyData['size'];
        this.selectedItems[img_index]['flip'] = !!copyData['flip'];
      }
      let ref = this
      // insert new item
      let img        = document.createElement("img");
      img.className  = "item-image";
      img.id         = `item-image-${img_index}`;
      img.dataset['index'] = img_index;
      let half_size  = this.sizeStandart * new_item.size / 100 / 2
      let x_position = this.sizeStandart * obj.x / 100 - half_size;
      let y_position = this.sizeStandart * obj.y / 100 - half_size;
      let src        = document.getElementById("app-current-preview-canvas");
      var newDiv     = document.createElement("div");
      newDiv.id      = `item-el-${img_index}`;
      newDiv.setAttribute("style", `position: absolute; left:${x_position}px; top:${y_position}px; width: 100%;`);
      src.appendChild(newDiv);
      console.log('srcNewDiv')
      console.log(img_index)
      img.setAttribute("style", `position: absolute; height: ${this.sizeStandart * obj.size / 100}px; width: ${this.sizeStandart * obj.size / 100}px;`);
      img.src = new_item.img.url;
      this.selectedItems[img_index]['x'] = (x_position / this.sizeStandart * 100);
      this.selectedItems[img_index]['y'] = (y_position / this.sizeStandart * 100);
      this.selectedItems[img_index]['z_index'] = this.changeIndex;
      this.changeIndex += 1;
      if (copyData) {
        img.style["-webkit-transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
        img.style["transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${ref.selectedItems[img_index]['rotate_degree']}deg)`;
      } else {
        this.selectedItems[img_index]['rotate_degree'] = 0;
      }

      let srcNewDiv = document.getElementById(`item-el-${img_index}`);
      // add tools
      let copy = document.createElement('div');
      copy.className = 'zoom-copy'
      copy.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; left: 0px; top: 25px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; user-select: none;`)
      copy.textContent = 'C'
      srcNewDiv.appendChild(copy);
      let plus = document.createElement('div');
      plus.className = 'zoom-plus'
      plus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; left: 25px; top: 25px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; user-select: none;`)
      plus.textContent = '+'
      srcNewDiv.appendChild(plus);

      let minus = document.createElement('div');
      minus.className = 'zoom-minus'
      minus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; left: 25px; top: 50px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; user-select: none;`)
      minus.textContent = '-'
      srcNewDiv.appendChild(minus);

      let remove = document.createElement('div');
      remove.className = 'item-remove'
      remove.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; left: 50px; user-select: none;`)
      remove.textContent = 'X'
      srcNewDiv.appendChild(remove);

      let flip = document.createElement('div');
      flip.className = 'item-flip'
      flip.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; top: 50px; user-select: none;`)
      flip.textContent = 'F'
      srcNewDiv.appendChild(flip);

      let rotate_plus = document.createElement('div');
      rotate_plus.className = 'item-rotate-plus'
      rotate_plus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; top: 25px; left: 50px; user-select: none;`)
      rotate_plus.textContent = 'R+'
      srcNewDiv.appendChild(rotate_plus);

      let rotate_minus = document.createElement('div');
      rotate_minus.className = 'item-rotate-minus'
      rotate_minus.setAttribute("style", `position: absolute; background-color: rgba(117, 190, 218, 0.5); font-weight: 900; width: 24px; height: 24px; text-align: center; font-color: white; display: none; z-index: 10; cursor: pointer; top: 50px; left: 50px; user-select: none;`)
      rotate_minus.textContent = 'R-'
      srcNewDiv.appendChild(rotate_minus);

      srcNewDiv.appendChild(img); // after tools because need find closest element in up dom

      var item = document.getElementById(img.id);
      this.addDragDrop(srcNewDiv);
      this.itemIndex += 1;

      srcNewDiv.addEventListener("mouseenter", function( event ) {
        copy.style.display="block";
        plus.style.display="block";
        minus.style.display="block";
        remove.style.display="block";
        flip.style.display="block";
        rotate_plus.style.display="block";
        rotate_minus.style.display="block";
      });
      srcNewDiv.addEventListener("mouseleave", function( event ) {
        copy.style.display="none";
        plus.style.display="none";
        minus.style.display="none";
        remove.style.display="none";
        flip.style.display="none";
        rotate_plus.style.display="none";
        rotate_minus.style.display="none";
      });
      copy.addEventListener("mousedown", function( event ) {
        ref.selectItem(val, ref.selectedItems[img_index])
      });
      plus.addEventListener("mousedown", function( event ) {
        if (ref.selectedItems[img_index]['size'] < 20) {
          ref.selectedItems[img_index]['size'] += 1;
          let img2 = document.getElementById(`item-image-${img_index}`);
          let new_size = ref.sizeStandart * ref.selectedItems[img_index]['size'] / 100 + 'px';
          img2.style["height"] = new_size;
          img2.style["width"]  = new_size;
          img2.setAttribute('height', new_size);
          img2.setAttribute('width', new_size);
        }
      });
      minus.addEventListener("mousedown", function( event ) {
        if (ref.selectedItems[img_index]['size'] > 3) {
          ref.selectedItems[img_index]['size'] -= 1;
          let img2 = document.getElementById(`item-image-${img_index}`);
          let new_size = ref.sizeStandart * ref.selectedItems[img_index]['size'] / 100 + 'px';
          img2.style["height"] = new_size;
          img2.style["width"]  = new_size;
          img2.setAttribute('height', new_size);
          img2.setAttribute('width', new_size);
        }
      });
      remove.addEventListener("mousedown", function( event ) {
        ref.$delete(ref.selectedItems, img_index)
        document.getElementById(`item-image-${img_index}`).remove();
        plus.remove();
        minus.remove();
        remove.remove();
        copy.remove();
        flip.remove();
        rotate_plus.remove();
        rotate_minus.remove();
      });
      flip.addEventListener("mousedown", function( event ) {
        let img2 = document.getElementById(`item-image-${img_index}`);
        ref.selectedItems[img_index]['flip'] = !ref.selectedItems[img_index]['flip']
        ref.selectedItems[img_index]['rotate_degree'] = ref.selectedItems[img_index]['rotate_degree']  * (-1);
        img2.style["-webkit-transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'}`
        img2.style["transform"] = `${ref.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${ref.selectedItems[img_index]['rotate_degree']}deg)`;
      });
      rotate_plus.addEventListener("mousedown", function( event ) {
        ref.rotate_plus_keep(img_index);
      });
      rotate_plus.addEventListener("mouseup", function( event ) {
        clearInterval(ref.rotateInterval);
      });
      rotate_minus.addEventListener("mousedown", function( event ) {
        ref.rotate_minus_keep(img_index);
      });
      rotate_minus.addEventListener("mouseup", function( event ) {
        clearInterval(ref.rotateInterval);
      });
    },
    rotate_plus_keep: function(img_index){
      console.log('prekuku')
      this.rotateInterval = setInterval(() => {
        let img2 = document.getElementById(`item-image-${img_index}`);
        this.selectedItems[img_index]['rotate_degree'] += 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1);
        img2.style["transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`;
      }, 100)
    },
    rotate_minus_keep: function(img_index){
      console.log('prekuku')
      this.rotateInterval = setInterval(() => {
        let img2 = document.getElementById(`item-image-${img_index}`);
        this.selectedItems[img_index]['rotate_degree'] -= 3 * (this.selectedItems[img_index]['flip'] ? (-1) : 1);
        img2.style["transform"] = `${this.selectedItems[img_index]['flip'] ? 'scaleX(-1)' : 'scaleX(1)'} rotate(${this.selectedItems[img_index]['rotate_degree']}deg)`;
      }, 100)
    },
    addDragDrop(item){
      item.ondragstart = function() {
        return false;
      };
      var ref = this
      item.onmousedown = function(e) { // 1. отследить нажатие
        ref.changeIndex += 1;
        let src = document.getElementById("app-current-preview-canvas");
        let coords = getCoords(item);
        let shiftX = e.pageX - coords.left;
        let shiftY = e.pageY - coords.top;

        // подготовить к перемещению
        // 2. разместить на том же месте, но в абсолютных координатах
        item.style.position = 'absolute';
        
        // переместим в body, чтобы мяч был точно не внутри position:relative
        src.appendChild(item);
        moveAt(e);
        item.style.zIndex = 1000; // показывать мяч над другими элементами

        // передвинуть мяч под координаты курсора
        // и сдвинуть на половину ширины/высоты для центрирования
        function moveAt(e) {
          // let elem_index  = e.toElement.dataset.index;
          let elem_index  = item.getElementsByClassName('item-image')[0].dataset.index;
          if (ref.selectedItems[elem_index]) {
            let src_coords = getCoords(src);
            item.style.left = (e.pageX - shiftX - src_coords.left) + 'px';
            item.style.top  = (e.pageY - shiftY - src_coords.top) + 'px';
            ref.selectedItems[elem_index].x = (e.pageX - shiftX - src_coords.left) / ref.sizeStandart * 100;
            ref.selectedItems[elem_index].y = (e.pageY - shiftY - src_coords.top) / ref.sizeStandart * 100;
            ref.selectedItems[elem_index].z_index = ref.changeIndex;
          }
        }
        function getCoords(elem) {   // кроме IE8-
          var box = elem.getBoundingClientRect();
          return {
            top: box.top + pageYOffset,
            left: box.left + pageXOffset
          };
        }
        // 3, перемещать по экрану
        src.onmousemove = function(e) {
          moveAt(e);
        }

        // 4. отследить окончание переноса
        item.onmouseup = function() {
          src.onmousemove = null;
          item.onmouseup = null;
        }
      }
    },
    selectCostume(val) {
      this.selectedCostume = val
    }
  },
  computed: {
    sizeStandartPx() {
      return this.sizeStandart + 'px';
    }
  },
  mounted() {
    $( ".first-invitation" ).remove();
    // $( ".secont-invitation" ).css("background-color", "lightskyblue");;
    // $( ".secont-invitation" ).text("Welcome to Nastix Design");
    // $('*').one('blur change click dblclick error focus focusin focusout hover keydown keypress keyup mousedown mouseenter mouseleave mousemove mouseout mouseover mouseup resize scroll select submit', function(action){ // load
    //   $( ".first-invitation" ).fadeOut(300, function(){ $(this).remove();});
      // $( ".secont-invitation" ).fadeOut(300, function(){ $(this).remove();});
    // });
    document.querySelector('html').style.backgroundColor = 'white';
    document.querySelector('body').style.backgroundColor = 'white';
  }
};
</script>

<style lang="scss" scoped>
  @import "../../styles/_variables.scss";
  #app-current-preview-canvas {
    position: absolute;
    margin-top: 15px;
    border: 0px;
  }
  #generate-style {
    margin: 36px auto 0px auto;
  }
  #current-canvas {
    display: none;
    position: absolute;
    bottom: -1600px;
    z-index: 2000;
    background: green;
  }
  .download-canvas {
    margin-left: 830px;
    padding: 15px 0px 0px 15px;
    background-color: white;
    height: 115px;
    min-width: 400px;
  }
  .download-canvas__button {
    height: 100%;
    width: 100%;
    background-color: lightcoral;
    background-blend-mode: hue;
    border: 0px;
    cursor: pointer;
    text-shadow: none;
    font-weight: normal;
    margin: 0px !important;
    background-color: lightskyblue !important;
    color: rgba(255,255,255,0.87) !important;
    letter-spacing: 0.10em;
    text-shadow: #000 0 1px 0;
    font-weight: 500;
  }
  .download-canvas__button:hover {
    background-color: lightcoral;
  }
  .download-canvas__button:active {
    background-color: lightcoral;
    background-blend-mode: overlay;
  }
  .item-image {
    cursor: pointer;
  }
  #img-for-canvas {
    display: block;
  }
  .first-screen {

  }
  #simple-toolbar--negative {
    background-color: $additional-color;
    width: 50%;
    margin: 15px 0px;
    padding: 15px;
  }
  #simple-toolbar--positive {
    background-color: lightblue;
    width: 50%;
    margin: 15px 0px;
    padding: 15px;
  }
  #simple-toolbar {
    display: inline-flex;
    width: 100%;
  }
  .advs-square {
  }
</style>
