<template lang="pug">
div
  app-header
  .app-body
    app-sidebar(:navItems="nav")
    main.main
      b-container(fluid='xl')
        router-view
  app-footer
  //- cookie-law
</template>
<script>

import AppHeader from "./Header/Header";
import AppSidebar from "./Sidebar/Sidebar";
import AppFooter from "./Footer";
// import CookieLaw from 'vue-cookie-law';

export default {
  name: "AppLayout",
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
    // CookieLaw
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Free online clothing design software',
    // all titles will be injected into this template
    titleTemplate: 'Nastix Design | %s'
  },
  data() {
    return {
      nav: ['Nav1', 'Nav2'],
    };
  },

};
</script>
<style scoped>
.app-header, .app-body {
  max-width: 1400px;
  margin: auto;
}
.app-body {
  background: white;
}
@media only screen and (max-width: 1024px) {
  body {

  }
}
@media only screen and (max-width: 480px) {
  body {

  }
}
</style>