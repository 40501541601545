import { render, staticRenderFns } from "./EaseAllowance.vue?vue&type=template&id=40181162&scoped=true&lang=pug&"
import script from "./EaseAllowance.vue?vue&type=script&lang=js&"
export * from "./EaseAllowance.vue?vue&type=script&lang=js&"
import style0 from "./EaseAllowance.vue?vue&type=style&index=0&id=40181162&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40181162",
  null
  
)

export default component.exports