<template lang="pug">
  .blog
    .taking_measurements
      h2
        router-link(to="blog")
          | Blog
        | : 
        | How to Take Body Measurements
      p
        b
          | Remember:
      ul
        li
          | Make sure that the measured stands freely, without much tension. You should not pull the stomach, raise and lower the shoulders, take them back and forth. Hands should be lowered, heels together, socks apart.
        li
          | The greatest accuracy if the person being measured is in underwear or a thin dress.
        li
          | The measuring tape should be applied tightly to the body (not tightening and not relaxing either).
      p
        | Record all body measurement results in a spreadsheet. Example:
      b-table(striped hover :items="table_items_5")
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      h4
        | Main body measurements
      b-row
        b-col
          b-list-group
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | width over bust 
                  b-badge(variant="info")
                    | WOB
              p
                | Over the breasts
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | bust 
                  b-badge(variant="info")
                    | B
              p
                | At the most protruding points of the chest (breast glands)
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | center of chest 
                  b-badge(variant="info")
                    | CC
              p
                | Horizontally along the front between the teat points
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | chest height 
                  b-badge(variant="info")
                    | CH
              p
                | From the point of articulation of the neck with the shoulder to the nipple point
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | neck girth 
                  b-badge(variant="info")
                    | NG
              p
                | From the base of the neck to the jugular notch
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder height oblique front 
                  b-badge(variant="info")
                    | SHOF
              p
                | Along the front from the point of intersection of the mid-front line with the waist line to the extreme point of the shoulder
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder length 
                  b-badge(variant="info")
                    | SL
              p
                | From the base of the neck to the extreme point of the shoulder (the point of articulation of the shoulder with the arm).
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to waist front 
                  b-badge(variant="info")
                    | LWF
              p
                | Along the front from the point of transition of the neck to the shoulder (the base of the neck) through the most protruding point of the chest to the waist
            //- b-list-group-item
            //-   div(class="d-flex w-100 justify-content-between")
            //-     h5(class="mb-1")
            //-       | length to waist front 
            //-       b-badge(variant="info")
            //-         | LWF
            //-   p
            //-     | Along the front from the point of transition of the neck to the shoulder (the base of the neck) through the most protruding point of the chest to the waist
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder height oblique 
                  b-badge(variant="info")
                    | SHO
              p
                | From the point of the spinal column at the waist line to the extreme point of the shoulder
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to back waist 
                  b-badge(variant="info")
                    | LBWL
              p
                | Along the back from the point of articulation of the neck with the shoulder to the waist line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length to back waist second 
                  b-badge(variant="info")
                    | LBW2
              p
                | Along the back from the seventh cervical vertebra to the waist line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | back width 
                  b-badge(variant="info")
                    | BW
              p
                | Along the back horizontally at the level of the shoulder blades before the arms
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | armhole width 
                  b-badge(variant="info")
                    | AW
              p
                | Under the armpit
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | armhole depth 
                  b-badge(variant="info")
                    | AD
              p
                | Depth from the 7th cervical vertebra to the arm
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | length of the sleeve 
                  b-badge(variant="info")
                    | LS
              p
                | From the end point of the shoulder to the wrist along the arm slightly bent at the elbow
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder girth 
                  b-badge(variant="info")
                    | SG
              p
                | At the widest top of the arm
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | inside leg length 
                  b-badge(variant="info")
                    | ILL
              p
                | Along the inside of the leg from the groin to the floor
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | outside leg length 
                  b-badge(variant="info")
                    | OLL
              p
                | From the waistline along the outside of the leg to the floor
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | wrist circumference 
                  b-badge(variant="info")
                    | WC
              p
                | Around the wrist
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | waist 
                  b-badge(variant="info")
                    | W
              p
                | At the narrowest point
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | hip circumference 
                  b-badge(variant="info")
                    | HC
              p
                | At the most convex points of the buttocks
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | hip height 
                  b-badge(variant="info")
                    | HH
              p
                | Along the side from the waist line to the hip line
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | shoulder product length 
                  b-badge(variant="info")
                    | SPL
              p
                | along the back from the 7th cervical vertebra to the desired length of the product
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | belt product length 
                  b-badge(variant="info")
                    | BL
              p
                | Waist to desired length
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | knee height 
                  b-badge(variant="info")
                    | KH
              p
                | From the waist to the center of the knee
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | knee girth
                  b-badge(variant="info")
                    | KG
              p
                | At the center of the knee
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | leg circumference 
                  b-badge(variant="info")
                    | LG
              p
                | Widest part of the leg
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | calf girth 
                  b-badge(variant="info")
                    | CG
              p
                | Along the widest part of the calf
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | ankle girth 
                  b-badge(variant="info")
                    | AG
              p
                | At the narrowest part of the leg above the bone
            b-list-group-item
              div(class="d-flex w-100 justify-content-between")
                h5(class="mb-1")
                  | calf height 
                  b-badge(variant="info")
                    | CH
              p
                | From the center of the patella to the most protruding part of the lower leg
      google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
      .mb-1
      b-row
        b-col
          b-img(src="/education_imgs/model_with_all_lines.png" center="" fluid="" alt="Taking measurements")
    hr
    blog-part
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/characteristics_clothing.webp"
        img-alt="Seam allowances"
        text-variant="white"
        title="Seam allowances"
        sub-title="VS Ease allowance"
      )
        b-card-text
          | added to the details of the pattern along all contours for assembling and processing the seams of the product
        b-button(href="/seam_allowances" variant="outline-primary")
          | Read
    .mb-1
    google-ad-sense(slotid='2746965181' :timeout='200' classnames='page-top')
    .mb-1
    div
      b-card(
        overlay=""
        img-src="/education_imgs/allowances.webp"
        img-alt="Ease allowance"
        text-variant="white"
        title="Ease allowance classification"
        sub-title="Allowances for the freedom of fitting"
      )
        b-card-text
          | Ease allowance (allowances for the freedom of fitting) classification.
        b-button(href="/ease_allowance" variant="outline-primary")
          | Read
    .mb-1
</template>
<script>
  import GoogleAdSense from "./GoogleAdSense"
  import BlogPart from "@/components/Blog/BlogPart"

  export default {
    components: {
      GoogleAdSense,
      BlogPart
    },
    metaInfo: {
      title: "How to Take Body Measurements",
      meta: [
        {
          name: 'keywords',
          content: 'custom design, clothes designing software, dress design, skirt design, novice designer, body measurements, take body measurements, waistline'
        },
        {
          name: 'description',
          content: 'Blog about designing clothes: main body measurements. How to take waistline, knee girth, calf height, etc.'
        }
      ]
    },
    data() {
      return {
        table_items_5: [
          {
            measurement_name: 'waist',
            value: '10',
            half_value: '5',
            third_value_optionally: '3.33',
            quarter_value_optionally: '2.5'
          },
          {
            measurement_name: '...',
            value: '...',
            half_value: '...',
            third_value_optionally: '...',
            quarter_value_optionally: '...'
          },
          {
            measurement_name: '...',
            value: '...',
            half_value: '...',
            third_value: '...',
            third_value_optionally: '...',
            quarter_value_optionally: '...'
          }
        ]
      }
    },
    methods: {
    },
    mounted() {
      $( ".first-invitation" ).remove();
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.blog {
  padding: 15px;
}
</style>