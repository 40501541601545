<template lang="pug">
  .advantages-wrap
    h1.h1.advantages__slogan
      | {{$ml.get('designIn3Steps')}}
    p.advantages__subtile
      | {{$ml.get('designIn3StepsSub')}}
    .btns__row
      button.scroll
        | {{$ml.get('getStarted')}}
      //- button.ai__btn
      //-   | {{$ml.get('tryAIBeta')}}
    .advantages_list
      .advantages
        .advantages__title-cell
          | {{$ml.get('advantages')}}
        .advantages__cell.custom-tooltip
          | {{$ml.get('free')}}
          span.tooltiptext
            | {{$ml.get('advantages1')}}
        .advantages__cell.custom-tooltip
          | {{$ml.get('customCostume')}}
          span.tooltiptext
            | {{$ml.get('advantages2')}}
        .advantages__cell.custom-tooltip
          | {{$ml.get('customFabric')}}
          span.tooltiptext
            | {{$ml.get('advantages3')}}
        .advantages__cell.custom-tooltip
          | {{$ml.get('customDetails')}}
          span.tooltiptext
            | {{$ml.get('advantages4')}}
        .advantages__cell.custom-tooltip
          | {{$ml.get('easyToUse')}}
          span.tooltiptext
            | {{$ml.get('advantages5')}}
</template>
<script>
  export default {
    name: 'quote',
    data() {
      return {
        text: '',
      }
    },
    methods: {
    },
    mounted() {
      $(".scroll").click(function() {
          // $('html, body').animate({
          //     scrollTop: $("#generate-style").offset().top
          // }, 1500);
          window.location.href = './app'
      });
      $(".ai__btn").click(function() {
          // $('html, body').animate({
          //     scrollTop: $("#generate-style").offset().top
          // }, 1500);
          window.location.href = './ai'
      });
    }
  }
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
.advantages-wrap {
  padding-top: 144px;
}
.advantages-wrap button {
  text-transform: uppercase;
}
.scroll, .ai__btn {
  font-weight: normal;
  cursor: pointer;
  color: white;
  margin: auto;
  display: flex;
  background-image: linear-gradient(180deg, $main-color, $main-color) !important;
  border: 2px solid indianred;
  font-size: 1.5rem;
  padding: 6px 12px;
}
.advantages-wrap button.scroll::hover {
  background-image: linear-gradient(180deg, $main-color, lightblue) !important;
}
.advantages {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightblue;
  margin-top: 36px;
}
.advantages_list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantages__title-cell {
  height: 100px;
  width: 19%;
  background-color: lightblue;
  color: white;
  padding: 15px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantages__slogan {
  text-align: center;
  color: white;
  font-size: 2rem !important;
}
.advantages__cell {
  height: 100px;
  width: 20%;
  background-color: lightskyblue;
  padding: 5px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
}
.advantages__subtile {
  max-width: 800px;
  font-size: 1.5rem;
  line-height: 1.25;
  color: white;
  font-weight: 400;
  margin: 15px auto 36px auto;
}
.btns__row {
  display: flex;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .advantages__cell {
    font-size: 16px;
  }
  .advantages__title-cell {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .advantages {
    margin-top: 20px;
    display: block;
  }
  .advantages__cell {
    font-size: 16px;
    width: 100%;
    height: 100%;
  }
  .advantages__title-cell {
    font-size: 16px;
    width: 100%;
    height: 100%;
  }
  .advantages__subtile {
    width: 100%;
    padding: 5px;
  }
}
.btns__row button {
  width: 190px;
  justify-content: center;

}
</style>