<template lang="pug">
  div(class="app-header-menu")
    div.app-header-menu-left
      div(class="app-costume-list")
        .app-costume-list__top-row
          span(:class="(nonselected)? 'blink_me' : ''")  
            | {{$ml.get('selectCostumeType')}}
          span
            input.form-control.form-control-sm.app-costume-list__search-input(v-model="filter_query" :placeholder="$ml.get('searchCostume')" )
        carousel(:perPage="costume_count_per_page" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="0")
          slide(v-for="costume in filteredCostumes" :key="costume.id")
            costume(
              :key="costume.id"
              :costume="costume"
              @select-costume="selectCostume"
          )
            div
        .custome-costume-input
          .custome-costume-input__text
            | {{$ml.get('customCostume')}}
            i.custom-tooltip
              |  ({{$ml.get('requirements')}}) 
              span.tooltiptext
                | ({{$ml.get('requirementsText')}}) (
                a(href="/examples/dress_example_for_custom_layout.png")
                  | {{$ml.get('example')}}
                | )
          .costume-input
            input(name='files' type='file' data-direct-upload-url='/rails/active_storage/direct_uploads' direct_upload='true' id='costume-input__file-input')
    div(class="app-filters")
      | {{$ml.get('costumeFilters')}}
      carousel(:perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="0")
        slide(v-for="category in categories" :key="category.id")
          costume-filter(
            :key="category.id"
            :category="category"
            @select-category="selectCategory"
        )
</template>
<script>
  import costumesApi  from '../api/costumes_api'
  import Costume from "./Costume.vue"
  import CostumeFilter from "./CostumeFilter.vue"
  export default {
    name: 'costume-list',
    components: {
      Costume,
      CostumeFilter,
    },
    props: {
      nonselected: {
        type: Boolean,
        default() {
          return true
        }
      }
    },
    data() {
      return {
        costumes: [],
        filter_query: null,
        categories: [],
        selected_category_items: {},
        costume_count_per_page: 5
      }
    },
    methods: {
      addMassItem(fileNameArr, file) {
        let ref = this;
        file.async("blob").then(function (blob) {
          let file_url = URL.createObjectURL(blob);
          let newCostume = {
            'description': null,
            'name': fileNameArr[2].split('.')[0],
            'img_front' : {
              'preview': {
                'url': file_url
              },
              'url': file_url
            }
          }
          ref.costumes.unshift(newCostume);
        });
      },
      count_per_page_width() {
        this.costume_count_per_page = $(document).width() >= 1600 ? 6 : 5;
      },
      inputCostume() {
        var fileInput = document.getElementById('costume-input__file-input')
        var ref = this
        fileInput.addEventListener('change', function(event) {
          var file = fileInput.files[0];
          var fr = new FileReader();
          fr.readAsDataURL(file);
          setTimeout(()=>{ ref.setCostume(fr.result) }, 2000);
        });
      },
      setCostume(fabric_url = null) {
        if (fabric_url) {
          let val = { description: '', id: '', img_back: null, img_front: { url: fabric_url, name: 'Custom' }}
          this.$emit('select-costume', val);
        }
      },
      fetchCostumes(params = {}) {
        costumesApi
          .fetchAll(params)
          .then(response => {
            this.costumes = response.data.costumes
          })
      },
      fetchCostumeFilters(params = {}) {
        costumesApi
          .fetchAllFilters(params)
          .then(response => {
            this.categories = response.data.categories
          })
      },
      selectCostume(val) {
        this.$emit('select-costume', val);
      },
      selectCategory(val) {
        this.selected_category_items[val[0]] = val[1];
        this.fetchCostumes({categories: this.selected_category_items });
        // this.$emit('select-costume', val);
      }
    },
    computed: {
      filteredCostumes() {
        if (this.filter_query == null || this.filter_query.length <= 1) {
          return this.costumes;
        } else {
          return this.costumes.filter(obj => {
            let name        = obj.name ? obj.name.toLowerCase() : ''
            let description = obj.description ? obj.description.toLowerCase() : ''
            let name_uk = obj.name_uk ? obj.name_uk.toLowerCase() : ''
            let name_es = obj.name_es ? obj.name_es.toLowerCase() : ''
            let description_uk = obj.description_uk ? obj.description_uk.toLowerCase() : ''
            let description_es = obj.description_es ? obj.description_es.toLowerCase() : ''
            return (name + description + name_uk + name_es + description_uk + description_es).includes(this.filter_query.toLowerCase());
          });
        }  
      },
    },
    mounted() {
      this.fetchCostumes();
      this.fetchCostumeFilters();
      this.inputCostume();
      this.count_per_page_width();
      window.addEventListener('resize', () => {
        this.count_per_page_width();
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .app-costume-list__top-row .app-costume-list__search-input {
    margin-left: 20px;
    height: 20px;
  }
  .app-costume-list__top-row {
    display: flex;
  }
  .app-header-menu {
    margin: 0px 0px 0px 0px;
    padding: 15px 20px;
    background-color: indianred;
    min-width: 1230px;
  }
  .main {
    .app-header-menu:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  .app-costume-list {
    width: 785px;
    float: left;
  }
  .app-filters {
    margin-left: 815px;
    padding-right: 30px;
  }
  .custome-costume-input__text {
    margin-right: 5px;
    padding-top: 4px;
  }
  .custome-costume-input {
    display: flex;
  }
  .blink_me {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .app-top-menu {
    visibility: hidden;
  }
  .application {
    .app-header-menu-left {
      width: 100%;
      display: inline-flex;
      margin-left: 40px;
    }
    .top-menu-button {
      width: 90px;
      color: white !important;
      text-decoration: none;
      margin: 0px;
    }
    .top-menu-button.md-button .md-ripple {
      justify-content: left;
    }
    .app-header-menu {
      background-color: $application-main-color;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 0px;
      padding-left: 0px;
      height: 210px;
    }
    .app-filters {
      margin-left: 0px;
      min-width: 370px;
      padding: 0px 15px;
    }
    .app-top-menu {
      visibility: visible;
      width: 90px;
    }
    .app-costume-list {
      width: 760px;
      padding: 0px 15px;
    }
  }
  @media screen and (min-width: 1600px) {
    .application {
      .app-costume-list {
        width: 945px;
      }
    }
  }
</style>