import axios from "axios";
import store from '@/store/index';

// axios.interceptors.request.use(config => {
//   var langs = { 'Українська': 'Ukrainian', 'English': 'English' }
//   const locale = langs[store.getters['language'] || 'English'];
//   if (locale) {
//     config.headers['locale'] = locale;
//   }

//   return config;
// });

const generateImgUrl = params => {
  return axios.post("/api/v1/ais/generate", params);
};

const generateImgUrlV2 = params => {
  return axios.post("/api/v1/ais/generate2", params);
};

// const fetchItemsById = (id, params) => {
//   return axios.get(`/api/v1/item_types/${id}/items`, {
//     params
//   });
// };

export default {
  generateImgUrl,
  generateImgUrlV2
  // fetchItemsById
};