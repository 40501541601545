import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'English',
  save: true,
  languages: [
    new MLanguage('English').create({
      about: 'about',
      socialMedia: 'social media',
      language: 'language',
      login: 'log in',
      logout: 'logout',
      selectCostumeType: '1. Select a costume type',
      searchCostume: 'search costume',
      customCostume: 'Custom costume',
      requirements: 'requirements',
      requirementsText: "Size 800x800px. Prefer PNG. For 'Choose material' or AI to work, the inside of the costume must be transparent, the outside is white",
      example: 'example',
      costumeFilters: 'Costume filters',
      memorySlots: 'MEMORY SLOTS',
      slot: 'SLOT',
      save: 'Save',
      load: 'Load',
      delete: 'Delete',
      clearCanvas: 'Clear canvas',
      howToUse: 'How to design clothes online free',
      howToUseShort: 'How to',
      historyBack: 'History back',
      historyForward: 'History forward',
      uploadItems: 'Upload items',
      openSlotMenu: 'Open memory slot menu',
      remove: 'Remove',
      add: 'Add',
      markup: 'markup',
      thin: 'Thin',
      bold: 'Bold',
      up: 'Up',
      down: 'Down',
      left: 'Left',
      right: 'Right',
      fullScreen: 'Full screen',
      zoom: 'Zoom',
      secondCanvas: 'second canvas',
      swapCanvases: 'Swap canvases',
      backgroundCoverContain: 'Background cover/contain',
      drawFigures: 'Draw figures (line, square etc)',
      returnConstruction: 'Return to construction',
      undo: 'Undo',
      redo: 'Redo',
      reset: 'Reset',
      dash: 'Dash',
      straightLine: 'Straight Line',
      circle: 'Circle',
      square: 'Square',
      triangle: 'Triangle',
      halfTriangle: 'Half Triangle',
      lineCapRound: 'lineCap Round',
      lineCapSquare: 'lineCap Square',
      lineCapbutt: 'lineCap butt',
      lineJoinRound: 'lineJoin Round',
      lineJoinmiter: 'lineJoin miter',
      lineJoinbevel: 'lineJoin bevel',
      fill: 'Fill',
      stroke: 'Stroke',
      standWithUkraine: 'Stand with Ukraine',
      selectMaterial: '3. Select a material',
      addFeatures: '2. Add features',
      custom: 'Custom',
      pressDownload: 'Download technical sketch',
      format: 'Format',
      quality: 'Quality',
      normal: 'NORMAL',
      medium: 'MEDIUM',
      low: 'LOW',
      sendTo: 'SEND TO',
      freeSoftware: 'free clothes designing software',
      privacyPolicy: 'Privacy Policy',
      uploadCostumes: 'Upload costumes / items / materials',
      link: 'link',
      modal1text1: 'You have the opportunity to add your own drawings to the form of choosing a costume, item and material',
      modal1text2: 'Detailed instructions at the',
      modal1text3: 'Template of zip-archive for upload at the',
      upload: 'Upload',
      close: 'Close',
      modal2text1: 'Thank you for using Nastix Design app',
      modal2text2: 'Our application is free, so your support is important',
      modal2text3: 'share the link/picture on your social media pages',
      modal2text4: 'write about Nastix Design on your site',
      modal2text5: 'or leave feedback where appropriate',
      modal2text6: 'Any mention of the application improves its search results. Help us spread the word to as many people as possible',
      modal3text1: 'Did you know that we have social media pages?',
      modal3text2: 'We will be very pleased if you share your design in Fashion News group',
      modal3text3: 'You can also ask for an opinion about it, give us advice on how to improve the application',
      ok: 'Ok',
      notes: 'notes',
      translationError: 'See a translation error? Let us know how to fix it by this email - nastix.design@gmail.com',
      app: 'App',
      designCourse: 'Design course',
      blog: 'Blog',
      contactUs: 'Contact us',
      contactForm: 'Contact form',
      contactForm1: 'Nastix design is a free software for online design clothes',
      contactForm2: 'Our clothes designing software helps you with learning and practice, creation prototypes for clients etc.',
      contactForm3: 'Here you can:',
      contactForm4: 'design jackets',
      contactForm5: 'design overalls',
      contactForm6: 'design trousers',
      contactForm7: 'design skirts',
      contactForm8: 'design dresses',
      contactForm9: 'etc',
      contactForm10: '3 simple steps:',
      contactForm11: 'choose or upload costume',
      contactForm12: 'add or upload details',
      contactForm13: 'choose or upload fabric',
      send: 'Send',
      close: 'Close',
      designIn3Steps: 'Design clothes online free in 3 steps',
      designIn3StepsSub: 'Nastix Design is a free app for design clothes online. Our product will be useful for novice fashion designers or students, sales managers of garment factories, ateliers.',
      getStarted: 'get started',
      advantages: 'Advantages',
      free: 'free',
      advantages1: 'No tariff plans. You can design a dress online free or create fashion technical sketch of skirt etc',
      customCostume: 'custom costume',
      advantages2: 'Can upload any picture or photo. Use as costume for your fashion technical sketch',
      customFabric: 'custom fabric',
      advantages3: 'Can upload any picture or photo. Use as fabric / background for costume for your fashion technical sketch',
      customDetails: 'custom details',
      advantages4: 'Can upload any picture or photo. Use as pocket, logo, etc for your fashion technical sketch',
      easyToUse: 'easy to use',
      advantages5: 'Three easy steps to create fashion technical sketch. Intuitive controls',
      tryAIBeta: 'AI design',
      useAI: 'Use artificial intelligence'
    }),
    new MLanguage('Українська').create({
      about: 'про проект',
      socialMedia: 'cоціальні медіа',
      language: 'мова',
      login: 'увійти',
      logout: 'вийти',
      selectCostumeType: '1. Виберіть костюм',
      searchCostume: 'пошук костюму',
      customCostume: 'Власний костюм',
      requirements: 'вимоги',
      requirementsText: 'Розмір 800x800px. Віддавайте перевагу PNG. Щоб функція «Виберіть матеріал» або ШІ працювала, внутрішня частина костюма має бути прозорою, а зовнішня – білою',
      example: 'приклад',
      costumeFilters: 'Фільтри костюмів',
      memorySlots: "СЛОТИ ПАМ'ЯТІ",
      slot: 'СЛОТ',
      save: 'Зберегти',
      load: 'Завантажити',
      delete: 'Видалити',
      clearCanvas: 'Очистити полотно',
      howToUse: 'Як створювати дизайн одягу онлайн безкоштовно',
      howToUseShort: 'Як користуватися',
      historyBack: 'Історія назад',
      historyForward: 'Історія вперед',
      uploadItems: 'Завантажте елементи',
      openSlotMenu: "Відкрити слоти пам'яті",
      remove: 'Видалити',
      add: 'Додати',
      markup: 'розмітку',
      thin: 'Тонка',
      bold: 'Товста',
      up: 'Вгору',
      down: 'Вниз',
      left: 'Лівіше',
      right: 'Правіше',
      fullScreen: 'Повноекранний',
      zoom: 'Масштаб',
      secondCanvas: 'друге полотно',
      swapCanvases: 'Поміняти місцями полотна',
      backgroundCoverContain: 'Фон заповнити/вмістити',
      drawFigures: 'Намалювати фігури (лінії, квадрати тощо)',
      returnConstruction: 'Повернутися до конструювання',
      undo: 'Скас.',
      redo: 'Повт.',
      reset: 'Скинути',
      dash: 'Пензель',
      straightLine: 'Пряма лінія',
      circle: 'Коло',
      square: 'Квадрат',
      triangle: 'Трикутник',
      halfTriangle: 'Напівтрикутник',
      lineCapRound: 'Край лінії круглий',
      lineCapSquare: 'Край лінії квадратний',
      lineCapbutt: "Край лінії м'який",
      lineJoinRound: 'Лінія звичайна',
      lineJoinmiter: 'Лінія 2',
      lineJoinbevel: 'Лінія 3',
      fill: 'Заливка',
      stroke: 'Границя',
      standWithUkraine: 'Слава Україні!',
      selectMaterial: '3. Виберіть матеріал',
      addFeatures: '2. Додайте деталі',
      custom: 'Власна',
      pressDownload: 'Завантажити малюнок',
      format: 'Формат',
      quality: 'Якість',
      normal: 'ВИСОКА',
      medium: 'СЕРЕДНЯ',
      low: 'НИЗЬКА',
      sendTo: 'ВІДПРАВИТИ',
      freeSoftware: 'безкоштовне програмне забезпечення для дизайну одягу',
      privacyPolicy: 'Політика конфіденційності',
      uploadCostumes: 'Завантажте костюми/деталі/матеріали',
      link: 'посиланням',
      modal1text1: 'Ви маєте можливість додати власні малюнки до форми вибору костюма, речі та матеріалу',
      modal1text2: 'Детальна інструкція за',
      modal1text3: 'Шаблон zip-архіву для завантаження за',
      upload: 'Завантажити',
      close: 'Закрити',
      modal2text1: 'Дякуємо за використання програми Nastix Design',
      modal2text2: 'Наша програма безкоштовна, тому ваша підтримка важлива',
      modal2text3: 'поділіться посиланням/зображенням на своїх сторінках у соціальних мережах',
      modal2text4: 'напишіть про Nastix Design на своєму сайті',
      modal2text5: 'або залиште відгук, де це доречно',
      modal2text6: 'Будь-яка згадка програми покращує результати її пошуку. Допоможіть нам поширити інформацію якомога більшій кількості людей',
      ok: 'Добре',
      notes: 'примітки',
      modal3text1: 'Чи знаєте ви, що у нас є сторінки в соціальних мережах?',
      modal3text2: 'Ми будемо дуже раді, якщо ви поділитеся своїм дизайном у групі Fashion News',
      modal3text3: 'Ви також можете запитати думку про свій дизайн, дати нам поради щодо покращення програми',
      translationError: 'Побачили помилку в перекладі? Повідомте нам як правильно виправити за цим імейлом - nastix.design@gmail.com',
      app: 'Додаток',
      designCourse: 'Курс дизайну',
      blog: 'Блог',
      contactUs: "Зв'яжіться з нами",
      contactForm: "Зворотній зв'язок",
      contactForm1: 'Nastix design — безкоштовна програма для онлайн-дизайну одягу',
      contactForm2: 'Наше програмне забезпечення для проектування одягу допоможе вам у навчанні та практиці, створенні прототипів для клієнтів тощо.',
      contactForm3: 'Тут ви можете:',
      contactForm4: 'конструювати куртки',
      contactForm5: 'конструювати спецодяг',
      contactForm6: 'конструювати брюки',
      contactForm7: 'конструювати спідниці',
      contactForm8: 'конструювати плаття',
      contactForm9: 'тощо',
      contactForm10: '3 прості кроки:',
      contactForm11: 'вибрати або завантажити костюм',
      contactForm12: 'додати або завантажити деталі',
      contactForm13: 'вибрати або завантажити тканину',
      send: 'Надіслати',
      close: 'Закрити',
      designIn3Steps: 'Дизайн одягу онлайн безкоштовно в 3 кроки',
      designIn3StepsSub: 'Nastix Design — безкоштовна програма для онлайн-дизайну одягу. Наш продукт стане в нагоді початківцям модельєрам або студентам, менеджерам з продажу швейних фабрик, ательє.',
      getStarted: 'почати',
      advantages: 'Переваги',
      free: 'безкоштовно',
      advantages1: 'Без тарифних планів. Ви можете безкоштовно спроектувати сукню онлайн або створити модний технічний ескіз спідниці тощо',
      customCostume: 'власний костюм',
      advantages2: 'Можна завантажити будь-яку картинку чи фото. Використовуйте як костюм для свого модного технічного ескізу',
      customFabric: 'власна тканина',
      advantages3: 'Можна завантажити будь-яку картинку чи фото. Використовуйте як тканину / фон для костюма для вашого модного технічного ескізу',
      customDetails: 'власні деталі',
      advantages4: 'Можна завантажити будь-яку картинку чи фото. Використовуйте як кишеню, логотип тощо для свого модного технічного ескізу',
      easyToUse: 'простий у використанні',
      advantages5: 'Три простих кроки для створення модного технічного ескізу. Інтуїтивно зрозуміле управління',
      tryAIBeta: 'Дизайн ШІ',
      useAI: 'Використати штучний інтелект'
    }),
    new MLanguage('Español').create({
      about: 'sobre el proyecto',
      socialMedia: 'medios de comunicación social',
      language: 'idioma',
      login: 'login',
      logout: 'cerrar sesión',
      selectCostumeType: '1. Elige un traje',
      searchCostume: 'búsqueda de disfraces',
      customCostume: 'Traje propio',
      requirements: 'requisitos',
      requirementsText: 'Tamaño 800x800px. Prefiero PNG. Para que funcione la función Seleccionar material, el interior del traje debe ser transparente y el exterior debe ser blanco.',
      example: 'ejemplo',
      costumeFilters: 'Filtros de traje',
      memorySlots: "RANURAS DE MEMORIA",
      slot: 'RANURA',
      save: 'Ahorrar',
      load: 'Subir',
      delete: 'Eliminar',
      clearCanvas: 'Limpiar el lienzo',
      howToUse: 'Cómo utilizar',
      howToUseShort: 'Cómo utilizar',
      historyBack: 'Historia atrás',
      historyForward: 'Historia adelante',
      uploadItems: 'Descargar articulos',
      openSlotMenu: "Ranuras de memoria abiertas",
      remove: 'Eliminar',
      add: 'Agregar',
      markup: 'Margen',
      thin: 'Delgada',
      bold: 'Negrita',
      up: 'Arriba',
      down: 'Abajo',
      left: 'Izquierda',
      right: 'Derecha',
      fullScreen: 'Pantalla completa',
      zoom: 'Zoom',
      secondCanvas: 'segundo lienzo',
      swapCanvases: 'Intercambiar lienzos',
      backgroundCoverContain: 'Fondo Cubrir/Contener',
      drawFigures: 'Dibujar figuras',
      returnConstruction: 'Volver a construcción',
      undo: 'Deshacer',
      redo: 'Rehacer',
      reset: 'Reiniciar',
      dash: 'Cepillar',
      straightLine: 'Línea recta',
      circle: 'Círculo',
      square: 'Cuadrada',
      triangle: 'Triángulo',
      halfTriangle: 'Mitadtriángulo',
      lineCapRound: 'El borde de la línea es redondo.',
      lineCapSquare: 'El borde de la línea es cuadrado.',
      lineCapbutt: "El borde de la línea es suave.",
      lineJoinRound: 'La linea es normal',
      lineJoinmiter: 'Línea 2',
      lineJoinbevel: 'Línea 3',
      fill: 'Llenar',
      stroke: 'Ataque',
      standWithUkraine: 'Apoyar a Ucrania',
      selectMaterial: '3. Seleccione el material',
      addFeatures: '2. Agregar detalles',
      custom: 'Costumbre',
      pressDownload: 'Presione para descargar',
      format: 'Formato',
      quality: 'Calidad',
      normal: 'Normal',
      medium: 'Medio',
      low: 'Baja',
      sendTo: 'Enviar a',
      freeSoftware: 'software de diseño de ropa gratis',
      privacyPolicy: 'Política de privacidad',
      uploadCostumes: 'Subir vestuario/partes/materiales',
      link: 'enlace',
      modal1text1: 'Tienes la opción de agregar tus propios dibujos al formulario de selección de vestuario, artículos y materiales.',
      modal1text2: 'Instrucciones detalladas para',
      modal1text3: 'Plantilla de archivo zip para descargar por',
      upload: 'Subir',
      close: 'Cerca',
      modal2text1: 'Gracias por usar Nastix Design',
      modal2text2: 'Nuestro programa es gratuito, por lo que su apoyo es importante',
      modal2text3: 'comparte el enlace/imagen en tus páginas de redes sociales',
      modal2text4: 'escribe sobre Nastix Design en tu sitio web',
      modal2text5: 'o dejar comentarios cuando corresponda',
      modal2text6: 'Cualquier mención de la aplicación mejora sus resultados de búsqueda. Ayúdanos a correr la voz a tantas personas como sea posible',
      ok: 'Bien',
      notes: 'notas',
      modal3text1: '¿Sabías que tenemos páginas en las redes sociales?',
      modal3text2: 'Estaremos muy complacidos si compartes tu diseño en el grupo Fashion News',
      modal3text3: 'También puedes pedir una opinión al respecto, darnos consejos sobre cómo mejorar la aplicación.',
      translationError: '¿Ves un error de traducción? Háganos saber cómo solucionarlo por este correo electrónico: nastix.design@gmail.com',
      app: 'Aplicación',
      designCourse: 'Curso de diseño',
      blog: 'Blog',
      contactUs: 'Contacto',
      contactForm: 'Formulario de contacto',
      contactForm1: 'Nastix design es un software gratuito para diseñar ropa online',
      contactForm2: 'Nuestro software de diseño de ropa lo ayuda con el aprendizaje y la práctica, la creación de prototipos para clientes, etc.',
      contactForm3: 'Aquí puedes:',
      contactForm4: 'para diseñar chaqueta',
      contactForm5: 'luego diseñar overoles',
      contactForm6: 'diseñar pantalones',
      contactForm7: 'diseñar faldas',
      contactForm8: 'diseñar vestidos',
      contactForm9: 'etc',
      contactForm10: '3 sencillos pasos:',
      contactForm11: 'elige o sube disfraz',
      contactForm12: 'agregar o cargar detalles',
      contactForm13: 'elige o sube tela',
      send: 'Enviar',
      close: 'Cerca',
      designIn3Steps: 'Diseña ropa online gratis en 3 pasos',
      designIn3StepsSub: 'Nastix Design es una aplicación gratuita para diseñar ropa en línea. Nuestro producto será útil para diseñadores de moda novatos o estudiantes, gerentes de ventas de fábricas de ropa, talleres.',
      getStarted: 'Empezar',
      advantages: 'Ventajas',
      free: 'gratis',
      advantages1: 'Sin planes tarifarios. Puede diseñar un vestido en línea gratis o crear bocetos técnicos de moda de falda, etc.',
      customCostume: 'traje personalizado',
      advantages2: 'Puede cargar cualquier imagen o foto. Úselo como disfraz para su boceto técnico de moda.',
      customFabric: 'tela personalizada',
      advantages3: 'Puede cargar cualquier imagen o foto. Úselo como tela / fondo para vestuario para su boceto técnico de moda',
      customDetails: 'detalles personalizados',
      advantages4: 'Puede cargar cualquier imagen o foto. Úselo como bolsillo, logotipo, etc. para su boceto técnico de moda.',
      easyToUse: 'fácil de usar',
      advantages5: 'Tres sencillos pasos para crear bocetos técnicos de moda. Controles intuitivos',
      tryAIBeta: 'Diseño de IA',
      useAI: 'Usa la inteligencia artificial'
    })
  ]
})