<template lang="pug">
  div(
    class="app-preview-canvas" 
    id="app-current-preview-canvas"
    v-bind:style="{ height: sizeStandart + 'px', width: sizeStandart + 'px' }"
  )

    .app-preview-canvas__name
      | {{$ml.get('standWithUkraine')}}
    .app-preview-canvas__comment
      input(maxlength="80" :placeholder="$ml.get('notes')" type="text" v-model="inputComment" @keyup="$emit('update:inputComment', inputComment)")
    .app-preview-canvas__web(v-show="showWeb && !showBoldWeb")
      .app-preview-canvas__web-hor(v-for="index in 80" :key="'h-' + index" v-bind:style="{ top: (index * 10 + vcorection) + 'px', opacity: (index % 10 == 0 ? 0.2 : 0.1) }")
      .app-preview-canvas__web-ver(v-for="index in 80" :key="'v-' + index" v-bind:style="{ left: (index * 10 + hcorection) + 'px', opacity: (index % 10 == 0 ? 0.2 : 0.1) }")
    .app-preview-canvas__web(v-show="showWeb && showBoldWeb")
      .app-preview-canvas__web-hor(v-for="index in 80" :key="'h-' + index" v-bind:style="{ top: (index * 10 + vcorection) + 'px', opacity: (index % 10 == 0 ? 0.8 : index % 5 == 0 ? 0.4 : 0.2) }")
      .app-preview-canvas__web-ver(v-for="index in 80" :key="'v-' + index" v-bind:style="{ left: (index * 10 + hcorection) + 'px', opacity: (index % 10 == 0 ? 0.8 : index % 5 == 0 ? 0.4 : 0.2) }")
</template>
<script>

  export default {
    name: 'preview-canvas',
    props: {
      inputComment: {
        type: String
      },
      selectedCostume: {
        type: Object,
        default() {
          return {}
        }
      },
      sizeStandart: {
        type: Number,
        default() {
          return 800
        }
      },
      showWeb: {
        type: Boolean,
        default() {
          return false
        }
      },
      showBoldWeb: {
        type: Boolean,
        default() {
          return false
        }
      },
      vcorection: {
        type: Number,
        default() {
          return 0
        }
      },
      hcorection: {
        type: Number,
        default() {
          return 0
        }
      },
    },
    // methods: {
    //   toggleWeb() {
    //     console.log('toogle')
    //     this.showWeb = !this.showWeb
    //   }
    // },
    watch: {
      selectedCostume(val) {
        // if (val && Object.keys(val).length > 0) {
          let obj = { x: 0, y: 0, size: 0}
          this.costume = Object.assign(obj, val)
          // remove old costume
          const costumes = document.getElementsByClassName("costume-image");
          while (costumes.length > 0) costumes[0].remove();
          const costumesSVG = document.getElementsByClassName("costume-image-svg");
          while (costumesSVG.length > 0) costumesSVG[0].remove();        
          // insert new costume
          if (this.costume && this.costume.img_front) {
          let img = document.createElement("img");
            img.className = "costume-image";
            img.id = "selected-costume-image"
            img.src = this.costume.img_front.url;
            img.onload = "SVGInject(this)"
            let src = document.getElementById("app-current-preview-canvas");
            src.appendChild(img);
          }
        // }
      }
    },
    data() {
      return{
        costume: {},
        items: []
      }
    }
  }
</script>

<style lang="scss">
  .app-preview-canvas {
    margin-right: 15px;
    border: 15px solid coral;
    background-color: white;
    border: 1px solid;
    float: left;
    overflow: hidden;
    position: absolute;
  }
  .costume-image {
    max-width: 100%;
    max-height: 100%;  
    display: block;  
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
  #app-current-preview-canvas {
    border: 15px solid coral;
  }
  .app-preview-canvas__name {
    color: white;
    padding: 5px;
    position: absolute;
    z-index: 100;
  }
  svg {
    max-width: 800px;
    max-height: 800px;
  }
  .app-preview-canvas__web-btn {
    z-index: 1100;
    cursor: pointer;
    color: darkred;
    padding: 0px 5px;
    position: absolute;
    right: 0px;
    border-radius: 2px;
    border: 1px solid darkred;
  }
  .app-preview-canvas__comment {
    z-index: 100;
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
  .app-preview-canvas__comment input {
    width: 770px;
    border: none;
    background: transparent;
  }
  .app-preview-canvas__web {
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .app-preview-canvas__web-hor, .app-preview-canvas__web-ver {
    background-color: black;
    opacity: 0.1;
    position: absolute;
  }
  .app-preview-canvas__web-ver {
    height: 100%;
    width: 1px;
  }
  .app-preview-canvas__web-hor {
    width: 100%;
    height: 1px;
  }
</style>
