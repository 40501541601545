<template lang="pug">
.header-wrapper
  b-container(fluid='xl')
    .header(class="show app-header navbar")
      router-link(to="/")
        h1(class="logo")
          | nastix
          span
            |  design
      .humburger
        i.fas.fa-bars
      md-menu.md-menu--color(md-direction="bottom-start")
        md-button.header-btn(to="/app")
          | {{$ml.get('app')}}
        md-button.header-btn(to="/how-to-use")
          | {{$ml.get('howToUseShort')}}
        md-button.header-btn(to="/education")
          | {{$ml.get('designCourse')}}
        md-button.header-btn(to="/blog")
          | {{$ml.get('blog')}}
        .dropdown
          md-button.header-btn
            | {{$ml.get('language')}}
          .dropdown-content
            .dropdown-message
              | {{$ml.get('translationError')}}
            a(
              v-for="lang in $ml.list"
              :key="lang"
              @click="changeLang($ml, lang)"
              href='#'
              v-bind:class="{ 'dropdown-content--disabled': language===lang }"
            )
              | {{lang}}
        md-button(@click="openModal()")
          | {{$ml.get('contactUs')}}
        md-button.auth__logout.header-btn(v-if="isAuthenticated" @click="logout()")
          | {{$ml.get('logout')}} {{ user.email }}
        md-button.auth__login.header-btn(v-if="!isAuthenticated" @click="login(oauth2Providers[0])")
          | {{$ml.get('login')}}
      md-dialog(:md-active.sync="showDialog")
        md-dialog-title
          | {{$ml.get('contactUs')}}
        md-tabs(md-dynamic-height='')
          md-tab(:md-label="$ml.get('about')")
            p
              | {{$ml.get('contactForm1')}}
            p
              | {{$ml.get('contactForm2')}}
            p
              | {{$ml.get('contactForm3')}}
            ul
              li
                | {{$ml.get('contactForm4')}}
              li 
                | {{$ml.get('contactForm5')}}
              li
                | {{$ml.get('contactForm6')}}
              li
                | {{$ml.get('contactForm7')}}
              li 
                | {{$ml.get('contactForm8')}}
              li
                | {{$ml.get('contactForm9')}}
            P     
              | {{$ml.get('contactForm10')}}
            ul
              li
                | {{$ml.get('contactForm11')}}
              li
                | {{$ml.get('contactForm12')}}
              li
                | {{$ml.get('contactForm13')}}
          md-tab(:md-label="$ml.get('contactForm')")
            form(class='' id='contact-form-id' @submit="sendMail")
              textarea(v-model="text" maxLength=256 id="contact-form-textarea-id" name="contact-form-textarea")
              div
              input(class="button button-primary" type="submit" :value="$ml.get('send')" :disabled="!text")
        md-dialog-actions
          md-button(class="md-primary" @click="showDialog = false")
            | {{$ml.get('close')}}
    advantages
</template>
<script>
  import oauth2Providers from '@/common/login_providers';
  // import modal from '@/modals/about.vue'
  import { mapGetters } from 'vuex';
  import ContactsApi from "@/api/contacts_api";
  import Advantages  from "@/components/Advantages"
  export default {
    name: 'c-header',
    data() {
      return {
        showDialog: false,
        text: '',
        componentKey: 0,
      };
    },
    components: { Advantages },
    computed: {
      ...mapGetters(['isAuthenticated', 'user', 'isConfirmAgreementsRequired', 'language']),
      oauth2Providers() {
        return oauth2Providers;
      }
    },

    methods: {
      changeLang(ml, lang) {
        ml.change(lang);
        this.$store.dispatch('changeLanguage', 
          lang
        );
        this.componentKey += 1;
        this.$emit('update-language');
      },
      openModal() {
        this.showDialog = true;
      },
      login(link) {
        this.$store.dispatch('login', {
          oauth2: link.slug
        });
      },
      logout() {
        this.$store.dispatch('logout');
      },
      sendMail(e) {
        ContactsApi.sendContactMail(this.text)
        this.showDialog = false;
        this.text = ''
        alert('Sent')
        e.preventDefault();
      }
    },
    mounted() {
      $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 80) {
          $(".header").removeClass("show");
        } else {
          $(".header").addClass("show");
        }
      });
      this.$store.dispatch('changeLanguage', 
        (localStorage.getItem('vueml-lang') || 'English')
      );
      $(".humburger").click(function(){
        $(".md-menu--color").toggle();
      });
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/_variables.scss";
  .app-header {
    background-color: white;
    padding-top: 1rem !important;
  }
  .logo {
    font-weight: normal;
    font-size: 32px;
    color: white;
  }
  .auth {
    color: lightskyblue;
  }
  .auth__logout {

  }
  .auth__login {
    
  }
  .modal-btn {

  }
  .header-btn {
  }
  .header{
    position: fixed !important;
    left: 0px;
    right: 0px;
    background-color: $main-color;
    height: 72px;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, .5);
    transform: translateY(-100%);
    transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.6, 1), opacity 0s 300ms;
    opacity: 0;
    transform: translateY(-72px);
  }
  .header-wrapper {
    width: 100%;
    height: 574px;
    background-color: $main-color;
  }
  .header.show {
    transform: translateY(0);
    transition: transform 300ms 300ms cubic-bezier(0.4, 0, 0.2, 1), opacity 0ms 300ms, background-color 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
  }
  .md-menu--color {
    background-color: $main-color;
  }
  .md-menu--color .md-button-content {
    color: white;
  }
  .md-menu--color button.md-button {
    margin: 0px 0px 6px 5px !important;
  }

  .md-menu--color  a.button:not(.disabled):hover, .md-menu--color a.button:link:not(.disabled):hover, .md-menu--color a.button:visited:not(.disabled):hover, .md-menu--color input[type=submit]:not(.disabled):hover, .md-menu--color input[type=button]:not(.disabled):hover, button:not(.disabled):hover {
      background-color: $main-color !important;
      background-image: linear-gradient(180deg, $main-color, $main-color) !important;
  }
  .logo span {
    background-color: $additional-color;
    cursor: pointer;
  }
  .app-header .router-link-active, .app-header .router-link-active:hover {
    text-decoration: none;
  }
  .humburger {
    display: none;
    cursor: pointer;
  }
  .humburger svg {
    width: 40px;
    height: 40px;
  }
  @media only screen and (max-width: 1024px) {
    .header {
      height: 104px;
    }
  }
  @media only screen and (max-width: 768px) {
    .header {
      height: 104px;
      .md-button {
        min-width: 60px;
      }
    }
  }
  @media only screen and (max-width: 481px) {
    .header-wrapper {
      height: 771px;
    }
    .humburger {
      display: block;
    }
    .md-menu--color {
      display: none;
      position: absolute;
      top: 100px;
      left: 0px;
      right: 0px;
    }
    .md-menu button, .md-menu .dropdown {
      width: 100%;
    }
  }
  /* Dropdown Button */
  .dropbtn {
    background-color: $application-border-color;
    color: white;
    border: none;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 8px;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    z-index: 100;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: $application-border-color;
  }
  .dropdown-content--disabled {
    background-color: #ddd;
    color: black;
    pointer-events: none;
  }
  .dropdown-message {
    padding: 2px;
    color: black;
    user-select: text;
  }
</style>
