import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import User from '@/models/User';

const state = {
  user: window.user ? new User(window.user) : null,
  language: 'English'
};

export default {
  state,
  getters,
  actions,
  mutations
};