<template lang="pug">
  .item-properties
    .item-properties__line(v-if='show')
      | Size: {{zoom}}% | Angle: {{rotate}}° | Flip: {{flip ? '+' : '-'}} | X, Y: {{x.toFixed(1)}}%, {{y.toFixed(1)}}%
</template>
<script>
  export default {
    name: 'item-properties',
    data() {
      return {
      }
    },
    props: {
      show: {
        type: Boolean,
        default() {
          return true
        }
      },
      rotate: {
        type: Number,
        default() {
          return 0
        }
      },
      zoom: {
        type: Number,
        default() {
          return 0
        }
      },
      x: {
        type: Number,
        default() {
          return 0
        }
      },
      y: {
        type: Number,
        default() {
          return 0
        }
      },
      flip: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    methods: {
      selectItem() {
        this.$emit('select-item', this.item);
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/_variables.scss";
  .item-properties {
    height: 15px;
    position: relative;
    bottom: -35px;
  }
</style>