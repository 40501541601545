import * as Vue from 'vue';
import VueRouter from 'vue-router';
import WorkZone from "./views/Home/WorkZone.vue";
import Application from "./views/Application.vue";
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import HowToUse from './components/HowToUse.vue';
import MassUploadGuide from './components/MassUploadGuide.vue';
import Collections from './components/Collections.vue'
import Education from './components/Education.vue'
import Blog from './components/Blog.vue'
import TakingMeasurements from './components/TakingMeasurements.vue'
import EaseAllowance from './components/EaseAllowance.vue'
import CharacteristicsClothing from './components/CharacteristicsClothing.vue'
import SeamAllowances from './components/SeamAllowances.vue'
import TailorPattern from './components/TailorPattern.vue'
import CottonFabrics from './components/CottonFabrics.vue'
import Pockets from './components/Pockets.vue'
import BaseDress from './components/Blog/BaseDress.vue'
import BaseSkirt from './components/Blog/BaseSkirt.vue'
import PatternDifference from './components/Blog/PatternDifference.vue'
import AI from './components/AI/Application.vue'
import store from '@/store/index';

Vue.use(VueRouter);

// import LoginView from './views/Login.vue';
// import ForgotPasswordView from './views/ForgotPassword.vue';
// import ResetPasswordView from './views/ResetPassword.vue';

const routes = [
  {
    path: '/',
    name: 'Root',
    meta: {
      requiresAuth: true
    },
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [
      {
        path: '/',
        name: 'Workzone',
        component: WorkZone
      },
      {
        path: '/app',
        name: 'Application',
        component: Application
      },
      {
        path: '/ai',
        name: 'AI',
        component: AI
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
      },
      {
        path: '/how-to-use',
        name: 'HowToUse',
        component: HowToUse
      },
      {
        path: '/collections',
        name: 'Collections',
        component: Collections
      },
      {
        path: '/mass-upload-guide',
        name: 'MassUploadGuide',
        component: MassUploadGuide
      },
      {
        path: '/education',
        name: 'Education',
        component: Education
      },
      {
        path: '/blog',
        name: 'Blog',
        component: Blog
      },
      {
        path: '/taking_measurements',
        name: 'Taking measurements',
        component: TakingMeasurements
      },
      {
        path: '/ease_allowance',
        name: 'EaseAllowance',
        component: EaseAllowance
      },
      {
        path: '/characteristics_clothing',
        name: 'CharacteristicsClothing',
        component: CharacteristicsClothing
      },
      {
        path: '/seam_allowances',
        name: 'SeamAllowances',
        component: SeamAllowances
      },
      {
        path: '/tailor_pattern',
        name: 'TailorPattern',
        component: TailorPattern
      },
      {
        path: '/cotton_fabrics',
        name: 'CottonFabrics',
        component: CottonFabrics
      },
      {
        path: '/pockets',
        name: 'Pockets',
        component: Pockets
      },
      {
        path: '/base_dress',
        name: 'BaseDress',
        component: BaseDress
      },
      {
        path: '/pattern_difference',
        name: 'PatternDifference',
        component: PatternDifference
      },
      {
        path: '/base_skirt',
        name: 'BaseSkirt',
        component: BaseSkirt
      }      
    ]
  }


  // {
  //   path: '/reset-password',
  //   component: ResetPasswordView
  // },
  // {
  //   path: '/forgot-password',
  //   component: ForgotPasswordView
  // },
  // {
  //   path: '/login',
  //   component: LoginView
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes // short for routes: routes
});

// router.beforeEach((to, _from, next) => {
//   if (to.matched.some(m => m.meta.requiresAuth)) {
//     if (store.getters['global/isAuthenticated']) {
//       console.log('regired auth');
//       return next();
//     } else {
//       return next('/login');
//     }
//   } else {
//     return next();
//   }
// });

export default router;
