<template>
  <footer class="app-footer">
    <span><a href="./app">Nastix Design</a> &copy; {{current_year}} </span> - {{$ml.get('freeSoftware')}} | <span><a href="./privacy-policy">{{$ml.get('privacyPolicy')}}</a></span>
  </footer>
</template>
<script>
export default {
  name: 'app-footer',
  computed: {
    current_year() {
      return new Date().getFullYear();
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-footer {
  	margin-top: 15px;
    background-color: lightskyblue;
    padding: 15px;
    color: white;
  }
</style>