import axios from "axios";
import store from '@/store/index';
var Qs = require('qs');

// Format nested params correctly


axios.interceptors.request.use(config => {
  window.console.log(config);

  config.paramsSerializer = params => {
    // Qs is already included in the Axios package
    var langs = { 'Українська': 'Ukrainian', 'English': 'English' }
    const locale = langs[store.getters['language'] || 'English'];
    if (locale) {
      config.headers['locale'] = locale;
    }
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

const fetchAll = params => {
  return axios.get("/api/v1/costumes", { params: params });
};


const fetchAllFilters = params => {
  return axios.get("/api/v1/categories", params);
}
export default {
  fetchAll,
  fetchAllFilters
};