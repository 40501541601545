<template lang="pug">
  ins.adsbygoogle(:classname='adsbygoogle' :style="{display: 'block'}" :data-ad-client='googleAdId' :data-ad-slot='slotid' data-ad-format='auto' data-full-width-responsive='true')
</template>
<script>
  export default {
    name: "GoogleAd",
    props: {
      timeout: Number,
      classNames: String,
      slotid: String
    },
    data() {
      return {
        googleInit: null,
        googleAdId: "ca-pub-8170405915179999"
      };
    },
    mounted() {
    	let timeout = 200;
      if(this.timeout) timeout = this.timeout;
      this.googleInit = setTimeout(() => {
        if (typeof window !== "undefined")
          (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, timeout);
    },
    destroyed() {
      if (this.googleInit) clearTimeout(this.googleInit);
    }
  };
</script>