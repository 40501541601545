import axios from "axios";
// const headers = {
//   'Content-Type': 'application/json'
// }
const saveSlot = params => {
  return axios.post("/api/v1/slots/save", params,
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};
const getSlot = position => {
  return axios.get(`/api/v1/slots/load?position=${position}`,
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};
const getSlots = position => {
  return axios.get(`/api/v1/slots`,
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};

const deleteSlot = params => {
  return axios.post("/api/v1/slots/clear", params,
    { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}
  });
};

export default {
  saveSlot,
  getSlot,
  getSlots,
  deleteSlot
};