<template lang="pug">
  .app-top-menu(:key="componentKey")
    md-button(@click="toAbout" class='top-menu-button')
      | {{$ml.get('about')}}
    .dropdown
      .dropbtn
        | {{$ml.get('socialMedia')}}
      .dropdown-content
        a(href='https://www.facebook.com/NastixDesign' target="_blank")
          | Facebook
        a(href='https://www.youtube.com/channel/UC5vZJCfwM4Ys4hHaiBBqdvw' target="_blank")
          | Youtube
        a(href='https://www.tiktok.com/@nastix_design?lang=en' target="_blank")
          | Tik-Tok
        a(href='https://www.instagram.com/nastix_design/' target="_blank")
          | Instagram
        a(href='https://twitter.com/NastixDesign' target="_blank")
          | Twitter
    .dropdown
      .dropbtn
        | {{$ml.get('language')}}
      .dropdown-content
        .dropdown-message
          | {{$ml.get('translationError')}}
        a(
          v-for="lang in $ml.list"
          :key="lang"
          @click="changeLang($ml, lang)"
          href='#'
          v-bind:class="{ 'dropdown-content--disabled': language===lang }"
        )
          | {{lang}}

    md-button(v-if="isAuthenticated" @click="logout()" class='top-menu-button') {{$ml.get('logout')}}
    md-button(v-if="!isAuthenticated" @click="login(oauth2Providers[0])" class='top-menu-button') {{$ml.get('login')}}
    md-button(@click="toAI" class='top-menu-button')
      | {{$ml.get('tryAIBeta')}}   
</template>

<script>
  import oauth2Providers from '@/common/login_providers';
  import { mapGetters } from 'vuex';

  export default {
    name: 'TopMenu',
    components: {
    },
    mounted() {
      this.$store.dispatch('changeLanguage', 
        (localStorage.getItem('vueml-lang') || 'English')
      )
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'user', 'isConfirmAgreementsRequired', 'language']),
      oauth2Providers() {
        return oauth2Providers;
      }
    },
    data() {
      return {
        componentKey: 0,
        // currentLang: 'English'
      }
    },
    methods: {
      changeLang(ml, lang) {
        ml.change(lang);
        this.$store.dispatch('changeLanguage', 
          lang
        );
        this.componentKey += 1;
        this.$emit('update-language');
      },
      login(link) {
        this.$store.dispatch('login', {
          oauth2: link.slug
        });
      },
      logout() {
        this.$store.dispatch('logout');
      },
      toAbout() {
        this.$router.push('/');
      },
      toAI() {
        this.$router.push('/ai');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/_variables.scss";
  .application {
    .app-top-menu {
      display: flex;
      width: 100%;
      background-color: $application-border-color;
    }
    .top-menu-button {
      height: 30px;
      color: white !important;
      text-decoration: none;
      margin: 0px;
    }
    .top-menu-button {
      background-color: $application-border-color !important;
    }
    .top-menu-button:hover {
      background-image: none !important;
      background-color: $application-border-color !important;
    }
    .top-menu-button:active {
      background-color: $application-border-color !important;
      background-blend-mode: overlay;
    }
    /* Dropdown Button */
    .dropbtn {
      background-color: $application-border-color;
      color: white;
      border: none;
      height: 30px;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 8px;
      border-radius: 2px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
      position: relative;
      display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }

    /* Links inside the dropdown */
    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    /* Change color of dropdown links on hover */
    .dropdown-content a:hover {
      background-color: #ddd;
      color: black;
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
      display: block;
      z-index: 100;
    }

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {
      background-color: $application-border-color;
    }
    .dropdown-content--disabled {
      background-color: #ddd;
      color: black;
      pointer-events: none;
    }
    .dropdown-message {
      padding: 2px;
      color: black;
      user-select: text;
    }
  }
</style>